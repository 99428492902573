import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { OnCall } from "../models";
import { formatAWSDate } from "../_utils/datetime";
import { useUser } from "./useUser";

export function useOncalls() {
  // User
  const { authUser, security } = useUser();

  // State
  const [onCallDates, setOnCallDates] = useState<OnCall[]>();
  const [queriedOnCall, setQueriedOnCall] = useState<OnCall[]>();
  const [activeOnCallId, setActiveOnCallId] = useState<null | string | undefined>();
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // Load onCall event
  const loadOnCallEvents = async () => {
    try {
      // Create date string limit
      let date = formatAWSDate(new Date());

      //Load data
      let data = await DataStore.query(
        OnCall,
        (c) => c.end("ge", date).deleted("ne", true),
        {
          sort: (s) => s.start(SortDirection.ASCENDING),
        }
      );

        console.log(data);
      // Update state
      setOnCallDates(data);
    } catch (error) {
      console.log("Error loading on call data");
    }
  };

  const queryOnCallDates = async () => {
    try {
      // Create regex
      let keywordRegex = new RegExp(keyword || "", "i");

      // Apply search
      let quedOnCallDates = onCallDates?.filter((onCall) => {
        //  Check empleyee email
        if (onCall.employee?.email?.match(keywordRegex)) return true;

        // Check name
        if (onCall.employee?.username?.match(keywordRegex)) return true;

        // Check date
        if (onCall.start.match(keywordRegex)) return true;

        // Default
        return false;
      });

      // Update state
      setQueriedOnCall(quedOnCallDates);
    } catch (error) {
      console.log("Error qu'ing oncall data");
    }
  };

  // Create oncall event
  const createOnCallEvent = async () => {
    try {
      //
      const newOnCallEvent = await DataStore.save(
        new OnCall({
          start: formatAWSDate(new Date()),
          end: formatAWSDate(new Date()),
        })
      );

      // Reload lsit
      loadOnCallEvents();

      // Open modal
      setActiveOnCallId(newOnCallEvent.id);
    } catch (error) {
      console.log("Error creating new oncall event");
    }
  };
  // Query on shift
  useEffect(() => {
    queryOnCallDates();
  }, [keyword, onCallDates]);

  // Reload data
  useEffect(() => {
    if (!activeOnCallId) loadOnCallEvents();
  }, [activeOnCallId]);

  // Load data on connect
  useEffect(() => {
    loadOnCallEvents();
  }, [authUser]);

  return {
    queriedOnCall,
    keyword,
    setKeyword,
    activeOnCallId,
    setActiveOnCallId,
    createOnCallEvent,
  };
}
