import { IonButton, IonIcon } from "@ionic/react";
import { mailOutline } from "ionicons/icons";

interface EmailButtonProps {
  email: null | string | undefined;
}

export const EmailButton: React.FC<EmailButtonProps> = (props) => {
  return (
    <>
      {props.email && (
        <IonButton href={"mailto:" + props.email}>
          <IonIcon icon={mailOutline} />
        </IonButton>
      )}
    </>
  );
};
