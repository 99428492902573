import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Accounts, Expenses, Invoice } from "../models";
import { TradeType } from "../models";
import { Project, Timesheet, TimesheetStatus } from "../models";

export interface LabourCosts {
  plumbing: number;
  hvac: number;
  unapproved: number;
}

export interface ProjectRevenue {
  income: number;
  vendor: number;
  subcontractor: number;
  misc: number;
  total: number;
}

export function useProjectFinances() {
  // Active project idd
  const [refreshFinances, setRefreshFinances] = useState<boolean>();
  const [activeProjectId, setActiveProjectId] = useState<string>();
  const [activeProject, setActiveProject] = useState<Project>();
  const [projectTimesheets, setProjectTimesheets] = useState<Timesheet[]>();
  const [projectInvoices, setProjectInvoices] = useState<Invoice[]>();
  const [projectLabour, setLabour] = useState<LabourCosts>();
  const [projectRevenue, setProjectRevenue] = useState<ProjectRevenue>();

  // Load project
  const loadProject = async () => {
    try {
      // If not there, reload
      let project = await DataStore.query(Project,  activeProjectId!);
      console.log(project!.id + 'asdfasdfasdffffff');
      console.log(project);
      console.log('abcde');
      // Project details
      setActiveProject(project);
      loadTimesheets();
      loadInvoices();
    } catch (error) {
      console.log("There was an error loading the project: ", error);
    }
  };

  // Calculate labout cost
  const labourCosts = async (timesheets: Timesheet[]) => {
    // Updated values
    let newCosts: LabourCosts = { plumbing: 0, hvac: 0, unapproved: 0 };

    timesheets.map((timesheet) => {
      // Timestamps
      const start = new Date(timesheet.date + "T" + timesheet.start);
      const end = new Date(timesheet.date + "T" + timesheet.end);

      // Hours
      const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60;

      // Cost
      const cost = timesheet.employee?.rate! * hours;

      // Update unapproved
      if (timesheet.status === TimesheetStatus.UNAPPROVED) {
        newCosts.unapproved += cost;
      }

      // Update plumbing
      if (
        timesheet.status === TimesheetStatus.APPROVED ||
        timesheet.status === TimesheetStatus.BILLED
      ) {
        if (timesheet.trade === TradeType.HVAC) newCosts.hvac += cost;
        if (timesheet.trade === TradeType.PLUMBING) newCosts.plumbing += cost;
      }
    });

    // Update state
    setLabour(newCosts);
  };

  // Calculate invoices
  const loadInvoices = async () => {
    try {
      let data = await DataStore.query(Invoice);
      let projectInvoicsData = data.filter((invoice) => {
        return invoice.projectIds?.includes(activeProjectId!);
      });
      console.log(activeProjectId);
      console.log(projectInvoicsData);
      setProjectInvoices(projectInvoicsData);
      projectCashflow(projectInvoicsData);
    } catch (error) {
      console.log("error loading project invoices: ", error);
    }
  };

  // Project cashflow
  const projectCashflow = async (invoices: Invoice[]) => {
    // Blank array
    let newCashflow: ProjectRevenue = {
      income: 0,
      vendor: 0,
      subcontractor: 0,
      misc: 0,
      total: 0,
    };

    // Get totals
    await invoices.map((invoice) => {
      // Revenues are not allocatinos
      if (invoice.account === Accounts.INCOME) {
        newCashflow.income += invoice.amount!;
      }

      // Expense
      if (invoice.account === Accounts.EXPENSES) {
        // Get the allocated amount
        let amount = invoice.amount;

        // If partition
        if (invoice.projectIds && invoice.projectIds?.length > 1) {
          // get index of project id
          let index = invoice.projectIds.indexOf(activeProjectId!);

          // Calculate it
          if (invoice.partition) {
            amount = invoice.partition[index]!;
          }
        }

        // UPdate category
        if (invoice.category === Expenses.VENDOR) {
          newCashflow.vendor += amount!;
        } else if (invoice.category === Expenses.SUBCONTRACTOR) {
          newCashflow.subcontractor += amount!;
        } else {
          newCashflow.misc += amount!;
        }
      }
    });

    // Calculate total
    newCashflow.total =
      newCashflow.income -
      (newCashflow.vendor + newCashflow.subcontractor + newCashflow.misc);

    // Set state
    setProjectRevenue(newCashflow);
  };

  // Load timesheets
  const loadTimesheets = async () => {
    try {
      let data = await DataStore.query(
        Timesheet,
        (c) => 
          c
            .timesheetProjectId("eq", activeProjectId!)
            .deleted("ne", true),
        {
          sort: (s) =>
            s.date(SortDirection.DESCENDING), //s.status(SortDirection.ASCENDING).date(SortDirection.DESCENDING),
        }
      );
      // Store in state
      setProjectTimesheets(data);
      labourCosts(data);
    } catch (error) {
      console.log("Error loading project timesheets");
    }
  };

  // Trigger project loading
  useEffect(() => {
    if (activeProjectId) {
      console.log('load' + activeProjectId);
      loadProject();
    } else {
      setActiveProject(undefined);
      setLabour(undefined);
      setProjectTimesheets(undefined);
      setProjectRevenue(undefined);
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (refreshFinances) {
      loadProject();
      setRefreshFinances(false);
    }
  }, [refreshFinances]);
  // Retrun
  return {
    activeProjectId,
    activeProject,
    setActiveProjectId,
    projectTimesheets,
    projectLabour,
    projectRevenue,
    projectInvoices,
    setRefreshFinances,
    loadInvoices,
  };
}
