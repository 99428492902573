import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { OnCall } from "../models";
import { formatAWSDate } from "../_utils/datetime";
import { useUser } from "./useUser";

export function useEventsOncall() {
  // User
  const { authUser, security } = useUser();

  // State
  const [onCallEmployees, setOnCallEmployees] = useState<OnCall[]>();
  const [onCall, setOnCall] = useState<OnCall>();
  const [nextOnCall, setNextOnCall] = useState<OnCall>();

  // Load onCall event
  const loadOnCallEvent = async () => {
    try {
      // Create date string limit
      let date = formatAWSDate(new Date());

      // Load data
      let data = await DataStore.query(OnCall, (c) => c.end("gt", date));

      // Update state
      setOnCallEmployees(data);
    } catch (error) {
      console.log("Error loading on call data");
    }
  };

  const loadEmployeeOnCallEvent = async () => {
    try {
      // Load last oncall data
      let today = new Date();

      let data = await DataStore.query(
        OnCall,
        (c) =>
          c
            .onCallEmployeeId("eq", authUser?.id!)
            .end("gt", formatAWSDate(today)),
        {
          sort: (s) => s.start(SortDirection.ASCENDING),
        }
      );

      // Set current oncall
      if (data.length > 0) setOnCall(data[0]);

      // Set next oncall
      if (data.length > 1) setNextOnCall(data[1]);
    } catch (error) {
      console.log("Error loading on call data");
    }
  };

  // Load data on connect
  useEffect(() => {
    if (security.isField) loadEmployeeOnCallEvent();
    if (security.isAdmin || security.isOffice) loadOnCallEvent();
  }, [authUser]);

  return { onCall, nextOnCall, onCallEmployees };
}
