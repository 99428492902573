import { useEffect, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonPopover,
  IonSearchbar,
  IonText,
} from "@ionic/react";
import { arrowUndoOutline, searchOutline } from "ionicons/icons";
import { StringInput } from "..";
import { useAddress, useAddressess } from "../../hooks";
interface AddressInputProps {
  id: string | undefined;
  canEdit: boolean;
  onChange: (id: string) => void;
  onChangeActiveAddress?: (addr1: string | null | undefined, addr2: string | null | undefined) => void;
  saveAddress: boolean;
  onErrorAddress?: (s: string) => void;
}

export const AddressInput: React.FC<AddressInputProps> = (props) => {

  // Sate save
  const [savingAddress, setSavingAddress] = useState(false);

  //  Load in data
  const { quedAddresses, setKeyword } = useAddressess();

  // Connect to hook
  const {
    setActiveAddressId,
    activeAddress,
    errorAddress,
    setActiveAddress,
    createAddress,
    saveAddress,
    activeAddressId,
  } = useAddress();

  // Update id
  const setId = (id: string) => {
    setActiveAddressId(id);
    props.onChange(id);
  };

  // create address
  useEffect(() => {
    if (props.saveAddress) {
      setSavingAddress(true);
      props.id === "new" ? createAddress() : saveAddress();
    }
  }, [props.saveAddress]);

  // Save trigger
  useEffect(() => {
    if (savingAddress && activeAddressId !== "new") {
      setSavingAddress(false);
      props.onChange(activeAddressId!);
      setActiveAddressId(undefined);
    }
  }, [activeAddressId, savingAddress]);

  // Update fields trigger
  useEffect(() => {
    if (props.onChangeActiveAddress) {
      props.onChangeActiveAddress(activeAddress?.address1, activeAddress?.address2);
    } 
  }, [activeAddress]);

  // Trigger update
  useEffect(() => {
    setActiveAddressId(props.id);
  }, [props.id]);

  useEffect(() => {

    if (props.onErrorAddress) {
      console.log({errorAddress});
      if (errorAddress?.address || (['', null, undefined].includes(activeAddress?.address1))) {
        props.onErrorAddress(errorAddress?.address ? "This address exists in the database" : "Address cannot be empty!");  
      } else {
        props.onErrorAddress("");
      } 
    }
    
  }, [errorAddress]);

  return (
    <IonList>
      <IonListHeader>
        <IonText color="medium">Address</IonText>
        <IonButton onClick={() => props.onChange("new")}>
          {props.canEdit && <IonIcon color="primary" icon={arrowUndoOutline} />}
        </IonButton>

        <IonItem id="search-address" button disabled={!props.canEdit}>
          {props.canEdit && <IonIcon color="primary" icon={searchOutline} />}

          <IonPopover trigger="search-address" showBackdrop={false}>
            <IonSearchbar
              onIonChange={(e) => setKeyword(e.detail.value as string)}
            />
            <IonList lines="none">
              {quedAddresses?.map((address) => {
                return (
                  <IonItem
                    key={address.id}
                    button
                    onClick={() => {
                      setId(address.id);
                    }}
                  >
                    <IonText>{address.address1}</IonText>
                  </IonItem>
                );
              })}
            </IonList>
          </IonPopover>
        </IonItem>
      </IonListHeader>

      {/* Address line */}
      <StringInput
        label={props.onErrorAddress ? "Address 1 *" : "Address 1"}
        value={activeAddress?.address1}
        canEdit={props.canEdit}
        onChange={(value) => {
          setActiveAddress({
            ...activeAddress!,
            address1: value?.split(".").join("").toUpperCase() as string,
          });
        }}
      />

      {/* Address line 2*/}
      <StringInput
        label="Address 2"
        value={activeAddress?.address2}
        canEdit={props.canEdit}
        onChange={(value) => {
          setActiveAddress({
            ...activeAddress!,
            address2: value?.split(".").join("").toUpperCase() as string,
          });
        }}
        error={{
          error: errorAddress?.address || (['', null, undefined].includes(activeAddress?.address1)),
          msg: errorAddress?.address ? "This address exists in the database" : "Address cannot be empty!",
        }}
      />

      {/* Postal code */}
      <StringInput
        label="Postal Code"
        value={activeAddress?.postal}
        canEdit={props.canEdit}
        onChange={(value) => {
          setActiveAddress({
            ...activeAddress!,
            postal: value?.split(".").join("").toUpperCase() as string,
          });
        }}
      />

      {/* City */}
      <StringInput
        label="City"
        value={activeAddress?.city}
        canEdit={props.canEdit}
        onChange={(value) => {
          setActiveAddress({
            ...activeAddress!,
            city: value?.split(".").join("").toUpperCase() as string,
          });
        }}
      />

      {/* Country */}
      <StringInput
        label="Country"
        value={activeAddress?.country}
        canEdit={props.canEdit}
        onChange={(value) => {
          setActiveAddress({
            ...activeAddress!,
            country: value?.split(".").join("").toUpperCase() as string,
          });
        }}
      />
    </IonList>
  );
};
