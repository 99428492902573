import {
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonReorder,
  IonRow,
} from "@ionic/react";
import { checkboxOutline, radioButtonOn } from "ionicons/icons";
import { useEffect } from "react";
import { DocumentInput, EditButton } from "..";
import { useStorage } from "../../hooks";
import { Document, NoteStatus } from "../../models";

interface DocumentItemProps {
  document: Document | undefined;
  onEdit: () => void;
}

export const DocumentItem: React.FC<DocumentItemProps> = (props) => {
  // Hook into data
  const { documentKey, setDocumentKey, documentLink } = useStorage();

  // Handle change
  useEffect(() => {
    setDocumentKey(props.document?.key !== null ? props.document?.key : undefined);
  }, [props.document]);

  return (
    // Main item wraper
    <IonItem>
      <IonGrid>
        <IonRow>
          {/* Reorder  */}
          <IonCol size="12" sizeMd="1">
            <IonReorder />
          </IonCol>
          {/* Display dot to show apprived */}
          {props.document?.approved && (
            <IonCol size="12" sizeMd="1">
              <p>
                <IonIcon icon={checkboxOutline} color="primary" />
              </p>
            </IonCol>
          )}
          <IonCol>
            <p>{props.document?.description}</p>
          </IonCol>
          <IonCol>
            <p>{props.document?.date}</p>
          </IonCol>
          <IonCol>
            {documentKey && (
              <p>
                <a href={documentLink} target="_blank">
                  {props.document?.key}
                </a>
              </p>
            )}
          </IonCol>
          <IonCol>
            <p>{props.document?.name}</p>
          </IonCol>
          <IonCol className="ion-text-right" sizeSm="1">
            <EditButton onEdit={() => props.onEdit()} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
