// --- React ---
import { useLocation, useHistory } from "react-router-dom";

// --- Ionic React Framework ---
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";
import {
  returnDownForwardOutline,
  returnDownForwardSharp,
} from "ionicons/icons";

// Config
import { appMenu, employeeMenu } from "./menu.config";
import { useUser } from "../../hooks";

// Style
import "./Menu.css";

export const Menu: React.FC = () => {
  const location = useLocation();
  const { username, security, logout } = useUser();

  const history = useHistory();

  const doLogout = () => {
    if (logout) {
      history.push('/');
      logout();
    }
  };

  return (
    <IonMenu contentId="main" type="overlay" class="no-print">
      <IonContent>
        <IonList id="menu-list">
          <IonListHeader>MACTECH</IonListHeader>
          <IonNote>{username.toUpperCase()}</IonNote>
          {(security.isAdmin || security.isOffice) &&
            appMenu.map((item, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={location.pathname === item.url ? "selected" : ""}
                    routerLink={item.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                    <IonLabel>{item.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}

          {security.isField &&
            employeeMenu.map((item, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={location.pathname === item.url ? "selected" : ""}
                    routerLink={item.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                    <IonLabel>{item.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
        </IonList>

        <IonList id="account-list">
          <IonListHeader>Account</IonListHeader>

          <IonMenuToggle autoHide={false}>
            <IonItem
              className={
                location.pathname === "/mactech/account" ? "selected" : ""
              }
              routerLink={"/mactech/account"}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon
                slot="start"
                ios={returnDownForwardOutline}
                md={returnDownForwardSharp}
              />
              <IonLabel>{username.toUpperCase()}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonButton onClick={doLogout} expand="full">
          Logout
        </IonButton>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
