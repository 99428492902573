import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonToolbar,
} from "@ionic/react";
import {
  CreateButton,
  Header,
  ExpenseItem,
  InvoiceModal,
  RevenueItem,
  RevenueListHeader,
  ExpensesListHeader,
} from "..";
import { useInvoices } from "../../hooks";
import { Accounts, Expenses, Invoice, Project } from "../../models";

interface ProjectAccountingTabInterface {
  project: Project | undefined;
  invoices: Invoice[] | undefined;
  onRefresh: () => void;
}

export const ProjectExpensesTab: React.FC<ProjectAccountingTabInterface> = (
  props
) => {
  const { setActiveInvoiceId, activeInvoiceId, createInvoice } = useInvoices();

  return (
    <IonContent>
      {/* Page header */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Invoices"
        }
      />

      {/* List out stuff */}
      <IonCard>
        {/* Add button header */}
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <CreateButton onCreate={() => createInvoice(props.project?.id)} />
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>

        {/* List out invoices */}
        <IonCardContent>
          
          {/* VEndor Expenses */}
          <IonList>
            {/* <IonListHeader>Vendor Invoices</IonListHeader> */}
            <IonItemDivider color="primary">Vendor Invoices</IonItemDivider>
            <ExpensesListHeader category="Vendor" />
            {props.invoices
              ?.filter((invoice) => {
                return (
                  invoice.account === Accounts.EXPENSES &&
                  invoice.category === Expenses.VENDOR
                );
              })
              .map((invoice) => {
                return (
                  <ExpenseItem
                    key={invoice.id}
                    invoice={invoice}
                    onEdit={() => setActiveInvoiceId(invoice.id)}
                  />
                );
              })}
              <ExpenseItem
                key="_total"
                invoice={{id: "", number: "TOTAL", amount: 0.00, description: "Total of Vendor Expenses", date: ""}}
              />
          </IonList>

          {/* Sub Expenses */}
          <IonList>
            {/* <IonListHeader>Subcontrator Invoices</IonListHeader> */}
            <IonItemDivider color="primary">Subcontractor Invoices</IonItemDivider>
            <ExpensesListHeader category="Subcontrator" />
            {props.invoices
              ?.filter((invoice) => {
                return (
                  invoice.account === Accounts.EXPENSES &&
                  invoice.category === Expenses.SUBCONTRACTOR
                );
              })
              .map((invoice) => {
                return (
                  <ExpenseItem
                    key={invoice.id}
                    invoice={invoice}
                    onEdit={() => setActiveInvoiceId(invoice.id)}
                  />
                );
              })}
              <ExpenseItem
                key="_total"
                invoice={{id: "", number: "TOTAL", amount: 0.00, description: "Total of Subcontractor Expenses", date: ""}}
              />
          </IonList>

          {/* Misc Expenses */}
          <IonList>
            {/* <IonListHeader>Misc Invoices</IonListHeader> */}
            <IonItemDivider color="primary">Misc Invoices</IonItemDivider>
            <ExpensesListHeader category="Misc" />

            {props.invoices
              ?.filter((invoice) => {
                return (
                  invoice.account === Accounts.EXPENSES &&
                  invoice.category === Expenses.MISCELLANEOUS
                );
              })
              .map((invoice) => {
                return (
                  <ExpenseItem
                    key={invoice.id}
                    invoice={invoice}
                    onEdit={() => setActiveInvoiceId(invoice.id)}
                  />
                );
              })}
              <ExpenseItem
                key="_total"
                invoice={{id: "", number: "TOTAL", amount: 0.00, description: "Total of Misc Expenses", date: ""}}
              />
          </IonList>
        </IonCardContent>
      </IonCard>

      {/* Modal */}
      <InvoiceModal
        invoiceId={activeInvoiceId}
        onDismiss={() => {
          setActiveInvoiceId(undefined);
          props.onRefresh();
        }}
      />
    </IonContent>
  );
};
