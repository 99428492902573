import { IonButton } from "@ionic/react";

interface PhoneButtonProps {
  tel: null | string | undefined;
}

export const PhoneButton: React.FC<PhoneButtonProps> = (props) => {
  return (
    <>
      {props.tel && (
        <IonButton href={"tel:" + props.tel}>{props.tel}</IonButton>
      )}
    </>
  );
};
