import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Address } from "../models";

export function useAddressess() {
  // State
  const [addresses, setAddresses] = useState<Address[]>();
  const [quedAddresses, setQuedAddresses] = useState<Address[]>();

  // Query
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // get data and store in state
  const loadAddresses = async () => {
    try {
      let data = await DataStore.query(Address);
      setAddresses(data);
    } catch (error) {
      console.log("Error loading addresses: ", error);
    }
  };

  // query addresses stored in state
  const queryAddresses = async () => {
    try {
      let keywordRegex = new RegExp(keyword || "", "i");
      let qAddresses = addresses?.filter((address) => {
        // Check agains each line
        if (address.address1?.match(keywordRegex)) return true;
        if (address.address2?.match(keywordRegex)) return true;
        if (address.postal?.match(keywordRegex)) return true;

        // Return false if falls out of the range
        return false;
      });

      setQuedAddresses(qAddresses);
    } catch (error) {
      console.log("Error quering addresses: ", error);
    }
  };

  // filter addresses on change
  useEffect(() => {
    queryAddresses();
  }, [addresses, keyword]);

  // Load in data
  useEffect(() => {
    loadAddresses();
  }, []);

  // return stuff
  return { quedAddresses, setKeyword };
}
