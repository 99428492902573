import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { NoteStatus } from "../API";
import { Note } from "../models";

interface NoteModalData {
  name: null | string | undefined;
  description: null | string | undefined;
  status: null | string | undefined;
  color: null | string | undefined;
}

export function useNote() {
  // Active note id to load data
  const [activeNoteId, setActiveNoteId] = useState<string>();
  const [activeNote, setActiveNote] = useState<NoteModalData>();

  // Load note
  const loadNote = async () => {
    try {
      // Get data when the id exists and set form data
      let data = await DataStore.query(Note, activeNoteId!);
      setActiveNote({
        name: data?.name,
        description: data?.description,
        status: data?.status,
        color: data?.color,
      });
    } catch (error) {
      console.log("Error loading note: ", error);
    }
  };

  // Save note information
  const saveNote = async () => {
    try {
      // Load origional
      let original = await DataStore.query(Note, activeNoteId!);

      await DataStore.save(
        Note.copyOf(original!, (updated) => {
          updated.name = activeNote?.name;
          updated.description = activeNote?.description;
          updated.status = activeNote?.status as NoteStatus;
          updated.color = activeNote?.color;
        })
      );
    } catch (error) {
      console.log("Error saving note: ", error);
    }
  };

  // Delete Porject
  const deleteNote = async () => {
    try {
      // Get project
      let original = await DataStore.query(Note, activeNoteId!);

      // remove all
      if (original) { 
        await DataStore.delete(original);
      }
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  const deleteNoteById = async (id: string) => {
    try {
      // Get project
      let original = await DataStore.query(Note, id);

      // remove all
      if (original) {
        await DataStore.delete(original);
      }
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  // Trigger note loading
  useEffect(() => {
    if (activeNoteId) loadNote();
  }, [activeNoteId]);

  // Return
  return { setActiveNoteId, activeNote, setActiveNote, saveNote, deleteNote, deleteNoteById };
}
