// Framework
import { IonContent, IonPage } from "@ionic/react";

// Component
import { UserCreateCard, Header, UserCard } from "../components";

// Data
import { useUser } from "../hooks";

export const Account: React.FC = () => {
  // Get current user security leve
  const { security } = useUser();

  return (
    <IonPage>
      {/* Header page title */}
      <Header title="Account" />

      {/* Page content */}
      <IonContent fullscreen>
        {/* User informatino card */}
        <UserCard />

        {/* If admin or office, create user */}
        {false && (security.isAdmin || security.isOffice) && <UserCreateCard />}
      </IonContent>
    </IonPage>
  );
};
