import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Address, Contact, Invoice, Project, ProjectStatus, Timesheet } from "../models";
import { LabourCosts, ProjectRevenue, useUser } from ".";
import { TimesheetStatus } from "../models";
import { TradeType } from "../models";
import { Accounts } from "../models";
import { Expenses } from "../models";

export interface SortProperty {
  sort: SortType;
  label: string;
}

export enum SortType {
  asc = "ASC",
  desc = "DESC",
}
export interface ProjectSortProperty {
  key: keyof Project;
  sort: SortType;
  label: string;
}

export function useProjects() {
  // State
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [projects, setProjects] = useState<Project[]>();
  const [queriedProjects, setQueriedProjects] = useState<Project[]>();
  const [projectStatus, setProjectStatus] = useState<"OPEN" | "ARCHIVED">(
    "OPEN"
  );

  // Edit stuff
  const [activeProjectId, setActiveProjectId] = useState<string>();

  // Keyword
  const [keyword, setProjectKeyword] = useState<null | string | undefined>();

  // Project stuff
  const ProjectSortProperties: ProjectSortProperty[] = [
    { key: "code", sort: SortType.asc, label: "PO (A -> Z)" },
    { key: "code", sort: SortType.desc, label: "PO (Z -> A)" },
    { key: "createdAt", sort: SortType.asc, label: "Newest" },
    { key: "updatedAt", sort: SortType.asc, label: "Last Modified" },
    { key: "createdAt", sort: SortType.asc, label: "Oldest" },
  ];
  const [projectSort, setProjectSort] = useState(ProjectSortProperties[0]);

  // get auth user
  const { authUser } = useUser();

  // Create new project
  const createProject = async () => {
    try {
      let newProject = await DataStore.save(
        new Project({ status: ProjectStatus.OPEN, projectAddressId: "new" })
      );
      setActiveProjectId(newProject.id);
    } catch (error) {
      console.log("Error creating job");
    }
  };

  // Load projects
  const loadProjects = async () => {
    try {
      // Load projects from server
      let data = await DataStore.query(Project, (c) =>
        c.deleted("ne", true).status("eq", projectStatus)
      );
      // Update state
      setProjects(data);
    } catch (error) {
      console.log("Error loading projects: ", error);
    }
  };

  // Query projects
  const queryProjects = async () => {
    // Create regex
    let keywordRegex = new RegExp(keyword || "", "i");

    // Apply search
    let qedProjects = projects
      ?.sort((a, b) => {
        // Check case of sort
        let aValue = "";
        let bValue = "";

        // Set value
        a[projectSort.key]
          ? (aValue = a[projectSort.key] as string)
          : (aValue = "");
        b[projectSort.key]
          ? (bValue = b[projectSort.key] as string)
          : (bValue = "");

        // Apply ordering
        switch (projectSort.sort) {
          case SortType.asc:
            return aValue.localeCompare(bValue);
            break;
          case SortType.desc:
            return bValue.localeCompare(aValue);
            break;
          default:
            return 0;
            break;
        }
      })
      .filter((project) => {
        if (keyword) {
          // Check project code
          if (project.code?.match(keywordRegex)) return true;

          // Descriptoin
          if (project.description?.match(keywordRegex)) return true;

          // Check address
          if (
            project.address?.address1?.match(keywordRegex) ||
            project.address?.address2?.match(keywordRegex) ||
            project.address?.postal?.match(keywordRegex)
          )
            return true;

          let spo = (project.code !== undefined && project.code !== null && project.number !== undefined && project.number !== null) ? 
            project.code.toUpperCase() + project.number.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
            :
            '';
            
          if (spo.match(keywordRegex)) { return true; }

          // Check builder
          if (project.customer?.name?.match(keywordRegex)) return true;

          // Default return
          return false;
        } else {
          return true;
        }
      });

    setQueriedProjects(qedProjects);
  };

  // Sort by change
  const handleSortChange = (label: string) => {
    let sortBy = ProjectSortProperties.find(
      (sortOption) => sortOption.label === label
    );
    sortBy ? setProjectSort(sortBy) : setProjectSort(ProjectSortProperties[0]);
  };

  // Delete Porject
  const deleteProject = async () => {
    try {
      // Get project
      let original = await DataStore.query(Project, activeProjectId!);

      // remove all
      await DataStore.save(
        Project.copyOf(original!, (updated) => {
          updated.deleted = true;
        })
      );
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  const deleteProjectById = async (id: string) => {
    try {
      // Get project
      let original = await DataStore.query(Project, id);

      // remove all
      await DataStore.save(
        Project.copyOf(original!, (updated) => {
          updated.deleted = true;
        })
      );
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  const getProjectById = async (id: string) => {
    try {
      // Get project
      let original = await DataStore.query(Project, id);

      return original;
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  // Load timesheets
  const getTimesheetsByProjectId = async (id: string) => {
    try {
      let data = await DataStore.query(
        Timesheet,
        (c) => 
          c
            .timesheetProjectId("eq", id)
            .deleted("ne", true),
        {
          sort: (s) =>
            s.date(SortDirection.DESCENDING), //s.status(SortDirection.ASCENDING).date(SortDirection.DESCENDING),
        }
      );
      // Store in state
      return data;
    } catch (error) {
      console.log("Error loading project timesheets");
    }
  };

  // Calculate invoices
  const getInvoicesByProjectId = async (id: string) => {
    try {
      let data = await DataStore.query(Invoice);
      let projectInvoicsData = data.filter((invoice) => {
        return invoice.projectIds?.includes(id);
      });
      return projectInvoicsData;
    } catch (error) {
      console.log("error loading project invoices: ", error);
    }
  };

  // Calculate labout cost
  const getLabourByProjectId = async (id: string) => {
    // Updated values
    let newCosts: LabourCosts = { plumbing: 0, hvac: 0, unapproved: 0 };

    let timesheets = await getTimesheetsByProjectId(id);

    timesheets!.map((timesheet) => {
      // Timestamps
      const start = new Date(timesheet.date + "T" + timesheet.start);
      const end = new Date(timesheet.date + "T" + timesheet.end);

      // Hours
      const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60;

      // Cost
      const cost = timesheet.employee?.rate! * hours;

      // Update unapproved
      if (timesheet.status === TimesheetStatus.UNAPPROVED) {
        newCosts.unapproved += cost;
      }

      // Update plumbing
      if (
        timesheet.status === TimesheetStatus.APPROVED ||
        timesheet.status === TimesheetStatus.BILLED
      ) {
        if (timesheet.trade === TradeType.HVAC) newCosts.hvac += cost;
        if (timesheet.trade === TradeType.PLUMBING) newCosts.plumbing += cost;
      }
    });

    return newCosts;

  };

  // Project cashflow
  const getRevenueByProjectId = async (id: string) => {

    //invoices: Invoice[]
    let invoices: Invoice[] | undefined = await getInvoicesByProjectId(id);

    // Blank array
    let newCashflow: ProjectRevenue = {
      income: 0,
      vendor: 0,
      subcontractor: 0,
      misc: 0,
      total: 0,
    };

    // Get totals
    await invoices!.map((invoice) => {
      // Revenues are not allocatinos
      if (invoice.account === Accounts.INCOME) {
        newCashflow.income += invoice.amount!;
      }

      // Expense
      if (invoice.account === Accounts.EXPENSES) {
        // Get the allocated amount
        let amount = invoice.amount;

        // If partition
        if (invoice.projectIds && invoice.projectIds?.length > 1) {
          // get index of project id
          let index = invoice.projectIds.indexOf(id!);

          // Calculate it
          if (invoice.partition) {
            amount = invoice.partition[index]!;
          }
        }

        // UPdate category
        if (invoice.category === Expenses.VENDOR) {
          newCashflow.vendor += amount!;
        } else if (invoice.category === Expenses.SUBCONTRACTOR) {
          newCashflow.subcontractor += amount!;
        } else {
          newCashflow.misc += amount!;
        }
      }
    });

    // Calculate total
    newCashflow.total =
      newCashflow.income -
      (newCashflow.vendor + newCashflow.subcontractor + newCashflow.misc);

    // Set state
    return newCashflow;
  };

  // Trigger query update
  useEffect(() => {
    queryProjects();
  }, [projects, keyword, projectSort]);

  // Trigger refresh
  useEffect(() => {
    if (refreshProjects) {
      loadProjects();
      setRefreshProjects(false);
    }
  }, [refreshProjects]);

  // Trigger refresh
  useEffect(() => {
    loadProjects();
  }, [projectStatus]);

  // Load projects
  useEffect(() => {
    loadProjects();
  }, [authUser]);

  return {
    queriedProjects,
    setRefreshProjects,
    activeProjectId,
    ProjectSortProperties,
    projectSort,
    setProjectKeyword,
    handleSortChange,
    setActiveProjectId,
    createProject,
    deleteProject,
    deleteProjectById,
    getProjectById,
    setProjectStatus,
    projectStatus,
    getLabourByProjectId,
    getRevenueByProjectId,
  };
}
