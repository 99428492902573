import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonList,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { DateInput, OnCallItem, OnCallModal } from "..";
import { useOncalls } from "../../hooks";
import { arrowUp, arrowDown, addCircleOutline } from "ionicons/icons";
import { formatAWSDate } from "../../_utils/datetime";
//import { Picker } from 'react-month-picker';

interface OnCallListProps {}

export const OnCallList: React.FC<OnCallListProps> = () => {
  // Show all onCallList

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);

  const [hideOnCallList, setHideOnCallList] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);

  // Connect to hool
  const {
    createOnCallEvent,
    queriedOnCall,
    setKeyword,
    setActiveOnCallId,
    activeOnCallId,
  } = useOncalls();

  //const pickAMonth = React.createRef();


  return (
    <>
      <IonCard>
        <IonToolbar>
          <IonTitle>On Call</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => createOnCallEvent()}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
            <IonButton onClick={() => setHideOnCallList(!hideOnCallList)}>
              {hideOnCallList ? (
                <IonIcon icon={arrowDown} />
              ) : (
                <IonIcon icon={arrowUp} />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonCardContent hidden={hideOnCallList}>
          <IonRow>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                tid="aaaa"
                label="Start Date"
                value={filterStartDate}
                disabled={false}
                onChange={(value) => {
                    setFilterStartDate(value);
                  }
                }
                
              />
            </IonCol>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                  tid="bhbbb"
                  label="End Date"
                  value={filterEndDate}
                  disabled={false}
                  onChange={(value) => {
                      setFilterEndDate(value);
                    }
                  }
                />
            </IonCol>
          </IonRow>
          <IonSearchbar
            onIonChange={(e) => setKeyword(e.detail.value as string)}
          />
          
          <IonList lines="none">
            {queriedOnCall?.filter((o) => {
                return (!filterStartDate || o.start >= formatAWSDate(filterStartDate)) && (!filterEndDate || o.start <= formatAWSDate(filterEndDate));
              })
              .map((onCall) => {
              return (
                <OnCallItem
                  key={onCall.id}
                  onCall={onCall}
                  onEdit={() => setActiveOnCallId(onCall.id)}
                />
              );
            })}
          </IonList>
        </IonCardContent>
      </IonCard>

      {/* On Call Modal */}
      <OnCallModal
        onCallId={activeOnCallId}
        onDismiss={() => setActiveOnCallId(undefined)}
      />
    </>
  );
};
