import { useLocation } from "react-router";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonText,
  IonToolbar,
} from "@ionic/react";
import {
  eyeOutline,
  arrowForwardOutline,
  stopwatchOutline,
} from "ionicons/icons";
import { useUser } from "../../hooks";
import { Project, ProjectStatus } from "../../models";
import "./ProjectCard.css";

interface ProjectCardProps {
  project: Project;
  onView: () => void;
  addTimesheet: () => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  // Display actions based on user
  const { security } = useUser();

  const location = useLocation();

  // Job status color
  let jobStatusColor = "primary";
  if (props.project.status === ProjectStatus.QUOTED) jobStatusColor = "warning";
  if (props.project.status === ProjectStatus.CLOSED) jobStatusColor = "medium";

  return (
    <IonCard className="job-card">
      {/* Header Section */}
      <IonCardHeader>
        <IonToolbar color="translucent">
          {/* JOB PO  */}
          <IonCardTitle style={{ textTransform: "uppercase" }}>
            {props.project.code}
          </IonCardTitle>

          {/* Actions buttons */}
          <IonButtons slot="end">
            {/* Open modal */}
            <IonButton onClick={() => props.onView()}>
              <IonIcon slot="icon-only" icon={eyeOutline} />
            </IonButton>

            {/* Go to job details */}
            {(security.isAdmin || security.isOffice) && (
              <IonButton
                routerLink={location.pathname + "/" + props.project.id}
              >
                <IonIcon slot="icon-only" icon={arrowForwardOutline}></IonIcon>
              </IonButton>
            )}

            {security.isField && (
              <IonButton onClick={() => props.addTimesheet()}>
                +<IonIcon icon={stopwatchOutline} />
              </IonButton>
            )}
          </IonButtons>

          {/* Customer's name */}
          <IonCardSubtitle>
            {props.project.customer &&
              "Buidler: " + props.project.customer.name}
          </IonCardSubtitle>
        </IonToolbar>
      </IonCardHeader>

      {/* Job Summary */}
      <IonCardContent>
        {/* Description */}
        {props.project.description && (
          <IonText>
            <h5>Note</h5>
            <h2>{props.project.description}</h2>
          </IonText>
        )}
        <br />

        {/* Address */}
        {props.project.address && (
          <IonText>
            <h5>Address</h5>
            <h2>{props.project.address?.address1}</h2>
          </IonText>
        )}
        <br />

        {/* Status */}
        <IonButton size="small" shape="round" color={jobStatusColor}>
          {props.project.status}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
