import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { refreshOutline } from "ionicons/icons";

export interface HeaderProps {
  title?: string;
  onRefresh?: () => void;
}

export const Header: React.FC<HeaderProps> = (props) => {
  return (
    <IonHeader color="primary">
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle style={{ textTransform: "uppercase" }}>
          {props.title}
        </IonTitle>
        {props.onRefresh && (
          <IonButtons slot="end">
            <IonButton onClick={() => props.onRefresh!()}>
              <IonIcon slot="icon-only" icon={refreshOutline} />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
