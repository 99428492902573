import {
  IonList,
  IonListHeader,
  IonText,
  IonItem,
  IonIcon,
  IonPopover,
  IonSearchbar,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonButton,
} from "@ionic/react";
import { addCircleOutline, createOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useProjects } from "../../hooks";

interface ProjectInputProps {
  id: null | string | undefined;
  canEdit: boolean;
  onSelect: (id: string) => void;
}

export const ProjectInput: React.FC<ProjectInputProps> = (props) => {
  const { setProjectKeyword, queriedProjects } = useProjects();
  // Open state, close on
  const [isOpen, setIsOpen] = useState(false);

  const openPopover = () => {
    setIsOpen(true);
  };

  return (
    <IonList>
      {props.canEdit && (
        <IonListHeader>
          <IonText color="medium">Jobs</IonText>

          {props.canEdit && (
            <>
              {/* Open search */}
              <IonButton onClick={openPopover}>
                <IonIcon color="primary" icon={addCircleOutline} />
              </IonButton>

              {/* Search popove */}
              <IonPopover
                showBackdrop={false}
                onDidDismiss={() => setIsOpen(false)}
                isOpen={isOpen}
              >
                <IonSearchbar
                  onIonChange={(e) =>
                    setProjectKeyword(e.detail.value as string)
                  }
                />
                <IonRadioGroup
                  onIonChange={(e) => {
                    props.onSelect(e.detail.value);
                    setIsOpen(false);
                  }}
                  value={props.id}
                >
                  {queriedProjects?.map((project) => {
                    return (
                      <IonItem key={project.id}>
                        <IonLabel>
                          {"" +
                            project.code?.toUpperCase() +
                            project?.number?.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}
                        </IonLabel>
                        <IonRadio slot="start" value={project.id} />
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
              </IonPopover>
            </>
          )}
        </IonListHeader>
      )}

      {props.id &&
        queriedProjects?.map((project) => {
          if (project.id === props.id)
            return (
              <IonItem key={project.id}>
                {"" +
                  project?.code?.toUpperCase() +
                  project?.number?.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                <IonText slot="end">
                  {project.address && (
                    <IonLabel>
                      {project.address.address2
                        ? project.address.address2 +
                          " - " +
                          project.address.address1
                        : project.address.address1}
                    </IonLabel>
                  )}
                </IonText>
              </IonItem>
            );
        })}
    </IonList>
  );
};
