import { useContext, useState, useEffect } from "react";
import { Auth, DataStore } from "aws-amplify";
import { UserContext } from "../context";
import { AuthUser, SecurityGroups, TradeType } from "../models";
import AWS from "aws-sdk";

// Context user
export const useUser = () => useContext(UserContext);

// Database user
interface FormErrors {
  email?: boolean;
  emailDuplicate?: boolean;
  username?: boolean;
  password?: boolean;
  submit?: boolean;
}

interface FormData {
  username: null | string | undefined;
  firstName: null | string | undefined;
  lastName: null | string | undefined;
  password?: string;
  email: null | string | undefined;
  cell: null | string | undefined;
  security: SecurityGroups;
  trade: TradeType;
  rate: number | undefined;
  amount: number | undefined;
}

export const useAuthUser = () => {
  // Init
  const initNewUserDetails: FormData = {
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    cell: "",
    security: SecurityGroups.FIELD,
    rate: 50.0,
    trade: TradeType.HVAC,
    amount: 0.0,
  };

  // State
  const [formData, setFormData] = useState<FormData | undefined>(
    initNewUserDetails
  );
  const [errors, setErrors] = useState<FormErrors>();
  const [userSuccess, setUserSuccess] = useState<boolean>();
  const [activeUserId, setActiveUserId] = useState<string>();

  // Load user on id change
  const loadUser = async () => {
    try {
      // Load
      let user = await DataStore.query(AuthUser, activeUserId!);

      // Set for data
      setFormData({
        username: user!.username,
        firstName: user!.firstName,
        lastName: user!.lastName,
        email: user!.email,
        cell: user!.cell,
        security: user!.security as SecurityGroups,
        trade: user!.trade as TradeType,
        rate: user!.rate !== null && user!.rate !== undefined ? user!.rate : 0.00,
        amount: user!.amount !== null && user!.amount !== undefined ? user!.amount : 0.00,
      });
    } catch (error) {
      console.log("Error loading user");
    }
  };

  // Valiadate email
  const checkEmail = async (email: string) => {
    // Validate email reg
    // let regExp = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    // // Check account
    // if (regExp.test(email)) {
    // Email is good, check if not in the database already
    const users = await DataStore.query(AuthUser, (c) => c.email("eq", email));

    // Update error status
    users.length > 0
      ? setErrors({ emailDuplicate: true })
      : setErrors({ emailDuplicate: false });

    // Set form data
    setFormData({ ...formData!, email });
  };

  // username validates
  const checkUsername = async (username: string) => {
    // Check username in database
    if (username !== "") {
      const users = await DataStore.query(AuthUser, (c) =>
        c.username("eq", username)
      );

      // set error if username alrady exist in db
      users.length > 0
        ? setErrors({ username: true })
        : setErrors({ username: false });
    }

    // Set form state data
    setFormData({ ...formData!, username });
  };

  // Just make sure password is greater than 8 letters
  const checkPassoword = (password: string) => {
    // Lenght
    password.length <= 7
      ? setErrors({ password: true })
      : setErrors({ password: false });

    // Set form state data
    setFormData({ ...formData!, password });
  };

  // Add new user
  const addAuthUser = async () => {
    // Set object
    let newUserDetails = {
      email: formData!.email,
      cell: formData!.cell,
      username: formData!.username,
      firstName: formData!.firstName,
      lastName: formData!.lastName,
      password: formData!.password,
      security: formData!.security,
      eventID: "",
    };

    // Add trade if field
    if (formData!.security?.includes(SecurityGroups.FIELD))
      newUserDetails = Object.assign(newUserDetails, {
        trade: formData!.trade,
      });

    // Add data to database
    try {
      // New cognito user
      await Auth.signUp({
        username: newUserDetails.username!,
        password: newUserDetails.password || "Test1234",
        attributes: {
          email: newUserDetails.email, // optional
        },
      });

      // New authUser in database
      await DataStore.save(new AuthUser(newUserDetails));

      // Set state
      setUserSuccess(true);
      setErrors({ submit: false });
      setFormData(initNewUserDetails);
      
    } catch (error) {
      console.log(error);
      setErrors({ submit: true });
    }
  };

  const deleteAuthUser = async (authUserId: string) => {
    try {
      const toDelete = await DataStore.query(AuthUser, authUserId);
      if (toDelete) {
        DataStore.delete(toDelete);
      }
    } catch (error) {
      console.log("Error deleting invoice");
    }
  };

  // Save user
  const saveUser = async () => {
    try {
      if (!errors && activeUserId) {
        // Make a copy
        // console.log(formData!);
        const original = await DataStore.query(AuthUser, activeUserId!);

        // Save information
        formData!.security.includes(SecurityGroups.FIELD)
          ? await DataStore.save(
              AuthUser.copyOf(original!, (updated) => {
                updated.cell = formData!.cell;
                updated.rate = formData!.rate;
                updated.amount = formData!.amount;
                updated.trade = formData!.trade;
                updated.firstName = formData!.firstName;
                updated.lastName = formData!.lastName;
              })
            )
          : await DataStore.save(
              AuthUser.copyOf(original!, (updated) => {
                updated.cell = formData!.cell;
                updated.firstName = formData!.firstName;
                updated.lastName = formData!.lastName;
              })
            );

        // if (formData && formData.password && formData.password !== '') {
        //   const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
        //   const userPoolId = currentAuthenticatedUser.pool.userPoolId;
        //   let auserPoolId = userPoolId.split('_');
        //   let awsLocation = auserPoolId[0];
        //   console.log(awsLocation);

        //   const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
        //     region: "us-east-1"
        //   });

        //   let params = {
        //     Password: formData.password,
        //     UserPoolId: userPoolId,
        //     Username: formData.username!,
        //     Permanent: true
        //   };

        //   cognitoidentityserviceprovider.adminSetUserPassword(params, function(err, data) {
        //     console.log(err);
        //     console.log(data);
        //   });
          
        //   console.log('Password was changed');

        // } else {
        //   console.log('Password was NOT changed');
        // }
        
      }
    } catch (error) {
      console.log("Error saving Employee: ", error);
    }
  };

  useEffect(() => {
    activeUserId ? loadUser() : setFormData(initNewUserDetails);
  }, [activeUserId]);

  useEffect(() => {}, []);
  return {
    activeUserId,
    formData,
    userSuccess,
    errors,
    addAuthUser,
    deleteAuthUser,
    checkEmail,
    checkUsername,
    checkPassoword,
    setFormData,
    saveUser,
    setActiveUserId,
  };
};
