import { useEffect, useState } from "react";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { AuthUser, TimeOff, TimeOffStatus } from "../models";
import { useUser } from "./";
import { formatAWSDate } from "../_utils/datetime";

interface TimeOffForm {
  start: Date;
  end: Date;
  status: TimeOffStatus,
  details: null | string | undefined;
  employee: AuthUser | undefined;
}

export function useTimeOff() {
  // User hook
  const { authUser, security } = useUser();

  // Form init
  const [timeOffForm, setTimeOffForm] = useState<TimeOffForm>({
    start: new Date(),
    end: new Date(),
    status: TimeOffStatus.REQUESTED,
    details: "",
    employee: undefined
  });
  const [requestMade, setRequestMade] = useState(false);

  // All
  const [timeOffEvents, setTimeOffEvents] = useState<TimeOff[]>();
  const [timeOffRequests, setTimeOffRequests] = useState<TimeOff[]>();

  // Funciton handling
  const [activeTimeOffId, setActiveTimeoffId] = useState<string>();
  const [activeTimeOff, setActiveTimeOff] = useState<TimeOff>();

  // Load timeoff data
  const loadTimeOffEvents = async () => {
    try {
      // data
      let data: TimeOff[] = [];

      // Load jobs
      if (security.isField) {
        // Set up limit
        data = await DataStore.query(
          TimeOff,
          (c) => c.timeOffEmployeeId("eq", authUser?.id!),
          {
            sort: (s) => s.start(SortDirection.DESCENDING),
          }
        );
      } else if (security.isAdmin || security.isOffice) {
        // Load accepted timeoff events
        data = await DataStore.query(
          TimeOff,
          (c) => c.status("eq", TimeOffStatus.APPROVED),
          {
            sort: (s) =>
              s.status(SortDirection.ASCENDING).start(SortDirection.DESCENDING),
          }
        );
      }
      // Add to state
      setTimeOffEvents(data);
    } catch (error) {
      console.log("Error loading timeoff: ", error);
    }
  };

  const loadTimeOffRequest = async () => {
    try {
      let data = await DataStore.query(TimeOff, (c) =>
        c.status("eq", TimeOffStatus.REQUESTED)
      );

      setTimeOffRequests(data);
    } catch (error) {
      console.log("Error loading timeoff request: ", error);
    }
  };

  // Load timeoff
  const loadTimeOffEvent = async () => {
    try {
      // If already loaded
      let event = timeOffEvents?.find((event) => event.id === activeTimeOffId);

      // Get data otherwise
      if (!event) event = await DataStore.query(TimeOff, activeTimeOffId!);

      // Set state
      setActiveTimeOff(event);
    } catch (error) {
      console.log("Error loading timeoff event: ", error);
    }
  };

  // Update status
  const updateStatus = async (id: string, status: TimeOffStatus) => {
    try {
      const original = await DataStore.query(TimeOff, id);

      await DataStore.save(
        TimeOff.copyOf(original!, (updated) => {
          updated.status = status;
        })
      );

      loadTimeOffEvents();
      loadTimeOffRequest();
    } catch (error) {
      console.log("Error updating status");
    }
  };

  // Save new timeoff request
  const saveTimeOff = async () => {
    try {
      await DataStore.save(
        new TimeOff({
          title: "Time Off - " + authUser?.username?.toLocaleUpperCase(),
          status: TimeOffStatus.REQUESTED,
          start: formatAWSDate(timeOffForm.start),
          end: formatAWSDate(timeOffForm.end),
          details: timeOffForm.details,
          color: "#ae0f0f",
          timeOffEmployeeId: authUser?.id,
        })
      );
      setRequestMade(true);
    } catch (error) {
      console.log("There was an error saving date: ", error);
    }
  };

  // Delete
  const deleteTimeoff = async () => {
    try {
      // Make copy to save
      const original = await DataStore.query(TimeOff, activeTimeOff!);

      // Update data
      await DataStore.save(
        TimeOff.copyOf(original!, (updated) => {
          updated.deleted = true;
        })
      );
    } catch (error) {
      console.log("Error editting employee");
    }
  };


  // Remove active timeoff
  const deleteActiveTimeoff = async () => {
    try {
      if (activeTimeOffId) {
        const eventToDelete = await DataStore.query(TimeOff, activeTimeOffId);
        // await DataStore.save(eventToDelete!);
        loadTimeOffEvents();
      }
    } catch (error) {
      console.log("There was an error deleting timeoff: ", error);
    }
  };

  // Load active
  useEffect(() => {
    if (activeTimeOffId) loadTimeOffEvent();
    if (!activeTimeOffId) loadTimeOffEvents();
  }, [activeTimeOffId]);

  // Reset status if input changes
  useEffect(() => {
    setRequestMade(false);
  }, [timeOffForm]);

  // Load time off delete
  useEffect(() => {
    loadTimeOffEvents();
    if (security.isAdmin || security.isOffice) loadTimeOffRequest();
  }, [authUser]);

  return {
    timeOffRequests,
    timeOffForm,
    requestMade,
    timeOffEvents,
    setTimeOffForm,
    saveTimeOff,
    activeTimeOffId,
    setActiveTimeoffId,
    activeTimeOff,
    updateStatus,
    deleteTimeoff,
    deleteActiveTimeoff,
    loadTimeOffEvents,
  };
}
