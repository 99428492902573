import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ExpenseCreateCard,
  ExpenseItem,
  ExpensesListHeader,
  Header,
  InvoiceModal,
  SearchCard,
} from "../components";
import { useInvoices } from "../hooks";
import { Accounts, Expenses as ExpensesType } from "../models";

interface ExpensesProps
  extends RouteComponentProps<{
    category: ExpensesType;
  }> {}

export const Expenses: React.FC<ExpensesProps> = ({ match }) => {
  // Grab type
  const [category, setCategory] = useState<ExpensesType>(match.params.category);

  // Tap into data
  const {
    activeInvoiceId,
    setActiveInvoiceId,
    setContactKeyword,
    queriedInvoices,
    setReloadState,
    loadInvoices,
    deleteInvoice,
  } = useInvoices();

  // ON page change
  useEffect(() => {
    setCategory(match.params.category);
  }, [match.params.category]);

  return (
    <IonPage>
      {/* Header */}
      <Header title={category + " Invoices"} />

      {/* Page content */}
      <IonContent>
        {/* Search Card */}
        <SearchCard onKeywordChange={setContactKeyword} />

        {/* Create card */}
        <ExpenseCreateCard
          category={category}
          onCreate={() => loadInvoices()}
        />

        {/* Displauy invoices */}
        <IonCard>
          <IonCardContent>
            <IonList>
              {/* Header */}
              <ExpensesListHeader category={category.toLocaleLowerCase()} />

              {/* Invoices */}
              {queriedInvoices
                ?.filter((invoice) => {
                  // Return vendor invoices
                  return invoice.account === Accounts.EXPENSES &&
                    category === invoice.category
                    ? true
                    : false;
                })
                .map((invoice) => {
                  return (
                    <ExpenseItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                      onDelete={() => deleteInvoice(invoice.id).then(() => loadInvoices())}
                    />
                  );
                })}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>

      {/* Modal */}
      <InvoiceModal
        invoiceId={activeInvoiceId}
        onDismiss={() => {
          setActiveInvoiceId(undefined);
          setReloadState(true);
        }}
      />
    </IonPage>
  );
};
