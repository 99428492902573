import { IonListHeader, IonText } from "@ionic/react";

interface ListHeaderProps {
  title: string;
}
export const ListHeader: React.FC<ListHeaderProps> = (props) => {
  return (
    <IonListHeader>
      <IonText color="medium">{props.title}</IonText>
    </IonListHeader>
  );
};
