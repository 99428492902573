import { IonCard, IonCardContent } from "@ionic/react";
import { useEffect, useState } from "react";
import FullCalendar, { ToolbarInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { Event as WorkEvent, TimeOff } from "../../models";

interface CalendarCardProps {
  events: WorkEvent[];
  timeOff: TimeOff[];
  setActiveEventId: React.Dispatch<React.SetStateAction<null | string | undefined>>;
  setTimeOffId: React.Dispatch<React.SetStateAction<null | string | undefined>>;
}

export const CalendarCard: React.FC<CalendarCardProps> = (props) => {
  // Settings
  const calenderHeader: ToolbarInput = {
    start: "today prev,next",
    center: "title",
    end: "timeGridWeek,listWeek,dayGridMonth",
  };

  const views = { listWeek: { titleFormat: {} } };

  // Event state
  const [events, setEvents] = useState<any[]>([
    ...props.events,
    ...props.timeOff,
  ]);

  // Set ative id of event
  const handleClick = (calendarEvent: any) => {
    // Check if event
    let event = props.events?.find(
      (event) => event.id === calendarEvent.event.id
    );
    if (event) props.setActiveEventId(event.id);

    // Check if event
    let timeoff = props.timeOff?.find(
      (timeoff) => timeoff.id === calendarEvent.event.id
    );
    if (timeoff) props.setTimeOffId(timeoff.id);
  };

  // Load calender
  useEffect(() => {
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }, []);

  // REload events
  useEffect(() => {
    setEvents([...props.events, ...props.timeOff]);
  }, [props.events, props.timeOff]);

  return (
    <IonCard>
      <IonCardContent>
        <FullCalendar
          initialView="timeGridWeek"
          headerToolbar={calenderHeader}
          displayEventTime={false}
          plugins={[
            dayGridPlugin,
            listPlugin,
            interactionPlugin,
            timeGridPlugin,
          ]}
          events={events}
          selectable={true}
          eventClick={handleClick}
          allDaySlot={false}
          slotDuration={"24:00:00"}
          expandRows={true}
          eventMinHeight={30}
        />
      </IonCardContent>
    </IonCard>
  );
};
