import { IonInput, IonItem, IonLabel } from "@ionic/react";

interface TimeInputProps {
  value: Date;
}

export const TimeItem: React.FC<TimeInputProps> = (props) => {
  return (
    <IonItem>
      <IonLabel position="stacked">Time</IonLabel>
      <IonInput readonly value={props.value.getHours() < 12 ? "AM" : "PM"} />
    </IonItem>
  );
};
