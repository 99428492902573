import { IonButton, IonIcon } from "@ionic/react";
import { listOutline, appsOutline } from "ionicons/icons";
import { DisplayLayout } from "../../context";
import { useTheme } from "../../hooks";

export const LayoutButton: React.FC = () => {
  const { layout, toggleLayout } = useTheme();

  return (
    <IonButton onClick={() => toggleLayout()}>
      {layout === DisplayLayout.GRID && (
        <IonIcon slot="icon-only" icon={listOutline} />
      )}
      {layout === DisplayLayout.TABLE && (
        <IonIcon slot="icon-only" icon={appsOutline} />
      )}
    </IonButton>
  );
};
