import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthUser, useEmployees } from "../../hooks";
import { SecurityGroups, TradeType } from "../../models";

interface UserModalProps {
  id: null | string | undefined;
  onDismiss: () => void;
  hideSave?: boolean;
}

export const UserModal: React.FC<UserModalProps> = (props) => {
  // Display
  const [isOpen, setIsOpen] = useState(false);

  // Hook data
  const { formData, setFormData, saveUser, setActiveUserId } = useAuthUser();

  // Handle save
  const handleSave = () => {
    saveUser();
    props.onDismiss();
  };

  // Trigger
  useEffect(() => {
    setActiveUserId(props.id !== null ? props.id : undefined);
    props.id ? setIsOpen(true) : setIsOpen(false);
  }, [props.id, setActiveUserId]);

  // Return
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        <IonTitle>Employee</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => props.onDismiss()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {/* Modal content */}
      {formData && (
        <IonContent>
          {/* Employee information */}
          <IonList lines="none">
            <IonListHeader>
              <IonText color="medium">Account Information</IonText>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">Username</IonLabel>
              <IonInput value={formData.username} readonly />
            </IonItem>
            {/* <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput type="text" value={formData.password} onIonChange={(e) => setFormData({...formData, password: e.detail.value || ''})} />
            </IonItem> */}
            <IonItem>
              <IonLabel position="stacked">First Name</IonLabel>
              <IonInput value={formData.firstName} onIonChange={(e) => setFormData({...formData, firstName: e.detail.value || ''})} />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Last Name</IonLabel>
              <IonInput value={formData.lastName} onIonChange={(e) => setFormData({...formData, lastName: e.detail.value || ''})} />
            </IonItem>
            {/* Email Input*/}
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput type="email" value={formData.email} readonly></IonInput>
            </IonItem>
          </IonList>

          <IonList lines="none">
            <IonListHeader>
              <IonText color="medium">Employee Information</IonText>
            </IonListHeader>
            {/* Phone number */}
            <IonItem>
              <IonLabel position="floating">Cell</IonLabel>
              <IonInput
                type="tel"
                value={formData.cell}
                onIonChange={(e) =>
                  setFormData({ ...formData, cell: e.detail.value as string })
                }
              ></IonInput>
            </IonItem>

            {/* User trade tag */}
            {formData.security.includes(SecurityGroups.FIELD) && (
              <IonItem>
                <IonLabel position="stacked">Trade Tag</IonLabel>
                <IonSelect
                  value={formData.trade}
                  interface="popover"
                  onIonChange={(e) => {
                    setFormData({
                      ...formData,
                      trade: e.detail.value as TradeType,
                    });
                  }}
                >
                  {Object.keys(TradeType).map((value, key) => {
                    return (
                      <IonSelectOption key={key} value={value}>
                        {value}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            )}

            {/* User trade rate */}
            {formData.security.includes(SecurityGroups.FIELD) && (
              <>
                <IonItem>
                  <IonLabel position="stacked">Rate ($)</IonLabel>
                  <IonInput
                    type="number"
                    value={formData.rate}
                    onIonChange={(e) => {
                      setFormData({ ...formData, rate: Number(e.detail.value) });
                    }}
                  ></IonInput>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="stacked">Amount ($)</IonLabel>
                  <IonInput
                    type="number"
                    value={formData.amount}
                    onIonChange={(e) => {
                      setFormData({ ...formData, amount: Number(e.detail.value) });
                    }}
                  ></IonInput>
                </IonItem> */}
              </>
            )}
          </IonList>
          {!props.hideSave && 
            <IonButton expand="block" onClick={() => handleSave()}>
              Save
            </IonButton>
          }
          
        </IonContent>
      )}
    </IonModal>
  );
};

interface UserPasswordChangeModalProps {
  id: null | string | undefined;
  name: null | string | undefined;
  onDismiss: () => void;
}

export const UserPasswordChangeModal: React.FC<UserPasswordChangeModalProps> = (props) => {
  // Display
  const [isOpen, setIsOpen] = useState(false);
  // Hook data
  const changePwd = (id: string, password: string) => { changeAuthUserPassword(id, password).then(() => setIsOpen(false)) }
  const {changeAuthUserPassword} = useEmployees();

  const [formData, setFormData] = useState({
    'password': ''
  });

  // Trigger
  useEffect(() => {
    props.id ? setIsOpen(true) : setIsOpen(false);
  }, [props.id]);

  // Return
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        <IonTitle>Employee</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => props.onDismiss()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {/* Modal content */}
      {true && (
        <IonContent>
          {/* Employee information */}
          <IonList lines="none">
            <IonListHeader>
              <IonText color="medium">Change User Password</IonText>
            </IonListHeader>
            <IonItem>
              <IonLabel position="stacked">Username</IonLabel>
              <IonInput value={props.name !== null && props.name !== undefined ? props.name : ''} readonly />
            </IonItem>
            {/* <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput type="text" value={formData.password} onIonChange={(e) => setFormData({...formData, password: e.detail.value || ''})} />
            </IonItem> */}
            {/* Email Input*/}
            <IonItem>
              <IonLabel position="floating">New password</IonLabel>
              <IonInput type="password" value={formData.password!== null && formData.password !== undefined ? formData.password : ''} onIonChange={(e) => setFormData({...formData, password: e.detail.value!})}></IonInput>
            </IonItem>
          </IonList>

          <IonButton expand="block" onClick={() => changePwd(props.id!, formData.password!)}>
            Change Password
          </IonButton>
        </IonContent>
      )}
    </IonModal>
  );
};
