import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Contact, ContactType } from "../models";

export interface ContactFilterProperty {
  type: ContactType;
  label: string;
}
export const ContactFilterProperties: ContactFilterProperty[] = [
  { type: ContactType.BUILDER, label: "Builder" },
  { type: ContactType.SUPERVISOR, label: "Supervisor" },
];

export function useContacts() {
  // State
  const [reloadState, setReloadState] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>();
  const [queriedContacts, setQueriedContacts] = useState<Contact[]>();
  const [activeContactId, setActiveContactId] = useState<string>();
  const [keyword, setContactKeyword] = useState<null | string | undefined>();
  const [contactFilter, setContactFilter] = useState<ContactFilterProperty>();

  // Load them
  const loadContacts = async () => {
    try {
      // Get data from DB
      let data = await DataStore.query(
        Contact, 
        ///Predicates.ALL,
        (c) => c.deleted("ne", true),
        {
          sort: (s) => s.email(SortDirection.ASCENDING)
        }
      );
      //console.log(data);
      setContacts(data);
    } catch (error) {
      console.log("Error loading contacts: ", error);
    }
  };

  const queryContacts = async () => {
    // Create regex
    let keywordRegex = new RegExp(keyword || "", "i");

    let qedContacts = contacts
      ?.filter((contact) => {
        // If filter is put on check it
        if (contactFilter) {
          if (contact.type === contactFilter.type) return true;
          return false;
        } else {
          return true;
        }
      })
      .filter((contact) => {
        // If keyword is set
        if (contact.email?.match(keywordRegex)) return true;
        if (contact.name?.match(keywordRegex)) return true;
        return false;
      });

    setQueriedContacts(qedContacts);
  };

  // Sort by change
  const handleFilterChange = (label: string) => {
    let filterBy = ContactFilterProperties.find(
      (filterOption) => filterOption.label === label
    );
    filterBy ? setContactFilter(filterBy) : setContactFilter(undefined);
  };

  // Create new
  const createContact = async () => {
    let newContact = await DataStore.save(
      new Contact({ type: ContactType.BUILDER })
    );
    setActiveContactId(newContact.id);
    loadContacts();
  };

  // DElte contact
  const deleteContact = async () => {
    try {
      // Get project
      let original = await DataStore.query(Contact, activeContactId!);
      console.log(123);
      console.log(original);
      console.log(456);
      // remove all
      await DataStore.save(
        Contact.copyOf(original!, (updated) => {
          updated.deleted = true;
        })
      );
    } catch (error) {
      console.log("Error deleting contact: ", error);
    }
  };

  useEffect(() => {
    queryContacts();
  }, [contacts, keyword, contactFilter]);

  useEffect(() => {
    loadContacts();
  }, []);

  useEffect(() => {
    if (reloadState) {
      setReloadState(false);
      loadContacts();
    }
  }, [reloadState]);

  return {
    setContactKeyword,
    queriedContacts,
    contactFilter,
    setContactFilter,
    handleFilterChange,
    activeContactId,
    setActiveContactId,
    createContact,
    setReloadState,
    deleteContact,
  };
}
