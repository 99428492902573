import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { TimesheetSortProperties } from "../../hooks";

interface ContactFilterItemProps {
  timesheetSort: any | undefined;
  handleSortChange: (value: string) => void;
}

export const TimesheetSortItem: React.FC<any> = (props) => {
  return (
    <IonItem>
      <IonLabel>Sort:</IonLabel>
      <IonSelect
        value={props.timesheetSort?.value}
        placeholder="Normal"
        onIonChange={(e) => props.handleSortChange(e.detail.value as string)}
      >
        {/* Clear stuff */}
        <IonSelectOption value={undefined}>Normal</IonSelectOption>

        {/* List options */}
        {TimesheetSortProperties.map((filter, index) => (
          <IonSelectOption key={index} value={filter.value}>
            {filter.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
