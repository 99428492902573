import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  eyeOutline,
  radioButtonOn,
  trashOutline,
} from "ionicons/icons";
import { useTimesheets, useUser } from "../../hooks";
import { Timesheet, TimesheetStatus, TradeType } from "../../models";
import "./TimesheetCard.css";
interface TimesheetCardProps {
  timesheet: Timesheet;
  onEdit: () => void;
  onApproval?: () => void;
  onDelete?: () => void;
}

export const TimesheetCard: React.FC<TimesheetCardProps> = (props) => {
  // Connect to contect
  const { security } = useUser();

  const { deleteTimesheet } = useTimesheets();

  // Timestamps
  const start = new Date(props.timesheet.date + "T" + props.timesheet.start);
  const end = new Date(props.timesheet.date + "T" + props.timesheet.end);

  const startDate = start.toLocaleDateString("en-US", {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'});

  let addoneday = false;

  if (end.getTime() < start.getTime()) {
    addoneday = true;
  }

  console.log({start, end});

  // Hours
  const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
  const fullminutes = Math.floor(hduration / 60) - (props.timesheet.lunch ? 30 : 0);
  const hours = Math.floor(fullminutes / 60);
  const minutes = (fullminutes % 60);
  const shours = hours + 'h' + (minutes % 60) + 'min';

  // Status
  let statusColor = "primary";
  if (props.timesheet?.status === TimesheetStatus.REJECTED)
    statusColor = "danger";
  if (props.timesheet?.status === TimesheetStatus.APPROVED)
    statusColor = "medium";

  return (
    <IonCard className="timesheet-card">
      {/* Action buttons */}
      <IonCardHeader>
        <IonToolbar color="translucent">
          <IonButtons slot="start">
            <IonButton color={statusColor}>{props.timesheet?.status}</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {(security.isAdmin || security.isOffice) &&
              props.timesheet.status === TimesheetStatus.UNAPPROVED && (
                <IonButton onClick={() => {
                  console.log(props.onApproval);
                  if (props.onApproval) {
                    props.onApproval();
                  }
                }}>
                  <IonIcon slot="icon-only" icon={checkmarkCircleOutline} />
                </IonButton>
              )}
            <IonButton onClick={() => {
              deleteTimesheet(props.timesheet.id);
              if (props.onDelete) {
                props.onDelete();
              }                  
            }}>
              <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
            </IonButton>
            <IonButton onClick={() => props.onEdit()}>
              <IonIcon slot="icon-only" icon={eyeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardHeader>

      {/* Timesheet details */}
      <IonCardContent>
        <IonCardTitle>
          {props.timesheet.employee?.firstName ? props.timesheet.employee?.firstName + (props.timesheet.employee?.lastName ? " " + props.timesheet.employee?.lastName : "") : props.timesheet.employee?.username}<br /><br />
          {shours} - {startDate} <br />
        </IonCardTitle>
        <IonCardSubtitle>
          {props.timesheet.project?.code?.toLocaleUpperCase() +
            "-" +
            props.timesheet.project?.number}
        </IonCardSubtitle>

        <IonText>
          <IonIcon
            icon={radioButtonOn}
            color={
              props.timesheet.trade === TradeType.PLUMBING
                ? "secondary"
                : "tertiary"
            }
          />
          {" " + props.timesheet.trade}
        </IonText>
        <IonItem lines="none">
          <IonLabel position="stacked">Details</IonLabel>
          <IonTextarea readonly rows={3} value={props.timesheet.details} />
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};
