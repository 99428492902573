import { IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";

export const RevenueListHeader: React.FC = () => {
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Job PO#</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Invoice #</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="3">
            <IonText color="medium">Description</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Amount</IonText>
          </IonCol>
          <IonCol size="12" sizeSm="2">
            <IonText color="medium">Date</IonText>
          </IonCol>
          <IonCol className="ion-text-right" size="12" sizeSm="1">
            <IonText color="medium">Edit</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
