import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import { Invoice } from "../../models";
import { EditButton } from "../Buttons";
import { useInvoices } from "../../hooks";
import React, { useState } from "react";

interface RevenueItemProps {
  invoice: Invoice;
  onEdit?: () => void;
}

export const RevenueItem: React.FC<RevenueItemProps> = (props) => {

  const { getPOs } = useInvoices();

  let [ pos, setPos ] = useState<any>("-");

  React.useEffect(() => {
    getPOs(props.invoice.projectIds as Array<string>).then((data) => {
      setPos(data);
    });
  }, []);

  const start = new Date(props.invoice.date + "T00:00:00");

  const formattedDate = start.toLocaleDateString("en-US", {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'});


  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="2">
            {pos[0]}
          </IonCol>
          {/* Invoice number */}
          <IonCol size="12" sizeSm="2">
            {props.invoice.number}
          </IonCol>
          {/* Description */}
          <IonCol size="12" sizeSm="3">
            {props.invoice.description}
          </IonCol>
          {/* Amount */}
          <IonCol size="12" sizeSm="2">
            ${props.invoice.amount}
          </IonCol>
          {/* Date */}
          <IonCol size="12" sizeSm="2">
            {formattedDate}{" "}
          </IonCol>
          <IonCol className="ion-text-right" sizeSm="1" size="12">
            {props.onEdit !== undefined && 
              <EditButton onEdit={() => {
                if (props.onEdit !== undefined) {
                  props.onEdit();
                }
              }} />
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
