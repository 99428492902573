import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { TimeOff } from "../models";
import { formatAWSDate } from "../_utils/datetime";
import { useUser } from "./useUser";

export function useTimeOffs() {
  // User
  const { authUser, security } = useUser();

  // State
  const [timeOffDates, setTimeOffDates] = useState<TimeOff[]>();
  const [queriedTimeOff, setQueriedTimeOff] = useState<TimeOff[]>();
  const [activeTimeOffId, setActiveTimeOffId] = useState<null | string | undefined>();
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // Load onCall event
  const loadTimeOffEvents = async () => {
    try {
      // Create date string limit
      let date = formatAWSDate(new Date());

      //Load data
      let data = await DataStore.query(
        TimeOff,
        (c) => c.end("ge", date).deleted("ne", true),
        {
          sort: (s) => s.start(SortDirection.ASCENDING),
        }
      );

        console.log(data);
      // Update state
      setTimeOffDates(data);
    } catch (error) {
      console.log("Error loading on call data");
    }
  };

  const queryTimeOffDates = async () => {
    try {
      // Create regex
      let keywordRegex = new RegExp(keyword || "", "i");

      // Apply search
      let quedTimeOffDates = timeOffDates?.filter((timeOff) => {
        //  Check empleyee email
        if (timeOff.employee?.email?.match(keywordRegex)) return true;

        // Check name
        if (timeOff.employee?.username?.match(keywordRegex)) return true;

        // Check date
        if (timeOff.start.match(keywordRegex)) return true;

        // Default
        return false;
      });

      // Update state
      setQueriedTimeOff(quedTimeOffDates);
    } catch (error) {
      console.log("Error qu'ing oncall data");
    }
  };

  // Create timeoff event
  const createTimeOffEvent = async () => {
    try {
      //
      const newTimeOffEvent = await DataStore.save(
        new TimeOff({
          start: formatAWSDate(new Date()),
          end: formatAWSDate(new Date()),
        })
      );

      // Reload lsit
      loadTimeOffEvents();

      // Open modal
      setActiveTimeOffId(newTimeOffEvent.id);
    } catch (error) {
      console.log("Error creating new oncall event");
    }
  };
  // Query on shift
  useEffect(() => {
    queryTimeOffDates();
  }, [keyword, timeOffDates]);

  // Reload data
  useEffect(() => {
    if (!activeTimeOffId) loadTimeOffEvents();
  }, [activeTimeOffId]);

  // Load data on connect
  useEffect(() => {
    loadTimeOffEvents();
  }, [authUser]);

  return {
    queriedTimeOff,
    keyword,
    setKeyword,
    activeTimeOffId,
    setActiveTimeOffId,
    createTimeOffEvent,
  };
}
