import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Address, Contact, Project, ProjectStatus } from "../models";
import { useUser } from ".";
import { useLocation, useHistory } from "react-router-dom";

// Errors to display
interface FormErrors {
  code?: boolean;
  message?: string;
  data?: Array<string>;
}

interface ProjectForm {
  code: null | string | undefined;
  number: number | undefined;
  description: null | string | undefined;
  status: null | string | undefined;
  customerId: null | string | undefined;
  addressId: null | string | undefined;
  supervisorId: null | string | undefined;
  customer: Contact | undefined;
  address: Address | undefined;
  noteIds: (string | null)[] | undefined;
}

export function useProject() {
  // Edit stuff\
  const [activeProjectId, setActiveProjectId] = useState<string>();
  const [activeProject, setActiveProject] = useState<ProjectForm>();
  const [project, setProject] = useState<Project>();
  const [errors, setErrors] = useState<FormErrors>();

  const location = useLocation();
  const history = useHistory();

  // Load active project
  const loadProject = async () => {
    try {
      // If not there, reload
      let project = await DataStore.query(Project, activeProjectId!);

      // Project details
      setActiveProject({
        code: project?.code,
        number: project?.number !== null ? project?.number : undefined,
        description: project?.description,
        status: project?.status,
        customerId: project?.projectCustomerId,
        addressId: project?.projectAddressId,
        noteIds: project?.noteIds !== null ? project?.noteIds : undefined,
        customer: project?.customer !== null ? project?.customer : undefined,
        address: project?.address !== null ? project?.address : undefined,
        supervisorId: project?.projectSupervisorId,
      });

      // Set project meta data
      setProject(project);
    } catch (error) {
      console.log("There was an error loading the project: ", error);
    }
  };

  const updateBuilder = async (id: null | string | undefined) => {
    // Check code
    try {
      if (id) {
        // Get customer code
        let customer = await DataStore.query(Contact, id);

        // Get last job
        let project = await DataStore.query(
          Project,
          (c) => c.code("eq", customer?.code!),
          { sort: (s) => s.number(SortDirection.DESCENDING), limit: 1 }
        );

        // Determine next job jumber
        let nextProject = project.length === 0 ? 1 : project[0].number! + 1;

        setActiveProject({
           ...activeProject!,
           code: customer?.code,
           number: nextProject,
          customerId: customer?.id,
          customer: customer,
        });
      } else {
        setActiveProject({
          ...activeProject!,
          code: "",
          number: 1,
          customerId: undefined,
          customer: undefined,
        });
      }
    } catch (error) {
      console.log("Error updating project builder");
    }
  };

  const checkPO = async (value: number) => {
    // Check if project code exists exists
    try {
      // Get last job
      let project = await DataStore.query(
        Project,
        (c) => c.code("eq", activeProject!.code!).number("eq", value),
        { sort: (s) => s.number(SortDirection.DESCENDING), limit: 1 }
      );

      // Determine next job jumber
      let nextProject = project.length === 0 ? value : value + 1;

      // Set state
      setActiveProject({
        ...activeProject!,
        number: nextProject,
      });
    } catch (error) {
      console.log("Error checking po");
    }
  };

  const setPO = async () => {
    // Check if project code exists exists
    try {
      // po temp number
      let value = 1;

      // Get last job
      let project = await DataStore.query(
        Project,
        (c) => c.code("eq", activeProject!.code!),
        { sort: (s) => s.number(SortDirection.DESCENDING), limit: 1 }
      );

      // Determine next job jumber
      let nextProject = project.length === 0 ? value : value + 1;

      // Set state
      setActiveProject({
        ...activeProject!,
        number: nextProject,
      });
    } catch (error) {
      console.log("Error checking po");
    }
  };

  const checkCode = async () => {

    /* VALIDATIONS */
    let blankmandatories = [];
    {
      if (activeProject?.customerId === undefined || activeProject?.customerId === null || activeProject?.customerId === '') {
        blankmandatories.push('BUILDER');
      }
      if (activeProject?.code === undefined || activeProject?.code === null || activeProject?.code === '') {
        blankmandatories.push('PO');
      }
      if (activeProject?.addressId === undefined || activeProject?.addressId === null) {
        blankmandatories.push('ADDRESS');
      }
    }

    if (blankmandatories.length > 0) {
      setErrors({ ...errors, code: true, message: ('Missing values for: ' + blankmandatories.join(', ')), data: blankmandatories});
    } else {
      setErrors({ ...errors, code: false, message: '', data: [] });
    }

  };

  const saveProject = async (doredirect?: any) => {

    if (doredirect === undefined || doredirect === null) {
      doredirect = false;
    }
    if (typeof doredirect !== 'boolean') {
      doredirect = false;
    }

    try {
      let original = await DataStore.query(Project, activeProjectId!);
      let updatedObject = await DataStore.save(
        Project.copyOf(original!, (updated) => {
          updated.code = activeProject?.code;
          updated.number = activeProject?.number;
          updated.customer = undefined;
          updated.projectCustomerId = activeProject?.customerId;
          updated.address = undefined;
          updated.projectAddressId = activeProject?.addressId;
          updated.supervisor = undefined;
          updated.projectSupervisorId = activeProject?.supervisorId;
          updated.description = activeProject?.description;
          updated.status = activeProject?.status as ProjectStatus;
        })
      );    
      
      if (doredirect) {
        history.push("jobs/" + updatedObject.id);
      }
      
    } catch (error) {
      console.log("Error saving project");
    }
  };

  // Code changing
  useEffect(() => {
    checkCode();
  }, [activeProject?.code, activeProject?.customerId, activeProject?.addressId, activeProject?.address]);

  // Trigger project load
  useEffect(() => {
    if (activeProjectId) loadProject();
  }, [activeProjectId]);

  return {
    activeProjectId,
    activeProject,
    project,
    setActiveProjectId,
    setActiveProject,
    checkPO,
    setPO,
    updateBuilder,
    saveProject,
    errors,
  };
}
