import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { ContactFilterProperties, ContactFilterProperty } from "../../hooks";

interface ContactFilterItemProps {
  contactFilter: ContactFilterProperty | undefined;
  handleFilterChange: (label: string) => void;
}

export const ContactFilterItem: React.FC<ContactFilterItemProps> = (props) => {
  return (
    <IonItem>
      <IonLabel>Filter:</IonLabel>
      <IonSelect
        value={props.contactFilter?.label}
        placeholder="All"
        onIonChange={(e) => props.handleFilterChange(e.detail.value as string)}
      >
        {/* Clear stuff */}
        <IonSelectOption value={undefined}>All</IonSelectOption>

        {/* List options */}
        {ContactFilterProperties.map((filter, index) => (
          <IonSelectOption key={index} value={filter.label}>
            {filter.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
