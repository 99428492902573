import { Dispatch, SetStateAction } from "react";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import {
  CalendarCard,
  Header,
  EventModal,
  TimeOffCard,
  TimeOffPopover,
  OnCallCard,
  TimeOffSummaryCard,
  OnCallSummaryCard,
} from "../components";
import { useEvents, useTimeOff, useUser } from "../hooks";

interface ScheduleProps {}

export const Schedule: React.FC<ScheduleProps> = (props) => {
  // user security
  const { security } = useUser();

  // Work events
  const {
    events,
    activeEventId,
    setActiveEventId,
    createEvent,
    deleteEvent,
    setReloadState,
  } = useEvents();

  // Persoanl date
  const {
    timeOffEvents,
    timeOffRequests,
    activeTimeOffId,
    setActiveTimeoffId,
    updateStatus,
    deleteTimeoff,
  } = useTimeOff();

  return (
    <IonPage>
      <Header title={"Schedule"} />
      <IonContent fullscreen>
        <IonGrid>
          {/* Employee top section */}
          {security.isField && (
            <IonRow>
              <IonCol>
                <OnCallCard />
              </IonCol>
              <IonCol>
                <TimeOffCard />
              </IonCol>
            </IonRow>
          )}

          {/* Office top section */}
          {(security.isOffice || security.isAdmin) && (
            <IonRow>
              <IonCol>
                <OnCallSummaryCard addEvent={createEvent} />
              </IonCol>
              <IonCol>
                <TimeOffSummaryCard
                  timeOffRequests={timeOffRequests}
                  updateStatus={updateStatus}
                />
              </IonCol>
            </IonRow>
          )}

          {/* Calendar */}
          <IonRow>
            <IonCol>
              <CalendarCard
                events={events || []}
                timeOff={timeOffEvents || []}
                setActiveEventId={setActiveEventId as React.Dispatch<SetStateAction<string | null | undefined>>}
                setTimeOffId={setActiveTimeoffId as React.Dispatch<SetStateAction<string | null | undefined>>}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Display Events */}
        <EventModal
          eventId={activeEventId}
          deleteEvent={deleteEvent}
          onDismiss={() => {
            setActiveEventId(undefined);
            setReloadState(true);
          }}
        />

        {/* Display timeoff */}
        <TimeOffPopover
          timeoffId={activeTimeOffId}
          deleteTimeoff={deleteTimeoff}
          onDismiss={() => setActiveTimeoffId(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};
