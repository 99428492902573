import {
  IonButtons,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonText,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  DateInput,
  CreateButton,
  Header,
  LayoutButton,
  SearchCard,
  TimesheetCard,
  TimesheetItem,
  TimesheetModal,
} from "../components";
import { DisplayLayout } from "../context";
import { useTheme, useTimesheets, useUser } from "../hooks";
import { TimesheetStatus } from "../models";
import { TimesheetSortItem } from "../components/Items/TimesheetSortItem";
import { useState } from "react";
import { formatAWSDate } from "../_utils/datetime";

interface TimesheetsProps {}

export const Timesheets: React.FC<TimesheetsProps> = (props) => {
  // Layout style
  const { layout } = useTheme();

  // Tap into hook
  const {
    setReloadState,
    queriedTimesheets,
    setKeyword,
    setActiveTimesheetID,
    activeTimesheetId,
    updateStatus,
    timesheetSort,
    handleSortChange
  } = useTimesheets();

  const [seed, setSeed] = useState<number>(0);
  // const reset = () => {
  //   loadTimesheets().then(() => setSeed(Math.random()));
  // };

  const handleSortChange2 = async (value: string) => {
    handleSortChange(value).then(() => {
      //setSeed(Math.random() * 100);
      setReloadState(true);
    });
  };

  const getFormattedTimeFromFullMinutes = (fullminutes: number) => {
    const hours = Math.floor(fullminutes / 60);
    const minutes = (fullminutes % 60);
    const shours = hours + 'h' + (minutes % 60) + 'min';
    return shours;
  };

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);
  
  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined); // sstartdate
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined); // senddate
  const [filterBillingStatus, setBillingStatus] = useState<string>("all");

  const getBillingStatusMapping = (billing_status: string) => {
    switch (billing_status) {
      case "billed":
        return TimesheetStatus.BILLED;
      case "unbilled":
        return TimesheetStatus.UNBILLED;
    }
  };

  const { security } = useUser();

  return (
    <IonPage class="printable">
      {/* Header title */}
      <Header title={"Timesheets"} />

      {/* Page content */}
      <IonContent fullscreen>

      <IonRow>
        <IonCol size="5">
          {/* Date */}
          <DateInput
            tid="aaaa"
            label="From"
            value={filterStartDate}
            disabled={false}
            onChange={(value) => {
                setFilterStartDate(value);
              }
            }
            
          />
        </IonCol>
        <IonCol size="5">
          {/* Date */}
          <DateInput
              tid="bhbbb"
              label="To"
              value={filterEndDate}
              disabled={false}
              onChange={(value) => {
                  setFilterEndDate(value);
                }
              }
            />
        </IonCol>
        <IonCol size="2">
          <IonButtons>
            <IonButton
              expand="full"
              onClick={() => {
              //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
              //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=0,left=0");
              //if (win) {
              //  win.document.body.innerHTML = "HTML";
              //}
                // if (window && window.document) {
                //   let head = window?.document?.head.innerHTML;
                //   let content = window?.document?.getElementsByClassName('ion-page')[0].innerHTML;
                //   let childHtml = '<html><head>' + head + '</head><body>' + content + '</body></html>';
                //   //var table = window.document.getElementById('printArea').innerHTML;
                //   // let table = window.document.getElementById('').innerHTML;
                //   let myWindow = window.open('', '', 'width=800, height=600');
                //   if (myWindow) {
                //     myWindow.document.write(childHtml);
                //     myWindow.print();
                //   }
                // }

                if (window) {
                  window.print();
                }
                
      
              }}
            >
              PRINT
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Billing Status:</IonLabel>
          <IonSelect style={{color: "#065BAA"}} value={filterBillingStatus} placeholder="All" onIonChange={(e) => setBillingStatus(e.detail.value)} >
            <IonSelectOption value="all">-- All --</IonSelectOption>
            <IonSelectOption value="billed">Billed</IonSelectOption>
            <IonSelectOption value="unbilled">Non Billed</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      

        {/* Search timesheets */}
        <SearchCard onKeywordChange={setKeyword} />

        {/* Grid layout */}
        <IonGrid>

          <IonRow>
            <IonCol size="10" sizeMd="3">
              <TimesheetSortItem
                timesheetSort={timesheetSort}
                handleSortChange={handleSortChange2}
              />
            </IonCol>
            {/* Display style */}
            <IonCol size="2" offsetMd="7">
              <IonButtons style={{ justifyContent: "flex-end" }}>
                {/* <CreateButton onCreate={() => createContact()} /> */}
                <LayoutButton />
              </IonButtons>
            </IonCol>
          </IonRow>

          {/*  List */}
          {layout === DisplayLayout.TABLE && (
            <IonRow>
              <IonCol>

                {(true || (timesheetSort !== null && timesheetSort !== undefined)) &&
                  <IonList lines="inset">
                  <IonListHeader>
                    <IonText>Labour</IonText>
                  </IonListHeader>

                  <IonItem>
                    <IonGrid>
                      <IonRow>
                        {/* Job details */}
                        <IonCol sizeSm="1">
                        </IonCol>

                      {!security.isField && 
                        <IonCol sizeSm="3">
                          <IonItem>
                            <IonText>
                              Employee
                            </IonText>
                          </IonItem>
                        </IonCol>
                      }
                      
                      <IonCol sizeSm="1">
                        <IonItem>
                          <IonText>
                            Project
                          </IonText>
                        </IonItem>
                      </IonCol>

                      <IonCol sizeSm="1">
                        <IonItem>
                          <IonText>
                            Hours
                          </IonText>
                        </IonItem>
                      </IonCol>

                      <IonCol sizeSm="1">
                        <IonItem>
                          <IonText>
                            Date
                          </IonText>
                        </IonItem>
                      </IonCol>

                      <IonCol sizeSm="1">
                        <IonItem>
                          <IonText>
                            Action
                          </IonText>
                          <IonLabel></IonLabel>
                        </IonItem>
                      </IonCol>

                      {/* Hours details */}
                      <IonCol sizeSm="2">
                        <IonItem lines="none">
                          <IonText>Details</IonText>
                        </IonItem>
                      </IonCol>

                      
                      {/* <IonCol sizeSm="2">
                        <IonButton fill="clear" color={statusColor}>
                          {props.timesheet?.status}
                        </IonButton>
                      </IonCol> */}

                      {/* Edit button */}
                      <IonCol sizeSm="2" className="ion-text-right">
                        
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                  {queriedTimesheets?.filter((o) => { 
                    return (!filterBillingStatus || filterBillingStatus === 'all' || (["billed", "unbilled"].includes(filterBillingStatus!) && getBillingStatusMapping(filterBillingStatus) == o.billing_status)) && (o.date ? ((!filterStartDate || o.date >= formatAWSDate(filterStartDate)) && (!filterEndDate || o.date <= formatAWSDate(filterEndDate))) : false);
                   }).map((timesheet: any) => {
                      return (
                        <TimesheetItem
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                          onApproval={() =>
                            updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                          }
                          onUpdate={() => {
                            setReloadState(true);
                          }}
                          // onDelete={() => window.location.reload()}
                        />
                      );
                    })}
                    <IonItem>
                      <IonLabel style={{fontWeight: "bold"}}>
                        TOTAL APPROVED HOURS: {getFormattedTimeFromFullMinutes(queriedTimesheets?.filter((o) => { 
                          return o.status === TimesheetStatus.APPROVED && (!filterBillingStatus || filterBillingStatus === 'all' || (["billed", "unbilled"].includes(filterBillingStatus!) && getBillingStatusMapping(filterBillingStatus) == o.billing_status)) && (o.date ? ((!filterStartDate || o.date >= formatAWSDate(filterStartDate)) && (!filterEndDate || o.date <= formatAWSDate(filterEndDate))) : false);
                        }).reduce((accumulator, currentValue, currentIndex, array) => {
                          const start = new Date(currentValue.date + "T" + currentValue.start);
                          const end = new Date(currentValue.date + "T" + currentValue.end);
                          let addoneday = false;
                          if (end.getTime() < start.getTime()) {
                            addoneday = true;
                          }
                          const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
                          const fullminutes = Math.floor(hduration / 60) - (currentValue.lunch ? 30 : 0);
                          return accumulator + fullminutes;
                        }, 0)!)}
                      </IonLabel>
                    </IonItem>
                    {/* <IonItem>
                      <IonLabel style={{fontWeight: "bold"}}>
                        TOTAL UNAPPROVED HOURS: {getFormattedTimeFromFullMinutes(queriedTimesheets?.filter((o) => { 
                          return o.status === TimesheetStatus.UNAPPROVED && (o.date ? (o.date >= formatAWSDate(filterStartDate) && o.date <= formatAWSDate(filterEndDate)) : false);
                        }).reduce((accumulator, currentValue, currentIndex, array) => {
                          const start = new Date(currentValue.date + "T" + currentValue.start);
                          const end = new Date(currentValue.date + "T" + currentValue.end);
                          let addoneday = false;
                          if (end.getTime() < start.getTime()) {
                            addoneday = true;
                          }
                          const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
                          const fullminutes = Math.floor(hduration / 60) - (currentValue.lunch ? 30 : 0);
                          return accumulator + fullminutes;
                        }, 0)!)}
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel style={{fontWeight: "bold"}}>
                        TOTAL HOURS: {getFormattedTimeFromFullMinutes(queriedTimesheets?.filter((o) => { 
                          return o.date ? (o.date >= formatAWSDate(filterStartDate) && o.date <= formatAWSDate(filterEndDate)) : false;
                        }).reduce((accumulator, currentValue, currentIndex, array) => {
                          const start = new Date(currentValue.date + "T" + currentValue.start);
                          const end = new Date(currentValue.date + "T" + currentValue.end);
                          let addoneday = false;
                          if (end.getTime() < start.getTime()) {
                            addoneday = true;
                          }
                          const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
                          const fullminutes = Math.floor(hduration / 60) - (currentValue.lunch ? 30 : 0);
                          return accumulator + fullminutes;
                        }, 0)!)}
                      </IonLabel>
                    </IonItem> */}
                  </IonList>
                }
                

                {/* Submitted/Unapproved List */}
                {false && (timesheetSort === null || timesheetSort === undefined) &&
                  <IonList lines="inset">
                  <IonListHeader>
                    <IonText>Unapproved</IonText>
                  </IonListHeader>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return timesheet.status === TimesheetStatus.UNAPPROVED;
                    })
                    .map((timesheet) => {
                      return (
                        <TimesheetItem
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                          onApproval={() =>
                            updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                          }
                          onDelete={() => window.location.reload()}
                        />
                      );
                    })}
                  </IonList>
                }

                {/* Approved list */}
                {false && (timesheetSort === null || timesheetSort === undefined) &&
                  <IonList lines="inset">
                    <IonListHeader>
                      <IonText>Approved</IonText>
                    </IonListHeader>
                    {queriedTimesheets
                      ?.filter((timesheet) => {
                        return timesheet.status === TimesheetStatus.APPROVED;
                      })
                      .map((timesheet) => {
                        return (
                          <TimesheetItem
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                            onDelete={() => window.location.reload()}
                          />
                        );
                      })}
                  </IonList>
                }

                {/* Rejected list
                <IonList lines="inset">
                  <IonListHeader>
                    <IonText>Rejected</IonText>
                  </IonListHeader>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return timesheet.status === TimesheetStatus.REJECTED;
                    })
                    .map((timesheet) => {
                      return (
                        <TimesheetItem
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                          onApproval={() =>
                            updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                          }
                        />
                      );
                    })}
                </IonList> */}
              </IonCol>
            </IonRow>
          )}

          {/* Card layout */}
          {layout === DisplayLayout.GRID && (
            <>
              
              {(true || (timesheetSort !== null && timesheetSort !== undefined)) &&
              <>
                <IonRow>
                  <IonCol>
                    <IonText>Labour</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {queriedTimesheets?.map((timesheet) => {
                      return (
                        <IonCol key={timesheet.id} size="12" sizeMd="4">
                          <TimesheetCard
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                            onApproval={() =>
                              updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                            }
                            onDelete={() => window.location.reload()}
                          />
                        </IonCol>
                      );
                    })}
                </IonRow>
              </>
              }

              {/* Submitted stuff */}
              {false && (timesheetSort === null || timesheetSort === undefined) &&
              <>
                <IonRow>
                  <IonCol>
                    <IonText>Unapproved</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return timesheet.status === TimesheetStatus.UNAPPROVED;
                    })
                    .map((timesheet) => {
                      return (
                        <IonCol key={timesheet.id} size="12" sizeMd="4">
                          <TimesheetCard
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                            onApproval={() =>
                              updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                            }
                            onDelete={() => window.location.reload()}
                          />
                        </IonCol>
                      );
                    })}
                </IonRow>
              </>
              }

              {/* Approved */}
              {false && (timesheetSort === null || timesheetSort === undefined) &&
              <>
                <IonRow>
                  <IonCol>
                    <IonText>Approved</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return timesheet.status === TimesheetStatus.APPROVED;
                    })
                    .map((timesheet) => {
                      return (
                        <IonCol key={timesheet.id} size="12" sizeMd="4">
                          <TimesheetCard
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                            onDelete={() => window.location.reload()}
                          />
                        </IonCol>
                      );
                    })}
                </IonRow>
              </>
              }

              {/* Rejected */}
              {/* <IonRow>
                <IonCol>
                  <IonText>Rejected</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                {queriedTimesheets
                  ?.filter((timesheet) => {
                    return timesheet.status === TimesheetStatus.REJECTED;
                  })
                  .map((timesheet) => {
                    return (
                      <IonCol key={timesheet.id} size="12" sizeMd="4">
                        <TimesheetCard
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                          onApproval={() =>
                            updateStatus(timesheet.id, TimesheetStatus.APPROVED)
                          }
                        />
                      </IonCol>
                    );
                  })}
              </IonRow> */}
            </>
          )}
        </IonGrid>

        {/* Data control */}
        <TimesheetModal
          timesheetId={activeTimesheetId}
          onDismiss={() => setActiveTimesheetID(undefined)}
          onEditSave={() => {
            setReloadState(true);
          }}
        />
      </IonContent>
    </IonPage>
  );
};
