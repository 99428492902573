import { useEffect, useState } from "react";
import { useAddress, useUser } from ".";
import {
  Address,
  Project,
  Timesheet,
  TimesheetStatus,
  TradeType,
} from "../models";
import { DataStore, Predicates, SortDirection, Storage } from "aws-amplify";
import { formatAWSDate, formatAWSTime } from "../_utils/datetime";

interface TimesheetForm {
  date: Date;
  start: Date;
  end: Date;
  lunch: boolean;
  trade: TradeType;
  details: string;
  photos: (string | null)[];
  status: TimesheetStatus;
  note: null | string | undefined;
}

export const TimesheetSortProperties: any[] = [
  { value: "date.ascending", label: "Date Ascending" },
  { value: "date.descending", label: "Date Descending" },
  { value: "status.ascending", label: "Status Ascending" },
  { value: "status.descending", label: "Status Descending" },
  { value: "invoicestatus.ascending", label: "Invoice Status Ascending" },
  { value: "invoicestatus.descending", label: "Invoice Status Descending" },
  { value: "employeename.ascending", label: "Employee Name Ascending" },
  { value: "employeename.descending", label: "Employee Name Descending" },
];

export function useTimesheets() {
  // State
  const [reloadState, setReloadState] = useState(false);
  const [timesheets, setTimesheets] = useState<Timesheet[]>();
  const [queriedTimesheets, setQueriedTimesheets] = useState<Timesheet[]>();
  const [timesheetSort, setTimesheetSort] = useState<any>();

  // Keyword search
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // Date range
  const [startDateRange, setStartDateRange] = useState<Date | undefined>();
  const [endDateRange, setEndDateRange] = useState<Date | undefined>();

  // Timesheet
  const [activeTimesheetId, setActiveTimesheetID] = useState<string>();

  // Get user id
  const { security, authUser } = useUser();

  // load Timesheets
  const loadTimesheets = async () => {
    try {
      console.log('22222');
      console.log(timesheetSort);
      // data
      let data: Timesheet[] = [];

      // Load jobs
      if (security.isField) {
        console.log(33333);
        // Set up limit

        let sortmethod = (s: any) => s.date(SortDirection.DESCENDING);
        if (timesheetSort) {
          console.log(timesheetSort.value);
          switch (timesheetSort.value) {
            case "date.ascending":
              sortmethod = (s: any) => s.date(SortDirection.ASCENDING).start(SortDirection.ASCENDING);
              break;
            case "date.descending":
              sortmethod = (s: any) => s.date(SortDirection.DESCENDING).start(SortDirection.DESCENDING);
              break;
            case "status.ascending":
              sortmethod = (s: any) => s.status(SortDirection.ASCENDING);
              break;
            case "status.descending":
              sortmethod = (s: any) => s.status(SortDirection.DESCENDING);
              break;
            case "invoicestatus.ascending":
              sortmethod = (s: any) => s.status(SortDirection.ASCENDING);
              break;
            case "invoicestatus.descending":
              sortmethod = (s: any) => s.status(SortDirection.DESCENDING);
              break;
            case "employeename.ascending":
              //sortmethod = (s: any) => s.status(SortDirection.ASCENDING);
              break;
            case "employeename.descending":
              //sortmethod = (s: any) => s.status(SortDirection.DESCENDING);
              break;
          }
        }

        data = await DataStore.query(
          Timesheet,
          (c) =>
            c
              .timesheetEmployeeId("eq", authUser?.id!)
              .or((c) =>
                c
                  .status("eq", TimesheetStatus.UNAPPROVED)
                  .status("eq", TimesheetStatus.APPROVED)
                  .status("eq", TimesheetStatus.REJECTED)
              )
              .deleted("ne", true),
          {
            sort: sortmethod,
          }
        );
      } else if (security.isAdmin || security.isOffice) {
        console.log(44444);
        let sortmethod = (s: any) => s.date(SortDirection.DESCENDING); //s.status(SortDirection.ASCENDING).date(SortDirection.DESCENDING);
        if (timesheetSort) {
          switch (timesheetSort.value) {
            case "date.ascending":
              sortmethod = (s: any) => s.date(SortDirection.ASCENDING).start(SortDirection.ASCENDING);
              break;
            case "date.descending":
              sortmethod = (s: any) => s.date(SortDirection.DESCENDING).start(SortDirection.DESCENDING);
              break;
            case "status.ascending":
              sortmethod = (s: any) => s.status(SortDirection.ASCENDING);
              break;
            case "status.descending":
              sortmethod = (s: any) => s.status(SortDirection.DESCENDING);
              break;
            case "invoicestatus.ascending":
              sortmethod = (s: any) => s.status(SortDirection.ASCENDING);
              break;
            case "invoicestatus.descending":
              sortmethod = (s: any) => s.status(SortDirection.DESCENDING);
              break;
            case "employeename.ascending":
              //sortmethod = (s: any) => s.employee.username(SortDirection.ASCENDING);
              break;
            case "employeename.descending":
              //sortmethod = (s: any) => s.employee.username(SortDirection.DESCENDING);
              break;
          }
        }
        data = await DataStore.query(
          Timesheet, 
          //Predicates.ALL, 
          (c) => c.deleted("ne", true),
          {
            sort: sortmethod,
          }
        );
        console.log(data);
      }
      // Add to state
      console.log(data);
      setTimesheets(data);
      queryTimesheets();
    } catch (error) {
      console.log("Error loading timesheets: ", error);
    }
  };

  // Sort by change
  const handleSortChange = async (value: string) => {
    let filterBy = TimesheetSortProperties.find(
      (filterOption) => filterOption.value === value
    );
    console.log({filterBy});
    filterBy ? setTimesheetSort(filterBy) : setTimesheetSort(undefined);
  };

  // Query timesheets
  const queryTimesheets = async () => {
    // Create regex
    let keywordRegex = new RegExp(keyword || "", "i");

    // Apply keyword
    let qedTimesheets = timesheets?.filter((timesheet) => {
      // Check project code
      if (timesheet.project?.code?.match(keywordRegex)) return true;

      // Check employee
      if (timesheet.employee?.username?.match(keywordRegex)) return true;
      if (timesheet.employee?.email?.match(keywordRegex)) return true;

      // Default
      return false;
    });

    if (timesheetSort && ['employeename.ascending', 'employeename.descending'].includes(timesheetSort.value)) {
      if (timesheetSort.value === 'employeename.ascending') {
        qedTimesheets = qedTimesheets?.sort((a, b) => {
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (timesheetSort.value === 'employeename.descending') {
        qedTimesheets = qedTimesheets?.sort((a, b) => {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }

    console.log(qedTimesheets);

    // Set state
    setQueriedTimesheets(qedTimesheets);
  };

  // Update status
  const updateStatus = async (id: string, status: TimesheetStatus) => {
    try {
      console.log("updateStatus Start");
      const original = await DataStore.query(Timesheet, id);

      await DataStore.save(
        Timesheet.copyOf(original!, (updated) => {
          updated.status = status;
        })
      );

      loadTimesheets();
    } catch (error) {
      console.log("Error updating status");
    }
  };

  // Toggle billing status
  const toggleBillingStatus = async (id: string) => {
    try {
      console.log("toggleBillingStatus Start");
      const original = await DataStore.query(Timesheet, id);

      let new_billing_status = original?.billing_status;
      if (new_billing_status === TimesheetStatus.BILLED) {
        new_billing_status = TimesheetStatus.UNBILLED;
      } else {
        new_billing_status = TimesheetStatus.BILLED;
      }

      await DataStore.save(
        Timesheet.copyOf(original!, (updated) => {
          if (origin)
          updated.billing_status = new_billing_status;
        })
      );

      loadTimesheets();
      return new_billing_status;

    } catch (error) {
      console.log("Error updating billing_status");
    }
  };

  // Toggle billing status
  const toggleStatus = async (id: string) => {
    try {
      console.log("toggleStatus Start");
      const original = await DataStore.query(Timesheet, id);

      let new_status = original?.status;
      if (new_status === TimesheetStatus.APPROVED) {
        new_status = TimesheetStatus.UNAPPROVED;
      } else {
        new_status = TimesheetStatus.APPROVED;
      }

      await DataStore.save(
        Timesheet.copyOf(original!, (updated) => {
          if (origin)
          updated.status = new_status;
        })
      );

      loadTimesheets();
      return new_status;

    } catch (error) {
      console.log("Error updating billing_status");
    }
  };

  const deleteTimesheet = async (id: string) => {
    try {
      console.log("deleteTimesheet Start");
      console.log(id);
      const original = await DataStore.query(Timesheet, id);
      console.log(original);
      if (original) {
        await DataStore.save(
          Timesheet.copyOf(original!, (updated) => {
            updated.deleted = true;
          })
        );
        //await DataStore.delete(original);
      }
      loadTimesheets();
    } catch (error) {
      console.log("Error deleting timesheet");
    }
  };

  // Create a new timesheet
  const createTimesheet = async (projectId: string) => {
    try {
      // Inti time stuff
      let initTimesheet = {
        date: new Date(),
        start: new Date(),
        end: new Date(),
      };
      initTimesheet.start.setHours(8); // Set to am that day
      initTimesheet.start.setMinutes(0);
      initTimesheet.end.setMinutes(0);

      // Add to database
      const timesheet = await DataStore.save(
        new Timesheet({
          date: formatAWSDate(initTimesheet.date),
          start: formatAWSTime(initTimesheet.start),
          end: formatAWSTime(initTimesheet.end),
          lunch: false,
          trade: authUser?.trade || TradeType.HVAC,
          photos: [],
          employee: authUser,
          timesheetProjectId: projectId,
          status: TimesheetStatus.UNAPPROVED,
          billing_status: TimesheetStatus.UNBILLED
        })
      );

      setActiveTimesheetID(timesheet.id);
    } catch (error) {
      console.log("There was a error creating a timesheet");
    }
  };

  // Trigger search
  useEffect(() => {
    queryTimesheets();
  }, [timesheets, keyword]);

  // Init data
  useEffect(() => {
    console.log(123123);
    loadTimesheets();
  }, [authUser, timesheetSort]);

  useEffect(() => {
    if (reloadState) {
      setReloadState(false);
      loadTimesheets();
    }
  }, [reloadState]);

  return {
    timesheets,
    reloadState,
    setReloadState,
    activeTimesheetId,
    setActiveTimesheetID,
    createTimesheet,
    queriedTimesheets,
    setKeyword,
    queryTimesheets,
    updateStatus,
    toggleStatus,
    toggleBillingStatus,
    deleteTimesheet,
    loadTimesheets,
    keyword,
    timesheetSort,
    handleSortChange
  };
}
