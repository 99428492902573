import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonText,
  IonButton,
  IonIcon,
  IonPopover,
  IonContent,
  IonLabel,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import {
  informationOutline,
  checkmarkOutline,
  closeOutline,
} from "ionicons/icons";
import { useTimeOff } from "../../hooks";
import { TimeOff, TimeOffStatus } from "../../models";

interface TimeOffSummaryCardProps {
  timeOffRequests: TimeOff[] | undefined;
  updateStatus: (id: string, status: TimeOffStatus) => void;
}

export const TimeOffSummaryCard: React.FC<TimeOffSummaryCardProps> = (
  props
) => {
  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        <IonToolbar>
          <IonCardTitle>Time Off Request</IonCardTitle>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          {props.timeOffRequests?.map((timeOffRequest) => {
            return (
              <IonItem key={timeOffRequest.id}>
                <IonText>
                  {timeOffRequest.employee?.username?.toLocaleUpperCase() +
                    ": " +
                    new Date(timeOffRequest.start).toDateString() +
                    "-" +
                    new Date(timeOffRequest.end).toDateString()}
                </IonText>
                <IonButton slot="end" id={"open-" + timeOffRequest.id}>
                  <IonIcon slot="icon-only" icon={informationOutline} />
                </IonButton>
                <IonButton
                  slot="end"
                  color="success"
                  onClick={() => {
                    props.updateStatus(
                      timeOffRequest.id,
                      TimeOffStatus.APPROVED
                    );
                  }}
                >
                  <IonIcon slot="icon-only" icon={checkmarkOutline} />
                </IonButton>
                <IonButton
                  slot="end"
                  color="danger"
                  onClick={() => {
                    props.updateStatus(timeOffRequest.id, TimeOffStatus.DENIED);
                  }}
                >
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
                <IonPopover
                  trigger={"open-" + timeOffRequest.id}
                  dismiss-on-select="true"
                  showBackdrop={false}
                >
                  <IonContent>
                    <IonItem>
                      <IonLabel position="stacked">Details</IonLabel>
                      <IonTextarea readonly value={timeOffRequest.details} />
                    </IonItem>
                  </IonContent>
                </IonPopover>
              </IonItem>
            );
          })}

          {props.timeOffRequests?.length === 0 && (
            <IonText>There are no requests at the moment</IonText>
          )}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};
