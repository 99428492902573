import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { SortProperty } from "../../hooks";

interface SortItemProps {
  sortProperty: SortProperty;
  sortProperties: SortProperty[];
  onSortChange: (label: string) => void;
}

export const SortItem: React.FC<SortItemProps> = (props) => {
  return (
    <IonItem>
      <IonLabel>Sort:</IonLabel>
      <IonSelect
        interface="popover"
        value={props.sortProperty.label}
        onIonChange={(e) => props.onSortChange(e.detail.value as string)}
      >
        {props.sortProperties.map((sort, index) => (
          <IonSelectOption key={index} value={sort.label}>
            {sort.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
