import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonReorder,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { eyeOutline, radioButtonOn } from "ionicons/icons";
import { Note, NoteStatus } from "../../models";
import { EditButton } from "../Buttons";

interface NoteItemInterface {
  note: Note;
  onView: () => void;
}

export const NoteItem: React.FC<NoteItemInterface> = (props) => {
  let onotets = props.note?.createdAt ? new Date(props.note?.createdAt + '')  : null;
  let notets = onotets ? onotets.toDateString() + " " + onotets.toTimeString() : null;
  let sdt = '';
  if (onotets) {
    let syyyy = onotets.getFullYear();
    let smm = onotets.getMonth() < 9 ? '0' + (onotets.getMonth() + 1) : (onotets.getMonth() + 1);
    let sdd = onotets.getDate() < 10 ? '0' + onotets.getDate() : onotets.getDate();
    let shh = onotets.getHours() < 10 ? '0' + onotets.getHours() : onotets.getHours();
    let smi = onotets.getMinutes() < 10 ? '0' + onotets.getMinutes() : onotets.getSeconds();
    let sss = onotets.getSeconds() < 10 ? '0' + onotets.getSeconds() : onotets.getSeconds();
    let sampm = onotets.getHours() >= 12 ? 'pm' : 'am';
    let shhh = onotets.getHours() % 12;
    let smmm = onotets.getMinutes();
    sdt = syyyy + '-' + smm + '-' + sdd + ' ' + (shhh + onotets.getMinutes() > 0 ? shhh + 'h' + smmm + 'min ' + sampm.toUpperCase() : shhh + sampm.toUpperCase());
  }
  return (
    <IonItem key={props.note.id}>
      <IonGrid
        className={props.note.status === NoteStatus.CLOSED ? "note-closed" : ""}
      >
        <IonRow>
          {/* Reorder  */}
          <IonCol size="12" sizeMd="1">
            <IonReorder />
          </IonCol>
          {/* Color */}
          <IonCol size="12" sizeMd="1">
            <IonIcon
              icon={radioButtonOn}
              color={
                props.note.color !== null ?
                  (
                  props.note.status === NoteStatus.OPEN
                    ? props.note.color
                    : "success"
                  )
                  :
                  undefined
              }
            />
          </IonCol>
          {/* Note */}
          <IonCol size="12" sizeMd="3">
            <IonLabel color="medium">Note</IonLabel>
            <IonText>{props.note.description}</IonText>
          </IonCol>
          {/* Attention */}
          <IonCol size="12" sizeMd="3">
          <IonLabel color="medium">Author</IonLabel>
            <IonText>{props.note.name}</IonText>
          </IonCol>
          <IonCol size="12" sizeMd="3">
            <IonLabel color="medium">Date</IonLabel>
            {notets &&
              <IonText>{sdt}</IonText>
            }
          </IonCol>
          {/* Status */}
          <IonCol size="12" sizeMd="1" className="ion-text-right">
            <EditButton onEdit={() => props.onView()} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
