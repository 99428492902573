import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";

interface SelectInputProps {
  label: string;
  value: null | string | undefined;
  options: any[];
  canEdit: boolean;
  onChange: (value: any) => void;
}

export const SelectInput: React.FC<SelectInputProps> = (props) => {
  // <!-- interface="popover" -->
  return (
    <IonItem>
      <IonLabel position="stacked">{props.label}</IonLabel>
      <IonSelect
        value={props.value}
        disabled={!props.canEdit}
        onIonChange={(e) => {
          props.onChange(e.detail.value);
        }}
      >
        {props.options.map((value, key) => {
          return (
            <IonSelectOption key={key} value={value}>
              {value}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};
