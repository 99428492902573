import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Address } from "../models";

export interface AddressForm {
  address1: null | string | undefined;
  address2: null | string | undefined;
  city: null | string | undefined;
  country: null | string | undefined;
  postal: null | string | undefined;
}

export interface AddressFormErrors {
  address?: boolean;
}

const initAddress: AddressForm = {
  address1: "",
  address2: "",
  city: "CALGARY",
  country: "CANADA",
  postal: "",
};

export function useAddress() {
  // Address state
  const [activeAddressId, setActiveAddressId] = useState<string>();
  const [address, setAddress] = useState<Address>();

  // Form state
  const [activeAddress, setActiveAddress] = useState<AddressForm>();
  const [errorAddress, setErrorAddress] = useState<AddressFormErrors>();

  // Set for data
  const fetchAddress = async () => {
    try {
      // Load data from database
      const data = await DataStore.query(Address, activeAddressId!);

      // Load into form state
      setActiveAddress({
        address1: data?.address1,
        address2: data?.address2,
        city: data?.city,
        country: data?.country,
        postal: data?.postal,
      });
      setErrorAddress(undefined);
      setAddress(data);
    } catch (error) {
      console.log("Error loading address");
    }
  };

  // load form data
  const newAddress = () => {
    try {
      // Reset state
      setActiveAddress(initAddress);
      setErrorAddress(undefined);
      setAddress(undefined);
    } catch (error) {
      console.log("Error loading address: ", error);
    }
  };

  // Error checking
  const address1Check = async () => {
    try {
      // Only check when not equal to ""
      if (activeAddress?.address1! === "") {
        setErrorAddress({ address: false });
      } else {
        // Check the database
        const data = await DataStore.query(Address, (c) =>
          c
            .id("ne", activeAddressId!)
            .address1("contains", activeAddress?.address1!)
        );

        // Trigger error to show
        data.length > 0
          ? setErrorAddress({ address: true })
          : setErrorAddress({ address: false });
      }
    } catch (error) {
      console.log("Error checking form data: ", error);
    }
  };

  const address2Check = async () => {
    try {
      // Check the database
      const data = await DataStore.query(Address, (c) =>
        c
          .id("ne", activeAddressId!)
          .address1("eq", activeAddress?.address1!)
          .address2("eq", activeAddress?.address2!)
      );
      // Trigger error to show
      data.length > 0
        ? setErrorAddress({ address: true })
        : setErrorAddress({ address: false });
    } catch (error) {
      console.log("Error checking form data: ", error);
    }
  };

  const createAddress = async () => {
    try {
      if (errorAddress?.address) {
        setActiveAddressId(undefined);
      } else {
        let data = await DataStore.save(
          new Address({
            address1: activeAddress?.address1,
            address2: activeAddress?.address2,
            city: activeAddress?.city,
            country: activeAddress?.country,
            postal: activeAddress?.postal,
          })
        );
        setActiveAddressId(data.id);
      }
    } catch (error) {
      console.log("Error creating new address: ", error);
    }
  };

  const saveAddress = async () => {
    try {
      let original = await DataStore.query(Address, activeAddressId!);
      await DataStore.save(
        Address.copyOf(original!, (updated) => {
          updated.address1 = activeAddress?.address1;
          updated.address2 = activeAddress?.address2;
          updated.city = activeAddress?.city;
          updated.country = activeAddress?.country;
          updated.postal = activeAddress?.postal;
        })
      );
    } catch (error) {
      console.log("error sav");
    }
  };

  // Trigger error check if stored address does not exist. therefore a new address is being added
  useEffect(() => {
    address1Check();
  }, [activeAddress?.address1]);

  useEffect(() => {
    activeAddress?.address2 === "" ? address1Check() : address2Check();
  }, [activeAddress?.address2]);

  // Trigger, no ID just load blank data
  useEffect(() => {
    if (activeAddressId) {
      activeAddressId === "new" ? newAddress() : fetchAddress();
    } else {
      setActiveAddress(undefined);
      setAddress(undefined);
    }
  }, [activeAddressId]);

  return {
    address,
    activeAddressId,
    setActiveAddressId,
    activeAddress,
    errorAddress,
    setActiveAddress,
    saveAddress,
    newAddress,
    createAddress,
  };
}
