import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from "@ionic/react";
import { SelectInput, StringInput, NumberInput } from "..";
import { useAuthUser } from "../../hooks";
import { SecurityGroups, TradeType } from "../../models";
import { PasswordField } from "@aws-amplify/ui-react";
import { useUser } from "../../hooks";

export const UserCreateCard: React.FC = () => {
  // Tap into hook
  const {
    formData,
    userSuccess,
    errors,
    addAuthUser,
    checkEmail,
    checkUsername,
    checkPassoword,
    setFormData,
  } = useAuthUser();

  const { username, security, logout } = useUser();

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>New User Details</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {/* Email Input*/}
        <StringInput
          label="Email"
          value={formData?.email}
          canEdit={true}
          onChange={(value) => checkEmail(value)}
          error={{
            error: errors?.emailDuplicate,
            msg: "* Email already linked to an account",
          }}
        />

        {/* Phone number */}
        <StringInput
          label="Cell"
          canEdit={true}
          value={formData?.cell}
          onChange={(value) => setFormData({ ...formData!, cell: value })}
        />

        {/* Username input */}
        <StringInput
          label="Username"
          canEdit={true}
          value={formData?.username}
          onChange={(value) => checkUsername(value)}
          error={{
            error: errors?.username,
            msg: "* Username already linked to an account",
          }}
        />

        {/* Username input */}
        <StringInput
          label="First Name"
          canEdit={true}
          value={formData?.firstName}
          onChange={(value) => setFormData({ ...formData!, firstName: value })}
          // onChange={(value) => checkUsername(value)}
          // error={{
          //   error: errors?.username,
          //   msg: "* Username already linked to an account",
          // }}
        />

        {/* Username input */}
        <StringInput
          label="Last Name"
          canEdit={true}
          value={formData?.lastName}
          onChange={(value) => setFormData({ ...formData!, lastName: value })}
          // onChange={(value) => checkUsername(value)}
          // error={{
          //   error: errors?.username,
          //   msg: "* Username already linked to an account",
          // }}
        />

        {/* Passowrd input*/}
        <PasswordField
        
          label="Password"
          value={formData?.password}
          // canEdit={true}
          onChange={(value) => checkPassoword(value.target.value)}
          minLength={8}
          enterKeyHint="aaa"
          errorMessage="* Password must be at least 8 characters"
          // error={{
          //   error: errors?.password,
          //   msg: "* Password must be at least 8 characters",
          // }}
          
        />

        {/* User security level */}
        <SelectInput
          label={"Security"}
          value={formData?.security}
          options={Object.keys(SecurityGroups)}
          canEdit={true}
          onChange={(value) =>
            setFormData({
              ...formData!,
              security: value as SecurityGroups,
            })
          }
        />

        {/* User trade tag */}
        {formData!.security.includes(SecurityGroups.FIELD) && (
          <SelectInput
            label={"Trade Tag"}
            value={formData?.trade}
            options={Object.keys(TradeType)}
            canEdit={true}
            onChange={(value) =>
              setFormData({
                ...formData!,
                trade: value as TradeType,
              })
            }
          />
        )}

        {(security.isAdmin || security.isOffice) && (formData!.security.includes(SecurityGroups.FIELD)) &&
          <>
          <NumberInput
            label="Rate ($)"
            value={formData?.rate}
            onChange={(value) => {}
              // TODO
            }
          />
          <NumberInput
            label="Amount ($)"
            value={formData?.amount}
            onChange={(value) => {}
              // TODO
            }
          />
          </>
        }

        {/* Submit button */}
        <IonButton
          onClick={() => addAuthUser()}
          expand="full"
          disabled={
            errors?.emailDuplicate ||
            errors?.password ||
            errors?.username
          }
        >
          Add User
        </IonButton>

        {/* Form submittion errors */}
        {userSuccess && (
          <IonText color="success">
            * User with information above has been added and notified with
            access code
          </IonText>
        )}

        {errors?.submit && (
          <IonText color="danger">
            * There was an error adding the User with information above. Please
            try again or contact Patrick at with the following link:{" "}
            <a href="mailto:patrick@techychameleon.com?subject=New User Error">
              Mactech Support
            </a>
          </IonText>
        )}
      </IonCardContent>
    </IonCard>
  );
};
