import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonToolbar,
} from "@ionic/react";
import {
  CreateButton,
  Header,
  ExpenseItem,
  InvoiceModal,
  RevenueItem,
  RevenueListHeader,
  ExpensesListHeader,
} from "..";
import { useInvoices } from "../../hooks";
import { Accounts, Expenses, Invoice, Project } from "../../models";

interface ProjectAccountingTabInterface {
  project: Project | undefined;
  invoices: Invoice[] | undefined;
  onRefresh: () => void;
}

export const ProjectRevenueTab: React.FC<ProjectAccountingTabInterface> = (
  props
) => {
  const { setActiveInvoiceId, activeInvoiceId, createInvoice } = useInvoices();

  return (
    <IonContent>
      {/* Page header */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Invoices"
        }
      />

      {/* List out stuff */}
      <IonCard>
        {/* Add button header */}
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <CreateButton onCreate={() => createInvoice(props.project?.id)} />
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>

        {/* List out invoices */}
        <IonCardContent>
          {/* List out revenu invoice */}
          <IonList>
            {/* <IonListHeader>Revenue</IonListHeader> */}
            <IonItemDivider color="primary">Revenue</IonItemDivider>
            <RevenueListHeader />
            {props.invoices
              ?.filter((invoice) => {
                return invoice.account === Accounts.INCOME;
              })
              .map((invoice) => {
                return (
                  <RevenueItem
                    key={invoice.id}
                    invoice={invoice}
                    onEdit={() => setActiveInvoiceId(invoice.id)}
                  />
                );
              })}
            <RevenueItem
              key="_total"
              invoice={{id: "", number: "TOTAL", amount: 0.00, description: "Total of Revenue", date: ""}}
            />
          </IonList>
        </IonCardContent>
      </IonCard>

      {/* Modal */}
      <InvoiceModal
        invoiceId={activeInvoiceId}
        onDismiss={() => {
          setActiveInvoiceId(undefined);
          props.onRefresh();
        }}
      />
    </IonContent>
  );
};
