import {
  businessOutline,
  businessSharp,
  calendarOutline,
  calendarSharp,
  callOutline,
  callSharp,
  peopleOutline,
  peopleSharp,
  personOutline,
  personSharp,
  receiptOutline,
  receiptSharp,
  stopwatchOutline,
  stopwatchSharp,
  cashOutline,
  cashSharp,
} from "ionicons/icons";

interface AppMenu {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

export const appMenu: AppMenu[] = [
  {
    title: "Jobs",
    url: "/mactech/jobs",
    iosIcon: businessOutline,
    mdIcon: businessSharp,
  },
  {
    title: "Revenue",
    url: "/mactech/revenue",
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: "Vendor Invoices",
    url: "/mactech/expenses/VENDOR",
    iosIcon: receiptOutline,
    mdIcon: receiptSharp,
  },
  {
    title: "Subcontrator Invoices",
    url: "/mactech/expenses/SUBCONTRACTOR",
    iosIcon: receiptOutline,
    mdIcon: receiptSharp,
  },
  {
    title: "Misc Invoices",
    url: "/mactech/expenses/MISCELLANEOUS",
    iosIcon: receiptOutline,
    mdIcon: receiptSharp,
  },
  {
    title: "Contacts",
    url: "/mactech/contacts",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Timesheets",
    url: "/mactech/timesheets",
    iosIcon: stopwatchOutline,
    mdIcon: stopwatchSharp,
  },
  {
    title: "OnCall",
    url: "/mactech/oncall",
    iosIcon: callOutline,
    mdIcon: callSharp,
  },

  {
    title: "Employees",
    url: "/mactech/users",
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
];

export const employeeMenu: AppMenu[] = [
  {
    title: "Jobs",
    url: "/mactech/jobs",
    iosIcon: businessOutline,
    mdIcon: businessSharp,
  },
  {
    title: "Hours",
    url: "/mactech/hours",
    iosIcon: stopwatchOutline,
    mdIcon: stopwatchSharp,
  },
  {
    title: "OnCall",
    url: "/mactech/oncall",
    iosIcon: callOutline,
    mdIcon: callSharp,
  },
];
