import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import {
  ContactCard,
  ContactItem,
  Header,
  LayoutButton,
  SearchCard,
  ContactModal,
  ContactFilterItem,
  CreateButton,
} from "../components";
import { DisplayLayout } from "../context";
import { useContacts, useTheme } from "../hooks";

interface ContactsProps {}

export const Contacts: React.FC<ContactsProps> = (props) => {
  // Hook into the matrix
  const {
    setContactKeyword,
    queriedContacts,
    activeContactId,
    contactFilter,
    handleFilterChange,
    setActiveContactId,
    createContact,
    setReloadState,
    deleteContact,
  } = useContacts();
  const { layout } = useTheme();

  return (
    <IonPage>
      {/* Header */}
      <Header title={"Contacts"} />

      {/* Page Content */}
      <IonContent>
        {/* Seach Card */}
        <SearchCard onKeywordChange={setContactKeyword} />

        {/* Layout */}
        <IonGrid>
          <IonRow>
            <IonCol size="10" sizeMd="3">
              <ContactFilterItem
                contactFilter={contactFilter}
                handleFilterChange={handleFilterChange}
              />
            </IonCol>
            {/* Display style */}
            <IonCol size="2" offsetMd="7">
              <IonButtons style={{ justifyContent: "flex-end" }}>
                <CreateButton onCreate={() => createContact()} />
                <LayoutButton />
              </IonButtons>
            </IonCol>
          </IonRow>

          {/* Display table */}
          {layout === DisplayLayout.TABLE &&
            queriedContacts?.map((contact) => {
              return (
                <IonRow key={contact.id}>
                  <IonCol onClick={() => setActiveContactId(contact.id)}>
                    <ContactItem
                      key={contact.id}
                      contact={contact}
                      onView={() => setActiveContactId(contact.id)}
                    />
                  </IonCol>
                </IonRow>
              );
            })}

          {/* Card results */}
          {layout === DisplayLayout.GRID && (
            <IonRow>
              {queriedContacts?.map((contact) => {
                return (
                  <IonCol key={contact.id} size="12" sizeMd="4">
                    <ContactCard
                      key={contact.id}
                      contact={contact}
                      onView={() => setActiveContactId(contact.id)}
                    />
                  </IonCol>
                );
              })}
            </IonRow>
          )}
        </IonGrid>
      </IonContent>

      {/* Contact modal */}
      <ContactModal
        id={activeContactId}
        onDelete={() => {
          deleteContact();
          setActiveContactId(undefined);
          setReloadState(true);
        }}
        onDismiss={() => {
          setActiveContactId(undefined);
          setReloadState(true);
        }}
      />
    </IonPage>
  );
};
