import { useLocation } from "react-router";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  arrowForwardOutline,
  stopwatchOutline,
  eyeOutline,
} from "ionicons/icons";
import { useUser } from "../../hooks";
import { Project } from "../../models";

interface ProjectItemProps {
  project: Project;
  onView: () => void;
  addTimesheet: () => void;
}

export const ProjectItem: React.FC<ProjectItemProps> = (props) => {
  const { security } = useUser();
  const location = useLocation();

  return (
    <IonItem key={props.project.id}>
      <IonGrid>
        <IonRow>
          {/* Code  */}
          <IonCol size="12" sizeSm="1">
            {"" +
              props.project.code?.toUpperCase() +
              props.project.number?.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
          </IonCol>

          {/* Builder  */}
          <IonCol size="12" sizeSm="3">
            {props.project.customer && props.project.customer.name}
          </IonCol>

          {/* Job details */}
          <IonCol size="12" sizeSm="3">
            {props.project.address && props.project.address.address1}
            {props.project.address &&
              props.project.address.address2 &&
              props.project.address.address2}
            <br />
          </IonCol>

          {/* Project descriptoin for office */}
          <IonCol sizeSm="3" size="12">
            <IonText>{props.project.description}</IonText>
          </IonCol>

          {/* Actions */}
          <IonCol sizeSm="2" size="12" className="ion-text-center">
            {security.isField && (
              <IonButton onClick={() => props.addTimesheet()}>
                +<IonIcon icon={stopwatchOutline} />
              </IonButton>
            )}
            {(security.isAdmin || security.isOffice) && (
              <>
                <IonButton onClick={() => props.onView()}>
                  <IonIcon slot="icon-only" icon={eyeOutline} />
                </IonButton>
                <IonButton
                  routerLink={location.pathname + "/" + props.project.id}
                >
                  <IonIcon slot="icon-only" icon={arrowForwardOutline} />
                </IonButton>
              </>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
