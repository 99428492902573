import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useEventsOncall } from "../../hooks";

interface OnCallCardProps {}

export const OnCallCard: React.FC<OnCallCardProps> = (props) => {
  // Hook in
  const { onCall, nextOnCall } = useEventsOncall();

  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        <IonCardSubtitle>ONCALL</IonCardSubtitle>
        <IonCardTitle>
          {onCall &&
            new Date(onCall.start + "T08:00").toDateString() +
              " to " +
              new Date(onCall.end + "T08:00").toDateString()}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel position="stacked">Note</IonLabel>
          <IonTextarea readonly rows={4} value={onCall?.note} />
        </IonItem>
        {nextOnCall && (
          <IonItem>
            <IonLabel>Next:</IonLabel>
            <IonText slot="end"></IonText>
            {new Date(nextOnCall.start + "T08:00").toDateString() +
              " to " +
              new Date(nextOnCall.end + "T08:00").toDateString()}
          </IonItem>
        )}
      </IonCardContent>
    </IonCard>
  );
};
