import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonList,
  IonListHeader,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { Header, UserItem, UserModal, UserPasswordChangeModal } from "../components";
import { useEmployees, useUser } from "../hooks";
import { SecurityGroups } from "../models";
import { useState } from "react";

export const Users: React.FC = (props) => {
  // On Call card
  const {
    queryEmployees,
    loadAll,
    queriedEmployees,
    setKeyword,
    activeEmployeeId,
    setActiveEmployeeId,
    activeEmployeeIdForPasswordChange,
    setActiveEmployeeIdForPasswordChange,
    deleteAuthUser,
    changeAuthUserPassword
  } = useEmployees();

  const { security } = useUser();
  
  const [usernameForPasswordChange, setUsernameForPasswordChange] = useState('');

  const [seed, setSeed] = useState(1);
  const reset = () => {
    loadAll().then(() => setSeed(Math.random()));
  };

  return (
    <IonPage key={seed}>
      {/* Page title */}
      <Header title={"Employees"} />

      {/* Page Content */}
      <IonContent>
        {/* Employee */}
        <IonCard>
          <IonToolbar>
            <IonTitle>MACTECH</IonTitle>
            <IonButtons slot="end">
              {security.isAdmin && 
                <IonButton routerLink="/mactech/account/create" color="primary">
                  <IonIcon icon={addOutline} />
                  CREATE USER
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
          <IonCardContent>
            <IonSearchbar
              onIonChange={(e) => setKeyword(e.detail.value as string)}
            />
            {/* Fiels Employees */}
            <IonList>
              <IonListHeader>Field</IonListHeader>
              {queriedEmployees
                ?.filter((employee) => {
                  return employee.security === SecurityGroups.FIELD
                    ? true
                    : false;
                })
                .map((employee) => {
                  return (
                    <UserItem
                      key={employee.id}
                      employee={employee}
                      onView={() => setActiveEmployeeId(employee.id)}
                      onDelete={() => deleteAuthUser(employee.id).then(() => reset()) }
                      onChangePasswordClick={() => { console.log(employee.id); setActiveEmployeeIdForPasswordChange(employee.id); setUsernameForPasswordChange(employee.username !== null && employee.username !== undefined ? employee.username : '') }}
                    />
                  );
                })}
            </IonList>

            {/* Office Employees */}
            <IonList>
              <IonListHeader>Office</IonListHeader>
              {queriedEmployees
                ?.filter((employee) => {
                  return employee.security === SecurityGroups.OFFICE ||
                    employee.security === SecurityGroups.ADMIN
                    ? true
                    : false;
                })
                .map((employee) => {
                  return (
                    <UserItem
                      key={employee.id}
                      employee={employee}
                      onView={() => setActiveEmployeeId(employee.id)}
                      onDelete={() => deleteAuthUser(employee.id).then(() => setSeed(Math.random())) }
                      onChangePasswordClick={() => { console.log(employee.id); setActiveEmployeeIdForPasswordChange(employee.id); setUsernameForPasswordChange(employee.username !== null && employee.username !== undefined ? employee.username : '') }}
                    />
                  );
                })}
            </IonList>
          </IonCardContent>
        </IonCard>
        <UserModal
          id={activeEmployeeId}
          onDismiss={() => setActiveEmployeeId(undefined)}
          hideSave={!security.isAdmin}
        />
        <UserPasswordChangeModal
          id={activeEmployeeIdForPasswordChange}
          name={usernameForPasswordChange}
          onDismiss={() => setActiveEmployeeIdForPasswordChange(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};
