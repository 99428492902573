import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Document } from "../models";
import { formatAWSDate } from "../_utils/datetime";

// Form edit for doc
interface DocModalForm {
  description: null | string | undefined;
  approved: boolean | undefined;
  key: null | string | undefined;
  projectId: null | string | undefined;
  name: null | string | undefined;
  date: Date | undefined;
  rank?: number | null | undefined;
}

// The useDocument hook is to handle any updates and changes to the document save in database
export function useDocument() {
  // Stae
  const [activeDocId, setActiveDocId] = useState<string>();
  const [activeDocForm, setActiveDocForm] = useState<DocModalForm>();

  // Load doc
  const loadDocument = async () => {
    try {
      let document = await DataStore.query(Document, activeDocId!);
      setActiveDocForm({
        key: document?.key,
        description: document?.description,
        approved: document?.approved !== null ? document?.approved : undefined,
        projectId: document?.documentProjectId,
        name: document?.name,
        date: document?.date ? new Date(document?.date) : new Date(),
        rank: document?.rank
      });
    } catch (error) {
      console.log("Error loading document: ", error);
    }
  };

  // Save doc
  const saveDocument = async () => {
    try {
      // make a copy
      let original = await DataStore.query(Document, activeDocId!);

      await DataStore.save(
        Document.copyOf(original!, (updated) => {
          updated.description = activeDocForm?.description;
          updated.approved = activeDocForm?.approved;
          updated.key = activeDocForm?.key;
          updated.project = undefined;
          updated.documentProjectId = activeDocForm?.projectId;
          updated.name = activeDocForm?.name;
          updated.date = formatAWSDate(activeDocForm?.date!);
          updated.rank = activeDocForm?.approved !== original?.approved ? -1 : original?.rank
        })
      );
    } catch (error) {
      console.log("Error saving document: ", error);
    }
  };

  // Trigger
  useEffect(() => {
    if (activeDocId) loadDocument();
  }, [activeDocId]);

  // Return
  return {
    setActiveDocId,
    activeDocForm,
    setActiveDocForm,
    saveDocument,
  };
}
