import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonLabel,
  IonList,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { DateInput, OnCallItem, OnCallModal } from "..";
import { useOnCall, useOncalls, useTimeOffs } from "../../hooks";
import { arrowUp, arrowDown, addCircleOutline } from "ionicons/icons";
import { formatAWSDate } from "../../_utils/datetime";
import { TimeOffModal } from "../Modals/TimeOffModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
//import { Picker } from 'react-month-picker';

interface TimeOffCalendarProps {}

export const TimeOffCalendar: React.FC<TimeOffCalendarProps> = () => {
  // Show all onCallList

  const events = [
    { title: 'event 1', date: '2024-07-25', start: '2024-07-25 12:00', end: '2024-07-25 14:00', backgroundColor: '#065BAA' },
    { title: 'event 1', date: '2024-07-26', start: '2024-07-25 12:00', backgroundColor: '#065BAA' },
  ];

  const [ displayedEvents, setDisplayedEvents ] = useState<any[]>(events);
  const [ filterWhat, setFilterWhat ] = useState<string>("all");

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);

  const [hideTimeOffList, setHideTimeOffList] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<Date>(sstartdate);
  const [filterEndDate, setFilterEndDate] = useState<Date>(senddate);

  // Connect to hool
  const {
    createTimeOffEvent,
    queriedTimeOff,
    setActiveTimeOffId,
    activeTimeOffId,
  } = useTimeOffs();

  const {
    createOnCallEvent,
    queriedOnCall,
    setActiveOnCallId,
    activeOnCallId,
  } = useOncalls();

  //const pickAMonth = React.createRef();

  useEffect(() => {
    let timeoffevents = queriedTimeOff?.filter((o) => {
        return (o.title !== null && o.title !== undefined);
      })
      .map((o) => {
      return {
        title: o.title,
        start: Date.parse(o.start),
        end: Date.parse(o.end)
      };
    });
    console.log(timeoffevents);
    let oncallevents = queriedOnCall?.filter((o) => {
        return (o.title !== null && o.title !== undefined);
      }).map((o) => {
      return {
        title: o.title,
        start: Date.parse(o.start),
        end: Date.parse(o.end)
      };
    });
    let displayedevents: any[] = [];
    switch (filterWhat) {
      case "oncall":
        {
          displayedevents = ([...(oncallevents ? oncallevents : [])]);
        }
        break;
      case "timeoff":
        {
          displayedevents = ([...(timeoffevents ? timeoffevents : [])]);
        }
        break;
      case "all":
        {
          displayedevents = ([...(timeoffevents ? timeoffevents : []), ...(oncallevents ? oncallevents : [])]);
        }
        break;
    }
    
    setDisplayedEvents(displayedevents!);
  }, [queriedTimeOff, queriedOnCall, filterWhat]);

  return (
    <>
      <IonRow>
        <IonCol size="5">
          <IonLabel>
            Display:
          </IonLabel>
          <IonSelect value={filterWhat} onIonChange={(e) => setFilterWhat(e.detail.value)}>
            <IonSelectOption value="all">-- All --</IonSelectOption>
            <IonSelectOption value="oncall">Only OnCalls</IonSelectOption>
            <IonSelectOption value="timeoff">Only TimeOffs</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      {/* Oncall list */}
      <FullCalendar
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            events={displayedEvents}
          />

      {/* On Call Modal */}
      <TimeOffModal
        timeOffId={activeTimeOffId}
        onDismiss={() => setActiveTimeOffId(undefined)}
      />
    </>
  );
};
