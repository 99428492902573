import { IonLabel } from "@ionic/react";
import { Project } from "../../models";

interface ProjectLabelProps {
  project: Project | undefined;
}
export const ProjectLabel: React.FC<ProjectLabelProps> = (props) => {
  return (
    <IonLabel>
      {"" +
        props.project?.code?.toUpperCase() +
        props.project?.number?.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
    </IonLabel>
  );
};
