import { IonButton, IonIcon } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";

// Ioninc button with creat icion and functin to call when used
interface CreateButtonProps {
  onCreate: () => void;
}
export const CreateButton: React.FC<CreateButtonProps> = (props) => {
  return (
    <IonButton onClick={() => props.onCreate()} fill="outline">
      <IonIcon icon={addCircleOutline} />
      Add
    </IonButton>
  );
};
