import { IonList, IonItem, IonIcon, IonButton, IonCol } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { JobInput, NumberInput } from "..";

interface ProjectAllocationInputProps {
  canEdit: boolean;
  projectIds: (string | null)[] | null | undefined;
  projectAllocations: (number | null)[] | null | undefined;
  onAddAllocation: () => void;
  onSelectProject: (index: number, id: string) => void;
  updateAllocation: (index: number, amount: number) => void;
  onRemove: (index: number) => void;
}

export const ProjectAllocationInput: React.FC<ProjectAllocationInputProps> = (
  props
) => {
  return (
    // Show new
    <>
      {/* Project inputs */}
      <IonCol>
        <JobInput
          id={props.projectIds![0]!}
          onSelect={(id) => {
            props.onSelectProject(0, id);
          }}
        />
        {/* If there are alocatino, display them */}
        {props.projectIds?.map((projectId, index) => {
          if (index > 0) {
            return (
              <JobInput
                key={"inputProject" + projectId}
                hideLable={true}
                id={props.projectIds![index]!}
                onSelect={(id) => {
                  console.log(index, projectId, id);
                  props.onSelectProject(index, id);
                }}
              />
            );
          }
        })}
      </IonCol>

      {/* Ammunt inputs */}
      <IonCol>
        <NumberInput
          label={"Allocation ($)"}
          value={props.projectAllocations![0]}
          onChange={(value) => {
            props.updateAllocation(0, value);
          }}
        />
        {/* If there are alocatino, display them */}
        {props.projectIds?.map((projectId, index) => {
          if (index > 0) {
            return (
              <NumberInput
                key={"input number" + projectId}
                label={undefined}
                value={props.projectAllocations![index]}
                onChange={(value) => {
                  props.updateAllocation(index, value);
                }}
              />
            );
          }
        })}
      </IonCol>

      {/* Add remove buttons */}
      <IonCol>
        <IonList>
          <IonItem>
            <IonButton onClick={() => props.onAddAllocation()}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          </IonItem>
        </IonList>
      </IonCol>
    </>
  );
};
