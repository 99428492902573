import { IonButton, IonIcon } from "@ionic/react";
import { pencilSharp } from "ionicons/icons";

// Edit button with finctin to call when clicks
interface EditButtonProps {
  onEdit: () => void;
}

export const EditButton: React.FC<EditButtonProps> = (props) => {
  return (
    <IonButton onClick={() => props.onEdit()}>
      <IonIcon slot="icon-only" icon={pencilSharp} />
    </IonButton>
  );
};
