import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Project, ProjectStatus } from "../models";

export function useProjectSearch() {
  // Keyword
  const [keyword, setKeyword] = useState<string>();
  const [selectedProject, setSelectedProject] = useState<string>();

  // Searched
  const [projects, setProjects] = useState<Project[]>();
  const [unarchivedProjects, setUnarchivedProjects] = useState<Project[]>();

  // Load last editited jobs
  const preloadProjects = async () => {
    try {
      await DataStore.query(Project, Predicates.ALL, {
        sort: (s) => s.createdAt(SortDirection.DESCENDING),
        limit: 5,
      }).then((projects) => {
        setProjects(projects);
      });
    } catch (error) {
      console.log("Error loading projects");
    }
  };

  // Query database on keyword change
  const queryProjects = async () => {
    try {
      // Match the string to getneratop alpha numbercial string
      // --- match wit regex (/[a-z]+|\d+/gi) will give an errray wthi fist letters and next numbers
      let str = keyword?.match(/[a-z]+|\d+/gi);

      // POssibly undefined but unlikelyle
      if (str && str.length === 1) {
        // Look up the code
        await DataStore.query(
          Project,
          (c) => c.deleted("ne", true).code("contains", str![0]),
          { limit: 5 }
        ).then((projects) => {
          setProjects(projects);
        });
        await DataStore.query(
          Project,
          (c) => c.deleted("ne", true).status("ne", ProjectStatus.ARCHIVED).code("contains", str![0]),
          { limit: 5 }
        ).then((projects) => {
          setUnarchivedProjects(projects);
        });
      } else if (str && str.length === 2) {
        // Look up the code and the numbers
        let projects = await DataStore.query(
          Project,
          (c) =>
            c
              .deleted("ne", true)
              .code("contains", str![0])
              .number("ge", Number(str![1])),
          { limit: 5 }
        );
        let unarchivedProjects = await DataStore.query(
          Project,
          (c) =>
            c
              .deleted("ne", true)
              .status("ne", ProjectStatus.ARCHIVED)
              .code("contains", str![0])
              .number("ge", Number(str![1])),
          { limit: 5 }
        );
        setProjects(projects);
        setUnarchivedProjects(unarchivedProjects);
      }
    } catch (error) {
      console.log("Error query projects");
    }
  };

  // Trigger keyword change
  useEffect(() => {
    keyword ? queryProjects() : preloadProjects();
  }, [keyword]);

  // load last jobs
  return { projects, unarchivedProjects, keyword, setKeyword, selectedProject, setSelectedProject };
}
