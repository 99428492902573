import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  Header,
  TimesheetCard,
  TimesheetModal,
  TimesheetItem,
  LayoutButton,
  SearchCard,
  DateInput,
} from "../components";
import { useTheme, useTimesheets } from "../hooks";
import { DisplayLayout } from "../context";
import { TimesheetStatus } from "../models";
import { useState } from "react";
import { formatAWSDate } from "../_utils/datetime";

export const Hours: React.FC = () => {
  // Layout style
  const { layout } = useTheme();

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);
  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);

  // Get user timesheets
  const {
    setReloadState,
    queriedTimesheets,
    activeTimesheetId,
    setActiveTimesheetID,
    setKeyword,
    keyword,
  } = useTimesheets();

  return (
    <IonPage>
      {/* Header */}
      <Header title="Hours" />

      {/* Page content */}
      <IonContent fullscreen>
        {/* Search timesheets */}
        <SearchCard onKeywordChange={setKeyword} />

        {/* Grid layout*/}
        <IonGrid>
          {/* Display */}
          <IonRow>
            <IonCol>
              <IonButtons style={{ justifyContent: "flex-end" }}>
                <LayoutButton />
              </IonButtons>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                tid="aaaa"
                label="From"
                value={filterStartDate}
                disabled={false}
                onChange={(value) => {
                    setFilterStartDate(value);
                  }
                }
                
              />
            </IonCol>
            <IonCol size="5">
              {/* Date */}
              <DateInput
                  tid="bhbbb"
                  label="To"
                  value={filterEndDate}
                  disabled={false}
                  onChange={(value) => {
                      setFilterEndDate(value);
                    }
                  }
                />
            </IonCol>
          </IonRow>

          {/* List layout */}
          {layout === DisplayLayout.TABLE && (
            <IonRow>
              <IonCol>
                {false &&
                  <IonList lines="inset">
                    {/* List */}
                    <IonListHeader>
                      <IonText color="danger">Rejected</IonText>
                    </IonListHeader>
                    {queriedTimesheets
                      ?.filter((timesheet) => {
                        return (timesheet.status === TimesheetStatus.REJECTED ? true : false) && ((!filterStartDate || timesheet.date! >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date! <= formatAWSDate(filterEndDate)));
                      })
                      .map((timesheet) => {
                        return (
                          <TimesheetItem
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                          />
                        );
                      })}
                  </IonList>
                }
                
                <IonList lines="inset">
                  {/* List */}
                  <IonListHeader>
                    <IonText color="medium">Unapproved</IonText>
                  </IonListHeader>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return (timesheet.status === TimesheetStatus.UNAPPROVED ? true : false) && ((!filterStartDate || timesheet.date! >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date! <= formatAWSDate(filterEndDate)));
                    })
                    .map((timesheet) => {
                      return (
                        <TimesheetItem
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                        />
                      );
                    })}
                </IonList>
                <IonList lines="inset">
                  {/* List */}
                  <IonListHeader>
                    <IonText color="medium">Approved for billing</IonText>
                  </IonListHeader>
                  {queriedTimesheets
                    ?.filter((timesheet) => {
                      return (timesheet.status === TimesheetStatus.APPROVED ? true : false) && ((!filterStartDate || timesheet.date! >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date! <= formatAWSDate(filterEndDate)));
                    })
                    .map((timesheet) => {
                      return (
                        <TimesheetItem
                          key={timesheet.id}
                          timesheet={timesheet}
                          onEdit={() => setActiveTimesheetID(timesheet.id)}
                        />
                      );
                    })}
                </IonList>
              </IonCol>
            </IonRow>
          )}

          {/* Cards */}
          {layout === DisplayLayout.GRID && (
            <IonRow>
              {queriedTimesheets
                ?.filter((timesheet) => {
                  return (!keyword ? timesheet.status === TimesheetStatus.UNAPPROVED : true) && ((!filterStartDate || timesheet.date! >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date! <= formatAWSDate(filterEndDate)));
                })
                .map((timesheet, index) => {
                  return (
                    <IonCol key={index} size="12" sizeMd="4">
                      <TimesheetCard
                        key={timesheet.id}
                        timesheet={timesheet}
                        onEdit={() => setActiveTimesheetID(timesheet.id)}
                      />
                    </IonCol>
                  );
                })}
            </IonRow>
          )}
        </IonGrid>

        {/* Data control */}
        <TimesheetModal
          timesheetId={activeTimesheetId}
          onEditSave={() => {
            console.log(123123);
            setReloadState(true);
          }}
          onDismiss={() => setActiveTimesheetID(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};
