import {
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonModal,
  IonContent,
  IonList,
} from "@ionic/react";
import { bookmark } from "ionicons/icons";
import { useState } from "react";

const ColorOptions = [
  { label: "Complete", color: "#bad963" },
  { label: "Final", color: "#ae0f0f" },
  { label: "Glen Schedule", color: "#f8db49" },
  { label: "HVAC", color: "#3cbde3" },
  { label: "Job Check", color: "#0ea03a" },
  { label: "Justin Webster Job", color: "#fdcda0" },
  { label: "Mark Schedule", color: "#0a3bae" },
  { label: "Matt Schedule", color: "#737373" },
  { label: "Nicole", color: "#d30da8" },
  { label: "PENDING", color: "#3cbde3" },
  { label: "Pick Ups", color: "#69bb7b" },
  { label: "Robert Schedule", color: "#da7210" },
  { label: "Trista", color: "#bc8df7" },
];

interface EventColorInputProps {
  value: null | string | undefined;
  onChange: (value: string) => void;
}

export const EventColorInput: React.FC<EventColorInputProps> = (props) => {
  const [isSelectColor, setIsSelectColor] = useState(false);

  const toggleModal = () => {
    setIsSelectColor(!isSelectColor);
  };

  return (
    <IonItem button onClick={() => toggleModal()}>
      <IonLabel>Color</IonLabel>
      <IonText>
        <IonIcon style={{ color: props.value }} icon={bookmark} />
      </IonText>
      <IonModal
        isOpen={isSelectColor}
        onDidDismiss={() => setIsSelectColor(false)}
      >
        <IonContent>
          <IonList>
            {ColorOptions.map((colorOption, index) => {
              return (
                <IonItem
                  key={index}
                  button
                  onClick={() => {
                    setIsSelectColor(false);
                    props.onChange(colorOption.color);
                  }}
                >
                  <IonLabel>{colorOption.label}</IonLabel>
                  <IonText>
                    <IonIcon
                      style={{ color: colorOption.color }}
                      icon={bookmark}
                    />
                  </IonText>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonModal>
    </IonItem>
  );
};
