// Formate date object into
export const formatIonDateTimeString = (date: Date) => {
  // Variable
  let dateTime = "";

  // Add year
  dateTime += date.getFullYear();

  // Add month - getMonth is 0 based
  dateTime += "-";
  dateTime +=
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

  // Add Date
  dateTime += "-";
  dateTime += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  // Add hours
  dateTime += "T";
  dateTime += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();

  // Add minutes
  dateTime += ":";
  dateTime +=
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return dateTime;
};

// AWS datesring
export const formatAWSDate = (date: Date) => {
  // Get variable
  let AWSdate = "";

  // Add year
  AWSdate += date.getFullYear();

  // Add month - getMonth is 0 based
  AWSdate += "-";
  AWSdate +=
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

  // Add Date
  AWSdate += "-";
  AWSdate += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  return AWSdate;
};

export const formatAWSTime = (date: Date) => {
  // Add timezone
  date.setHours(date.getHours() + 6);
  // Get variable
  let AWStime = "";
  // Add hours
  AWStime += date.getHours() < 10 ? "0" + date.getHours() : date.getHours();

  // Add minutes
  AWStime += ":";
  AWStime +=
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return AWStime;
};

export const formatAWSDateTime = (date: Date) => {
  return formatAWSDate(date) + "T" + formatAWSTime(date) + ":00.000Z";
};

// Export time
export const formatTimeString = (date: Date) => {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

// Export time
export const formatTimeH12String = (date: Date) => {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

// Set hour and minut
export const updateTime = (date: Date, newTime: Date) => {
  // Update hours
  date.setHours(newTime.getHours());
  // Update minutes
  date.setMinutes(newTime.getMinutes());

  return date;
};
