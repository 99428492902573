import {
  IonList,
  IonListHeader,
  IonText,
  IonItem,
  IonIcon,
  IonPopover,
  IonLabel,
  IonButton,
} from "@ionic/react";
import { personAddOutline, closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { CloseButton, EmailButton, PhoneButton } from "..";
import { useEmployees, useUser } from "../../hooks";

interface EmployeeInputProps {
  ids: (string | null)[];
  canEdit: boolean;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

export const EmployeeInput: React.FC<EmployeeInputProps> = (props) => {
  // Hooks
  const { security } = useUser();
  const { fieldEmployees } = useEmployees();

  // State
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Update securioy
  useEffect(() => {
    security.isAdmin || security.isOffice
      ? setDisabled(false)
      : setDisabled(true);
  }, [security]);

  return (
    <IonList>
      <IonListHeader>
        {/*  Title */}
        <IonText color="medium">Employees</IonText>

        {/* Add employees to event */}
        <IonButton onClick={() => setIsOpen(true)} disabled={disabled}>
          <IonIcon icon={personAddOutline} color="primary" />
        </IonButton>
        <IonPopover
          isOpen={isOpen}
          showBackdrop={false}
          onDidDismiss={() => setIsOpen(false)}
        >
          <IonList lines="none">
            {fieldEmployees
              ?.filter((employee) => !props.ids.includes(employee.id))
              .map((employee, index) => {
                return (
                  <IonItem
                    key={index}
                    button
                    onClick={() => {
                      props.onAdd(employee.id);
                      setIsOpen(false);
                    }}
                  >
                    <IonLabel>{employee.username}</IonLabel>
                  </IonItem>
                );
              })}
          </IonList>
        </IonPopover>
      </IonListHeader>

      {/* Display linked employees */}
      {props.ids.map((employeeId, index) => {
        let employee = fieldEmployees?.find(
          (employee) => employee.id === employeeId
        );
        return (
          <IonItem key={index}>
            <IonLabel>{employee?.username}</IonLabel>

            <IonText slot="end">
              <PhoneButton tel={employee?.cell} />
              <EmailButton email={employee?.email} />
              <CloseButton onClick={() => props.onRemove(employee!.id)} />
            </IonText>
          </IonItem>
        );
      })}
    </IonList>
  );
};
