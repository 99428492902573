import { useState } from "react";
import {
  IonList,
  IonListHeader,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonImg,
  IonActionSheet,
} from "@ionic/react";
import { cameraOutline, trash, close } from "ionicons/icons";
import { usePhotoGallery } from "../../hooks";

interface ImageInputProps {
  images: string[] | undefined;
  addImage: (image: string) => void;
  deleteImage: (image: string) => void;
}

export const ImageInput: React.FC<ImageInputProps> = (props) => {
  // Delete image
  const [imageToDelete, setImageToDelete] = useState<string>();

  // Photo
  const { newImage } = usePhotoGallery();

  // Take image
  const handlePhoto = async () => {
    let imageFile = await newImage();
    if (imageFile) props.addImage(imageFile);
  };

  return (
    <IonList>
      <IonListHeader>
        <IonText color="medium">Images</IonText>

        <IonButton onClick={() => handlePhoto()}>
          +<IonIcon icon={cameraOutline}></IonIcon>
        </IonButton>
      </IonListHeader>
      {/* Show images */}
      {props.images?.map((image, index) => {
        return (
          <IonItem key={index}>
            <IonImg onClick={() => setImageToDelete(image)} src={image} />
          </IonItem>
        );
      })}

      {/* Remove photo */}
      <IonActionSheet
        isOpen={!!imageToDelete}
        buttons={[
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            handler: () => {
              if (imageToDelete) {
                props.deleteImage(imageToDelete);
                setImageToDelete(undefined);
              }
            },
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
          },
        ]}
        onDidDismiss={() => {
          setImageToDelete(undefined);
        }}
      />
    </IonList>
  );
};
