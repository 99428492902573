import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { Contact } from "../../models";
import { EditButton } from "../Buttons";

interface ContactItemProps {
  contact: Contact;
  onView: () => void;
}

export const ContactItem: React.FC<ContactItemProps> = (props) => {
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel>{props.contact.name}</IonLabel>
            <IonText color="medium">{props.contact.type}</IonText>
          </IonCol>
          <IonCol>
            <IonLabel color="medium">Phone</IonLabel>
            <IonText>
              <a href={"tel:" + props.contact.phone}>{props.contact.phone}</a>
            </IonText>
          </IonCol>
          <IonCol>
            <IonLabel color="medium">Email</IonLabel>
            <IonText>
              <a href={"mailto:" + props.contact.email}>
                {props.contact.email}
              </a>
            </IonText>
          </IonCol>
          <IonCol className="ion-text-right">
            <EditButton onEdit={() => props.onView()} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
