import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonReorderGroup,
  IonRow,
  IonTitle,
  ItemReorderEventDetail,
} from "@ionic/react";
import { useEffect } from "react";
import { useNotes } from "../../hooks";
import { Project, Note } from "../../models";
import { CreateButton, Header, NoteItem, NoteModal } from "..";

interface ProjectNotesTabInterface {
  project: Project | undefined;
}

export const ProjectNotesTab: React.FC<ProjectNotesTabInterface> = (props) => {
  // Conect to hook
  const {
    setActiveProjectId,
    activeProjectNotes,
    setReloadState,
    createNote,
    projectNoteId,
    setProjectNoteId,
    updateOrder,
    deleteNoteById
  } = useNotes();

  // Do reorder
  const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    updateOrder(event.detail.from, event.detail.to);
    event.detail.complete();
  };

  // Load stuff
  useEffect(() => {
    // Set project id
    setActiveProjectId(props.project?.id);
  }, [props.project?.id]);

  return (
    <IonContent>
      {/* Page header */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Notes"
        }
      />

      <IonGrid>
        <IonRow>
          <IonCol size="1" push="11">
            <IonButtons>
              <CreateButton onCreate={() => createNote()} />
            </IonButtons>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
              {activeProjectNotes?.map((note) => {
                if (note)
                  return (
                    <NoteItem
                      key={note.id}
                      note={note}
                      onView={() => setProjectNoteId(note!.id)}
                    />
                  );
              })}
            </IonReorderGroup>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* Note modal */}
      <NoteModal
        noteId={projectNoteId}
        onDelete={() => {
          if (projectNoteId) {
            console.log(123);
            deleteNoteById(projectNoteId);
          }
          setProjectNoteId(undefined);
          setReloadState(true);
        }}
        onDismiss={() => {
          setProjectNoteId(undefined);
          setReloadState(true);
        }}
      />
    </IonContent>
  );
};
