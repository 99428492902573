import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonList,
  IonListHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useInvoice } from "../../hooks";
import { Accounts, Expenses, Project } from "../../models";
import { CloseButton } from "../Buttons";
import {
  DateInput,
  DocumentInput,
  NumberInput,
  ProjectInput,
  ProjectSplitInput,
  SelectInput,
  StringInput,
  TextAreaInput,
} from "../Inputs";
import { bool } from "aws-sdk/clients/signer";

interface InvoiceModalProps {
  invoiceId: null | string | undefined;
  projectId?: null | string | undefined;
  onDismiss: () => void;
  typeFilter?: null | string | undefined;
  project?: Project;
}

export const InvoiceModal: React.FC<InvoiceModalProps> = (props) => {
  // open state
  const [isOpen, setIsOpen] = useState(false);

  const [ allocationProblem, setAllocationProblem ] = useState<boolean>(false);

  // Data
  const {
    projectID,
    setProjectID,
    projectPartition,
    setProjectPartition,
    partitionInvoice,
    removePartition,
    setActiveInvoiceId,
    activeInvoice,
    setActiveInvoice,
    saveInvoice,
  } = useInvoice();

  useEffect(() => {
    if (activeInvoice) {
      let hasProblem = false;
      let projectIds = activeInvoice.projectIds;
      let partition = activeInvoice.partition;
      if (projectIds!.length !== partition!.length) {
        hasProblem = true;
      } else if (projectIds?.length === 0) {
        hasProblem = false;
      } else if (projectIds?.length === 1 && projectIds[0]?.trim() === '') {
        if (partition![0] !== 0) {
          hasProblem = true;
        } else {
          hasProblem = false;
        }
      } else {
        let sum = 0.0;
        for (let i = 0; i < partition!.length; i++) {
          if (partition !== null && partition !== undefined) {
            sum += (partition as number[])[i];
          }
        }
        if (sum != activeInvoice.amount) {
          hasProblem = true;
        }
      }
      setAllocationProblem(hasProblem);
    }
  }, [activeInvoice]);

  // Save date
  const handleSubmit = async () => {
    await saveInvoice();
    props.onDismiss();
  };

  // Trigger
  useEffect(() => {
    // // Reset state
    setProjectID(undefined);
    setProjectPartition(0);

    // Set ID
    setActiveInvoiceId(props.invoiceId !== null ? props.invoiceId : undefined);
    // Show if needed
    props.invoiceId ? setIsOpen(true) : setIsOpen(false);
  }, [props.invoiceId]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        {/* Title */}
        {activeInvoice?.number && <IonTitle>{activeInvoice?.number}</IonTitle>}
        <IonButtons slot="end">
          {/* Close button */}
          <CloseButton onClick={() => props.onDismiss()} />
        </IonButtons>
      </IonToolbar>
      {activeInvoice && (
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                {/* Document */}
                <DocumentInput
                  label="Invoice"
                  value={activeInvoice.document !== null ? activeInvoice.document : undefined}
                  onUpload={(key) =>
                    setActiveInvoice({ ...activeInvoice, document: key })
                  }
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* Date */}
                <DateInput
                  value={activeInvoice.date}
                  disabled={false}
                  onChange={(value) =>
                    setActiveInvoice({ ...activeInvoice, date: value })
                  }
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* Invoice ref */}
                <StringInput
                  label="Invoice #"
                  value={activeInvoice.number}
                  canEdit={true}
                  onChange={(value) =>
                    setActiveInvoice({ ...activeInvoice, number: value })
                  }
                />
              </IonCol>
              <IonCol>
                {/* Amount */}
                <NumberInput
                  label="Amount ($)"
                  value={activeInvoice?.amount}
                  onChange={(value) => {
                  if (props.project && activeInvoice.partition?.length === 1 && props.project!.id === activeInvoice.projectIds![0]) {
                      setActiveInvoice({ ...activeInvoice, amount: value, partition: [value] });
                    }
                    //  else {
                    //   setActiveInvoice({ ...activeInvoice, amount: value });
                    // }
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* Account */}
                <SelectInput
                  label="Account"
                  value={props.typeFilter === 'revenue' ? Accounts.INCOME : (props.typeFilter === 'expenses' ? Accounts.EXPENSES : activeInvoice.account)}
                  canEdit={true}
                  onChange={(value) =>
                    setActiveInvoice({
                      ...activeInvoice,
                      account: value as Accounts,
                    })
                  }
                  options={props.typeFilter === 'revenue' ? [Accounts.INCOME] : (props.typeFilter === 'expenses' ? [Accounts.EXPENSES] : Object.keys(Accounts))}
                />
              </IonCol>
              {/* Categery */}
              {activeInvoice.account === Accounts.EXPENSES && (
                <IonCol>
                  <SelectInput
                    label="Category"
                    value={activeInvoice.category}
                    canEdit={true}
                    onChange={(value) =>
                      setActiveInvoice({
                        ...activeInvoice,
                        category: value as Expenses,
                      })
                    }
                    options={Object.keys(Expenses)}
                  />
                </IonCol>
              )}
            </IonRow>
            {/* Invoice ref */}
            {activeInvoice.account === Accounts.EXPENSES &&
              activeInvoice.category != Expenses.MISCELLANEOUS && (
                <IonRow>
                  <IonCol>
                    <StringInput
                      label="Business"
                      value={activeInvoice.business}
                      canEdit={true}
                      onChange={(value) =>
                        setActiveInvoice({
                          ...activeInvoice,
                          business: value.toUpperCase(),
                        })
                      }
                    />
                  </IonCol>
                </IonRow>
              )}
            <IonRow>
              <IonCol>
                {/* Description */}
                <TextAreaInput
                  lable="Description"
                  value={activeInvoice.description}
                  readonly={false}
                  onChange={(value) =>
                    setActiveInvoice({ ...activeInvoice, description: value })
                  }
                />
              </IonCol>
            </IonRow>

            {/* Job linking */}
            {(!props.project || (activeInvoice.partition?.length !== 1 && props.project.id !== activeInvoice.projectIds![0] && activeInvoice.partition![0] !== activeInvoice.amount)) && 
              <IonRow>
                <IonCol>
                  <ProjectSplitInput
                    key="new-project"
                    id={projectID}
                    partition={projectPartition}
                    canEdit={true}
                    onSelect={(id) => setProjectID(id)}
                    percentage={(value) => setProjectPartition(value)}
                    onAdd={() => partitionInvoice()}
                    projectIds={activeInvoice.projectIds}
                    projectAllocations={activeInvoice.partition}
                    onRemove={(index) => removePartition(index)}
                    updateAllocation={(index, amount) => {
                      let iindex = index;
                      if (typeof index === 'string') {
                        iindex = parseInt(index);
                      }
                      console.log({
                        'activeInvoice.partition': activeInvoice.partition!,
                        'index': index,
                        'iindex': iindex
                      });
                      let newpartition = [...activeInvoice.partition!];
                      newpartition[iindex] = amount;
                      setActiveInvoice({
                        ...activeInvoice,
                        partition: [...newpartition!],
                      });
                    }}
                  />
                </IonCol>
              </IonRow>
            }
          </IonGrid>

          {/* Save */}
          <IonButton
            expand="full"
            disabled={!activeInvoice.document || allocationProblem}
            onClick={() => handleSubmit()}
          >
            Save
          </IonButton>
        </IonContent>
      )}
    </IonModal>
  );
};
