import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonText,
} from "@ionic/react";
import { addCircle } from "ionicons/icons";
import { useState } from "react";
import { useBuilders } from "../../hooks";

interface BuilderInputProps {
  id: null | string | undefined;
  onChange: (id: string) => void;
  error?: { error: boolean | undefined; msg: string };
}

export const BuilderInput: React.FC<BuilderInputProps> = (props) => {
  const { builders, quedBuilders, setKeyword } = useBuilders();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <IonList>
      <IonListHeader>
        <IonText color="medium">Builder</IonText>
        <IonButton onClick={() => setIsOpen(true)}>
          <IonIcon color="primary" icon={addCircle} />
        </IonButton>
        <IonPopover
          isOpen={isOpen}
          onDidDismiss={() => setIsOpen(false)}
          showBackdrop={false}
        >
          <IonSearchbar
            onIonChange={(e) => setKeyword(e.detail.value as string)}
          />
          <IonRadioGroup
            value={props.id}
            onIonChange={(e) => {
              if (props.id !== e.detail.value) {
                props.onChange(e.detail.value as string);
              }
              setIsOpen(false);
            }}
          >
            {quedBuilders?.map((builder) => {
              return (
                <IonItem key={builder.id}>
                  <IonLabel> {builder.name}</IonLabel>
                  <IonRadio slot="start" value={builder.id} />
                </IonItem>
              );
            })}
            {/* Remove */}
            {/* <IonItem>
              <IonLabel> None</IonLabel>
              <IonRadio slot="start" value={undefined} />
            </IonItem> */}
          </IonRadioGroup>
        </IonPopover>
      </IonListHeader>
      {props.id &&
        builders?.map((builder) => {
          if (builder.id === props.id)
            return (
              <IonItem key={builder.id}>
                <IonLabel>{builder.name}</IonLabel>
              </IonItem>
            );
        })}
      {props.error?.error && (
        <IonItem>
          <IonText color="danger">{props.error.msg}</IonText>
        </IonItem>
      )}
    </IonList>
  );
};
