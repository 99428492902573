import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

export function useStorage() {
  // State
  const [documentKey, setDocumentKey] = useState<string>();
  const [documentLink, setDocumentLink] = useState<string>();

  const loadDocument = async () => {
    try {
      let data = await Storage.get(documentKey!);
      setDocumentLink(data);
    } catch (error) {
      console.log("Error loading document: ", error);
    }
  };

  const uploadDocument = async (file: File) => {
    try {
      const data = await Storage.put(file.name, file, {
        contentType: file.type,
      });
      setDocumentKey(data.key);
      return data.key;
    } catch (error) {
      console.log("Error loadinng focument: ", error);
    }
  };

  // Trigger
  useEffect(() => {
    if (documentKey) {
      loadDocument();
    }
  }, [documentKey]);
  return { uploadDocument, documentKey, setDocumentKey, documentLink };
}
