import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTimeOff, useUser } from "../../hooks";
import { TimeOffStatus } from "../../models";

interface TimeOffPopoverProps {
  timeoffId: null | string | undefined;
  deleteTimeoff: () => void;
  onDismiss: () => void;
}

export const TimeOffPopover: React.FC<TimeOffPopoverProps> = (props) => {
  // User conetct
  const { security } = useUser();

  // Visibility state
  const [isOpen, setIsOpen] = useState(false);

  // Get details
  const { activeTimeOff, setActiveTimeoffId } = useTimeOff();

  // Status
  let statusColor = "warning";
  if (activeTimeOff?.status === TimeOffStatus.DENIED) statusColor = "danger";
  if (activeTimeOff?.status === TimeOffStatus.APPROVED) statusColor = "medium";

  // Show if timeoff event is activated
  useEffect(() => {
    if (props.timeoffId) {
      setActiveTimeoffId(props.timeoffId);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [props.timeoffId]);

  // Handle delete
  // Handle delete
  const handleDelete = async () => {
    props.deleteTimeoff();
    props.onDismiss();
  };

  // Return stuff
  return (
    <IonPopover isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      <IonToolbar>
        <IonTitle>
          {activeTimeOff?.employee?.username?.toLocaleUpperCase()} Time Off
        </IonTitle>
        <IonButtons slot="end">
          {(security.isAdmin || security.isOffice) && (
            <IonButton onClick={() => handleDelete()}>
              <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
          )}
          <IonButton onClick={() => props.onDismiss()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        {activeTimeOff && (
          <IonList>
            {/* Start input */}
            <IonItem>
              <IonLabel>From</IonLabel>
              <IonText slot="end">
                {new Date(activeTimeOff?.start).toDateString()}
              </IonText>
            </IonItem>

            {/* To */}
            <IonItem>
              <IonLabel>To</IonLabel>
              <IonText slot="end">
                {new Date(activeTimeOff?.end).toDateString()}
              </IonText>
            </IonItem>

            {/* Details */}
            <IonItem lines="none">
              <IonLabel position="stacked">Details</IonLabel>
              <IonTextarea
                readonly
                value={activeTimeOff!.details}
              ></IonTextarea>
            </IonItem>

            {/* Status */}
            <IonItem lines="none">
              <IonText color={statusColor}>{activeTimeOff.status}</IonText>
            </IonItem>
          </IonList>
        )}
      </IonContent>
    </IonPopover>
  );
};
