import { DataStore, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Address, AuthUser, Event, Project } from "../models";
import { formatAWSDateTime } from "../_utils/datetime";

export interface EventForm {
  start: Date;
  title: string;
  color: string;
  description: string;
  material: string;
  lockbox: string;
  photos: (string | null)[];
  employeeIds: (string | null)[];
  projectId: null | string | undefined;
  coordinatorId: null | string | undefined;
  contactId: null | string | undefined;
  project: Project | undefined;
  creator: AuthUser | undefined;
}

export function useEvent() {
  // Event loaded trigger
  const [activeEventId, setActiveEventId] = useState<string>();

  // Active event
  const [activeEvent, setActiveEvent] = useState<EventForm>();

  // Images linked to project
  const [activeEventImages, setActiveEventImages] = useState<string[]>();
  const [imageToDelete, setImageToDelete] = useState<string>();

  // Load event specifs
  const loadEvent = async () => {
    try {
      let event = await DataStore.query(Event, activeEventId!);

      // Set event
      setActiveEvent({
        start: new Date(event?.start!),
        title: event?.title || "",
        color: event?.color || "",
        description: event?.description || "",
        material: event?.material || "",
        lockbox: event?.lockbox || "",
        photos: event?.photos || [],
        employeeIds: event?.employeeIds || [],
        projectId: event?.eventProjectId,
        coordinatorId: event?.eventCoordinatorId,
        project: event?.project !== null ? event?.project : undefined,
        contactId: event?.eventContactId,
        creator: event?.createdBy !== null ? event?.createdBy : undefined,
      });
    } catch (error) {
      console.log("Error loading event: ", error);
    }
  };

  // Add employees
  const addEmployees = async (id: string) => {
    if (activeEvent?.employeeIds && !activeEvent?.employeeIds.includes(id)) {
      // Create new array
      let newIds = activeEvent!.employeeIds!.concat(id);
      // Update event
      setActiveEvent({ ...activeEvent, employeeIds: newIds });
    } else {
      setActiveEvent({ ...activeEvent!, employeeIds: [id] });
    }
  };

  const removeEmployee = async (id: string) => {
    if (activeEvent?.employeeIds && activeEvent?.employeeIds.includes(id)) {
      let newIds = activeEvent!.employeeIds!.filter(
        (employeeId) => employeeId != id
      );
      setActiveEvent({ ...activeEvent, employeeIds: newIds });
    }
  };

  // Update descriptopn and address when po changes
  const setDefaultDescription = async () => {
    try {
      // Get project
      let project = await DataStore.query(Project, activeEvent?.projectId!);

      // Make description
      let description =
        "" +
        project?.code?.toUpperCase() +
        project?.number?.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      // Add address if exists
      if (project?.address?.address1)
        description += " " + project?.address?.address1;
      if (project?.address?.address2)
        description += " " + project?.address?.address2;

      // Update stuff based on it
      setActiveEvent({
        ...activeEvent!,
        description: description,
      });
    } catch (error) {
      console.log("Error loading project: ", error);
    }
  };
  // Load images
  const loadImages = async () => {
    // Save timesheet images
    const original = await DataStore.query(Event, activeEventId!);
    await DataStore.save(
      Event.copyOf(original!, (updated) => {
        updated.photos = activeEvent!.photos;
      })
    );

    // Load image data
    const photos = activeEvent!.photos.map(async (photo) => {
      const photoUrl = await Storage.get(photo!);
      return photoUrl;
    });

    Promise.all(photos).then((results) => setActiveEventImages(results));
  };
  // Add image
  const addActiveEventImage = async (image: string) => {
    let images = [image];
    activeEvent?.photos.map((photo) => {
      if (photo) images.push(photo);
    });
    setActiveEvent({ ...activeEvent!, photos: images });
  };
  // Remove image
  const deleteActiveEventImage = async (image: string) => {
    try {
      // Get index
      const indexOfImage = activeEventImages?.indexOf(image);

      if (indexOfImage) {
        // Photos array
        const newImages = [...activeEvent?.photos!];

        // Remove from storage
        await Storage.remove(newImages[indexOfImage]!);

        // New photo array
        newImages.splice(indexOfImage!, 1);

        // Save timesheet images
        const original = await DataStore.query(Event, activeEventId!);

        // console.log(original)
        await DataStore.save(
          Event.copyOf(original!, (updated) => {
            updated.photos = newImages;
          })
        );

        // Update timeshett
        setActiveEvent({ ...activeEvent!, photos: newImages });
      }
    } catch (error) {
      console.log("Error removing image: ", error);
    }
  };
  // Save event
  const saveEvent = async () => {
    try {
      // reload
      const original = await DataStore.query(Event, activeEventId!);

      await DataStore.save(
        Event.copyOf(original!, (updated) => {
          updated.start = formatAWSDateTime(activeEvent!.start);
          updated.title = activeEvent!.title;
          updated.description = activeEvent!.description;
          updated.material = activeEvent!.material;
          updated.lockbox = activeEvent!.lockbox;
          updated.employeeIds = activeEvent!.employeeIds;
          updated.project = undefined;
          updated.eventProjectId = activeEvent?.projectId;
          updated.coordinator = undefined;
          updated.eventCoordinatorId = activeEvent?.coordinatorId;
          updated.contact = undefined;
          updated.eventContactId = activeEvent?.contactId;
          updated.photos = activeEvent!.photos;
          updated.color = activeEvent?.color;
        })
      );
    } catch (error) {
      console.log("Error saving event: ", error);
    }
  };

  // UPdate description on project change
  useEffect(() => {
    if (activeEvent?.projectId) {
      setDefaultDescription();
    }
  }, [activeEvent?.projectId]);

  // Load event
  useEffect(() => {
    if (activeEventId) loadEvent();
  }, [activeEventId]);

  // Trigger to load images
  useEffect(() => {
    if (activeEvent?.photos) loadImages();
  }, [activeEvent?.photos]);

  return {
    activeEventId,
    activeEvent,
    imageToDelete,
    setActiveEvent,
    setActiveEventId,
    addEmployees,
    setImageToDelete,
    deleteActiveEventImage,
    removeEmployee,
    activeEventImages,
    saveEvent,
    addActiveEventImage,
  };
}
