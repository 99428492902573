import { IonItem, IonList, IonListHeader, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useStorage } from "../../hooks";

interface DocumentInputProps {
  label: string;
  value: string | undefined;
  hideHeader?: boolean;
  onUpload: (key: string) => void;
}

export const DocumentInput: React.FC<DocumentInputProps> = (props) => {
  // Hook into data
  const { uploadDocument, documentKey, setDocumentKey, documentLink } =
    useStorage();

  // Upload file to storage
  const handleUpload = async (file: File) => {
    let key = await uploadDocument(file);
    if (key) props.onUpload(key);
  };

  // Triger change

  // Handle change
  useEffect(() => {
    setDocumentKey(props.value);
  }, [props.value]);
  return (
    <IonList>
      {!props.hideHeader && (
        <IonListHeader>
          <IonText color="medium">{props.label}</IonText>
        </IonListHeader>
      )}

      <IonItem>
        {!documentKey && (
          <input
            type="file"
            onChange={(e) => handleUpload(e.target.files![0])}
          />
        )}
        {documentKey && (
          <a href={documentLink} target="_blank">
            {props.value}
          </a>
        )}
      </IonItem>
    </IonList>
  );
};
