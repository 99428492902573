import {
  IonCard,
  IonToolbar,
  IonTitle,
  IonCardContent,
  IonSearchbar,
} from "@ionic/react";

interface SearchCardProps {
  onKeywordChange: (keyword?: string) => void;
}

export const SearchCard: React.FC<SearchCardProps> = (props) => {
  return (
    <IonCard>
      <IonToolbar>
        <IonTitle>Search</IonTitle>
      </IonToolbar>
      <IonCardContent>
        <IonSearchbar
          onIonChange={(e) => props.onKeywordChange(e.detail.value as string)}
        />
      </IonCardContent>
    </IonCard>
  );
};
