import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  return (
    <IonButton onClick={() => props.onClick()}>
      <IonIcon icon={closeOutline} />
    </IonButton>
  );
};
