import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Invoice, Project, ProjectStatus } from "../models";
import { Accounts, Expenses } from "../models";
import { formatAWSDate } from "../_utils/datetime";

interface InvoiceModalForm {
  number: null | string | undefined;
  amount: number | undefined;
  date: Date;
  account: Accounts | undefined;
  category: Expenses | undefined;
  description: null | string | undefined;
  business: null | string | undefined;
  projectIds: (string | null)[] | null | undefined;
  partition: (number | null)[] | null | undefined;
  document: null | string | undefined;
}

export function useInvoice() {
  // Satat id
  const [activeInvoiceId, setActiveInvoiceId] = useState<string>();
  const [activeInvoice, setActiveInvoice] = useState<InvoiceModalForm>();

  // Partition
  const [projectID, setProjectID] = useState<string>();
  const [projectPartition, setProjectPartition] = useState<number>(100);

  // Load invoice
  const loadInvoice = async () => {
    try {
      let invoice = await DataStore.query(Invoice, activeInvoiceId!);
      setActiveInvoice({
        number: invoice?.number,
        amount: invoice?.amount !== null ? invoice?.amount : undefined,
        date: invoice?.date ? new Date(invoice?.date) : new Date(),
        account: invoice?.account as Accounts,
        category: invoice?.category as Expenses,
        business: invoice?.business,
        projectIds: invoice?.projectIds,
        partition: invoice?.partition,
        description: invoice?.description,
        document: invoice?.document,
      });
    } catch (error) {
      console.log("Error loading invoice: ", error);
    }
  };

  // Save Content
  const saveInvoice = async () => {
    try {
      // make a copy
      let original = await DataStore.query(Invoice, activeInvoiceId!);

      await DataStore.save(
        Invoice.copyOf(original!, (updated) => {
          updated.number = activeInvoice?.number;
          updated.amount = activeInvoice?.amount;
          updated.date = formatAWSDate(activeInvoice?.date!);
          updated.account = activeInvoice?.account;
          updated.category = activeInvoice?.category;
          updated.business = activeInvoice?.business;
          updated.projectIds = activeInvoice?.projectIds;
          updated.partition = activeInvoice?.partition;
          updated.description = activeInvoice?.description;
          updated.document = activeInvoice?.document;
        })
      );
    } catch (error) {
      console.log("Error saving invoice data: ", error);
    }
  };

  // Add new incoice
  const addInvoice = async () => {
    try {
      let projectIds = activeInvoice?.projectIds
      // let projectId = projectIds !== null && projectIds !== undefined && projectIds.length > 0 ? projectIds[0] : null;
      // if (projectId !== null && projectId !== '') {
      //   let project = await DataStore.query(Project, projectId as string);
      //   if (project?.status == ProjectStatus.ARCHIVED) {
      //     alert('JOB IS ARCHIVED');
      //   }
      // }
      await DataStore.save(
        new Invoice({
          number: activeInvoice?.number,
          amount: activeInvoice?.amount,
          date: formatAWSDate(activeInvoice?.date!),
          account: activeInvoice?.account,
          category: activeInvoice?.category,
          business: activeInvoice?.business,
          projectIds: activeInvoice?.projectIds,
          partition: activeInvoice?.partition,
          description: activeInvoice?.description,
          document: activeInvoice?.document,
        })
      );
    } catch (error) {
      console.log("Error creating invoice: ", error);
    }
  };

  // Partition bill
  const partitionInvoice = () => {
    // Add id
    if (projectID && projectPartition) {
      // Array holding the ids for projects
      let projectIds = activeInvoice?.projectIds
        ? activeInvoice?.projectIds.concat([projectID])
        : [projectID];
      // Array holding partion percentages
      let projectPartitions = activeInvoice?.partition
        ? activeInvoice?.partition.concat([projectPartition])
        : [projectPartition];

      // Add details
      setActiveInvoice({
        ...activeInvoice!,
        projectIds: projectIds,
        partition: projectPartitions,
      });

      // Reset state
      setProjectID(undefined);
      setProjectPartition(0);
    }
  };

  const removePartition = (index: number) => {
    // Make copy
    if (activeInvoice?.projectIds && activeInvoice?.partition) {
      let projectIds = [...activeInvoice?.projectIds];
      let projectPs = [...activeInvoice?.partition];
      // Remove
      projectIds?.splice(index, 1);
      projectPs?.splice(index, 1);

      // Add details
      setActiveInvoice({
        ...activeInvoice!,
        projectIds: projectIds,
        partition: projectPs,
      });
    }
  };

  // trigger
  useEffect(() => {
    activeInvoiceId ? loadInvoice() : setActiveInvoice(undefined);
  }, [activeInvoiceId]);

  return {
    activeInvoiceId,
    setActiveInvoiceId,
    activeInvoice,
    setActiveInvoice,
    saveInvoice,
    addInvoice,
    projectID,
    setProjectID,
    projectPartition,
    setProjectPartition,
    partitionInvoice,
    removePartition,
  };
}
