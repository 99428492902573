import { IonContent, IonPage } from "@ionic/react";
import { Header, OnCallList, TimeOffCalendar, TimeOffRequestList } from "../components";
import { TabItem, Tabs } from "@aws-amplify/ui-react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';

interface UsersProps {}

export const OnCall: React.FC<UsersProps> = (props) => {
  return (
    <IonPage>
      {/* Page title */}
      <Header title={"On Call"} />

      {/* Page Content */}
      <IonContent>
        <Tabs justifyContent="flex-start" defaultValue="oncall">
          <TabItem title="On Call" value="oncall">
            {/* Oncall list */}
            <OnCallList />
          </TabItem>
          <TabItem title="Time off" value="timeoff">
            {/* Oncall list */}
            <TimeOffRequestList />
          </TabItem>
          <TabItem title="Calendar" value="oncall">
            <TimeOffCalendar />
          </TabItem>
        </Tabs>
      </IonContent>
    </IonPage>
  );
};
