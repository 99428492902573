import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Document, Project } from "../models";
import { formatAWSDate } from "../_utils/datetime";
import { useUser } from "./";

export function useDocuments() {
  // Get use
  const { authUser } = useUser();

  // State
  const [activeProjectId, setActiveProjectId] = useState<string>();
  const [documents, setDocuments] = useState<Document[]>();
  const [documentsApproved, setDocumentsApproved] = useState<Document[]>();
  const [documentsUnapproved, setDocumentsUnapproved] = useState<Document[]>();
  const [activeDocumentId, setActiveDocumentId] = useState<string>();

  // Refresh
  const [reloadState, setReloadState] = useState(false);

  // Load documents
  const loadDocuments = async () => {
    try {
      console.log(123);
      let data = await DataStore.query(Document, (c) =>
        c.documentProjectId("eq", activeProjectId!),
        {
          sort: (s) => s.rank(SortDirection.ASCENDING)
        }
      );
      data = data.filter((c) => {
        return "key" in c && c.key !== null;
      });
      console.log(data);
      setDocuments(data);
      setDocumentsApproved(data.filter((doc) => {
        return doc.approved;
      })); 
      setDocumentsUnapproved(data.filter((doc) => {
        return !doc.approved;
      }));
    } catch (error) {
      console.log("Error loading documents: ", error);
    }
  };

  // Create document
  const addDocument = async () => {
    try {
      let newDoc = await DataStore.save(
        new Document({
          documentProjectId: activeProjectId,
          approved: false,
          date: formatAWSDate(new Date()),
          name: authUser?.username,
          rank: -1,
        })
      );
      updateRanks();
      setActiveDocumentId(newDoc.id);
    } catch (error) {
      console.log("Error creating document: ", error);
    }
  };

  const updateRanks = async () => {
    try {
      // Load project data
      let original = await DataStore.query(Project, activeProjectId!);
      console.log('fine 1');
      //console.log(original?.noteIds);
      // Update
      {
        // Copy elements
        //let tempIDs = [...original?.noteIds];
        //let tempID = tempIDs![from];

        let tempIDsApproved = documentsApproved?.filter((doc) => {
          return doc?.approved;
        }).map((doc) => {
          return doc.id;
        });

        // let tempIDsUnapproved = documentsApproved?.filter((doc) => {
        //   return !doc?.approved;
        // }).map((doc) => {
        //   return doc.id;
        // });

        let data = await DataStore.query(Document, (c) =>
          c.documentProjectId("eq", activeProjectId!),
          {
            sort: (s) => s.rank(SortDirection.ASCENDING)
          }
        );

        data = data.filter((c) => {
          return "key" in c && c.key !== null;
        });

        console.log(activeProjectId);
        console.log(data);
        console.log(documents);

        // let ldocumentsApproved = documentsApproved ? [...documentsApproved!] : data.filter((doc) => {
        //   return doc?.approved;
        // });
        // let ldocumentsUnapproved = documentsUnapproved ? [...documentsUnapproved!] : data.filter((doc) => {
        //   return !doc?.approved;
        // });

        let ldocumentsApproved = data.filter((doc) => {
          return doc?.approved;
        });
        let ldocumentsUnapproved = data.filter((doc) => {
          return !doc?.approved;
        });

        let ndocumentsApproved = [];
        let ndocumentsUnapproved = [];

        //let tempIDs = [ ...tempIDsApproved!, ...tempIDsUnapproved! ];
        for (let i = 0; i < ldocumentsApproved.length; i++) {
          let document = ldocumentsApproved[i];
          let updatedDocument = Document.copyOf(document, (updated) => {
            updated.rank = i;
          });
          await DataStore.save(
            updatedDocument
          );
          ndocumentsApproved.push(updatedDocument);
        }
        for (let i = 0; i < ldocumentsUnapproved.length; i++) {
          let document = ldocumentsUnapproved[i];
          let updatedDocument = Document.copyOf(document, (updated) => {
            updated.rank = ldocumentsApproved.length + i;
          });
          await DataStore.save(
            updatedDocument
          );
          ndocumentsUnapproved.push(updatedDocument);
        }

        console.log(ndocumentsApproved);
        console.log(ndocumentsUnapproved);

        let data2 = await DataStore.query(Document, (c) =>
          c.documentProjectId("eq", activeProjectId!),
          {
            sort: (s) => s.rank(SortDirection.ASCENDING)
          }
        );

        data2 = data2.filter((c) => {
          return "key" in c && c.key !== null;
        });

        // setDocumentsApproved(ndocumentsApproved);
        // setDocumentsUnapproved(ndocumentsUnapproved);

        setDocuments(data2);
        setDocumentsApproved(data2.filter((doc) => {
          return doc.approved;
        })); 
        setDocumentsUnapproved(data2.filter((doc) => {
          return !doc.approved;
        }));
        
        // await DataStore.save(
        //   Project.copyOf(original!, (updated) => {
        //     updated.noteIds = tempIDs;
        //   })
        // );
        //

        //loadDocuments();
      }
    } catch (error) {
      console.log("Error fixing note order: ", error);
    }
  };

  // Update order
  const updateOrder = async (type: string, from: number, to: number) => {
    try {
      if (type !== 'approved') {
        type = 'unapproved';
      }
      // Load project data
      let original = await DataStore.query(Project, activeProjectId!);
      //console.log(original?.noteIds);
      // Update
      //if (original?.noteIds) {
        // Copy elements
        //let tempIDs = [...original?.noteIds];
        //let tempID = tempIDs![from];

        // let tempIDsApproved = documentsApproved?.filter((doc) => {
        //   return doc?.approved;
        // }).map((doc) => {
        //   return doc.id;
        // });

        // let tempIDsUnapproved = documentsApproved?.filter((doc) => {
        //   return !doc?.approved;
        // }).map((doc) => {
        //   return doc.id;
        // });

        let ldocumentsApproved = [...documentsApproved!];
        let ldocumentsUnapproved = [...documentsUnapproved!];
        
        switch (type) {
          case "approved":
            {
              //let tempID = tempIDsApproved![from];
              let tempDocument = ldocumentsApproved![from];
              // Remove element from array
              //tempIDsApproved?.splice(from, 1);
              ldocumentsApproved.splice(from, 1);
              // Replace item
              //tempIDsApproved?.splice(to, 0, tempID);
              ldocumentsApproved.splice(to, 0, tempDocument);
              //console.log(tempIDsApproved);
              console.log(ldocumentsApproved);
            }
            break;
          default:
            {
              //let tempID = tempIDsUnapproved![from];
              let tempDocument = ldocumentsUnapproved![from];
              // Remove element from array
              //tempIDsUnapproved?.splice(from, 1);
              ldocumentsUnapproved.splice(from, 1);
              // Replace item
              //tempIDsUnapproved?.splice(to, 0, tempID);
              ldocumentsUnapproved.splice(to, 0, tempDocument);
              //console.log(tempIDsApproved);
              console.log(ldocumentsUnapproved);
            }
            break;
        }

        //let tempIDs = [ ...tempIDsApproved!, ...tempIDsUnapproved! ];
        for (let i = 0; i < ldocumentsApproved.length; i++) {
          let document = ldocumentsApproved[i];
          await DataStore.save(
            Document.copyOf(document, (updated) => {
              updated.rank = i;
            })
          );
        }
        for (let i = 0; i < ldocumentsUnapproved.length; i++) {
          let document = ldocumentsUnapproved[i];
          await DataStore.save(
            Document.copyOf(document, (updated) => {
              updated.rank = ldocumentsApproved.length + i;
            })
          );
        }
        
        // await DataStore.save(
        //   Project.copyOf(original!, (updated) => {
        //     updated.noteIds = tempIDs;
        //   })
        // );
        //

        loadDocuments();
      //}
    } catch (error) {
      console.log("Error fixing note order: ", error);
    }
  };

  //Reload trigger
  // useEffect(() => {
  //   console.log('PASSOU AQUI STATE');
  //   console.log(123123);
  //   console.log(reloadState);
  //   if (reloadState) {
  //     updateRanks();
  //     //loadDocuments();
  //     setReloadState(false);
  //   }
  // }, [reloadState]);

  useEffect(() => {
    if (activeProjectId) {
      console.log('activeDocumentId CHANGED');  
      if (!activeDocumentId) {
        console.log('WINDOW WAS CLOSED');  
        updateRanks();  
      } else {
        console.log('WINDOW WAS OPEN');  
      }
    }
  }, [activeDocumentId]);

  // Trigger
  useEffect(() => {
    console.log('PASSOU AQUI');
    if (activeProjectId) loadDocuments();
    //if (activeProjectId) updateRanks();
  }, [activeProjectId]);

  // Return
  return {
    setActiveProjectId,
    addDocument,
    activeDocumentId,
    setActiveDocumentId,
    documents,
    documentsApproved,
    documentsUnapproved,
    setReloadState,
    updateOrder,
    updateRanks,
    loadDocuments,
  };
}
