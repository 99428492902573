import { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  CloseButton,
  DateInput,
  ImageInput,
  ProjectInput,
  SelectInput,
  StringInput,
  TextAreaInput,
  TimeInput,
  ToggleInput,
} from "..";
import { useTimesheet, useUser } from "../../hooks";
import { TradeType, TimesheetStatus } from "../../models";
import { updateTime } from "../../_utils/datetime";

interface TimesheetModalProps {
  timesheetId: null | string | undefined;
  onDismiss: () => void;
  onEditSave?: () => void;
}

export const TimesheetModal: React.FC<TimesheetModalProps> = (props) => {
  // User contect
  const { security } = useUser();
  const [canEdit, setCanEdit] = useState(false);

  // Modal state
  const [isOpen, setIsOpen] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  // Get active/create new timesheet info
  const {
    activeTimesheet,
    addActiveTimesheetImage,
    activeTimesheetImages,
    saveTimesheet,
    setActiveTimesheetID,
    setActiveTimesheet,
    deleteActiveTimesheetImage,
  } = useTimesheet();

  let doValidate = () => {
    if (
      !activeTimesheet?.projectId 
      || !activeTimesheet.projectId 
      || !activeTimesheet.date 
      || activeTimesheet.start === null 
      || activeTimesheet.start === undefined 
      || activeTimesheet.end === null 
      || activeTimesheet.end === undefined
      || !activeTimesheet.details) {
        return false;
    }
    return true;
  };

  // Job status color
  let timesheetStatusColor = "primary";
  if (activeTimesheet?.status === TimesheetStatus.REJECTED)
    timesheetStatusColor = "danger";
  if (activeTimesheet?.status === TimesheetStatus.APPROVED)
    timesheetStatusColor = "medium";

  // Save date
  const handleSubmit = async () => {
    await saveTimesheet();
    props.onEditSave && props.onEditSave();
    props.onDismiss();
  };

  // Load data
  useEffect(() => {
    setActiveTimesheetID(props.timesheetId !== null ? props.timesheetId : undefined);
    props.timesheetId ? setIsOpen(true) : setIsOpen(false);
  }, [props.timesheetId]);

  // Block inputs
  useEffect(() => {
    activeTimesheet?.status === TimesheetStatus.UNAPPROVED ||
    activeTimesheet?.status === TimesheetStatus.REJECTED
      ? setDisableInput(false)
      : setDisableInput(true);
    setIsValidated(doValidate());
  }, [activeTimesheet]);

  // Security
  useEffect(() => {
    if (security.isAdmin || security.isOffice) {
      setCanEdit(true);
    }
  }, [security]);

  // Modal
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        <IonTitle>Timesheet</IonTitle>
        {!isValidated &&
            <IonTitle color="danger" size="small">
              All fields except the images field are required.
            </IonTitle>
          }
        {/* Status */}
        <IonButtons slot="end">
          <IonButton size="small" shape="round" color={timesheetStatusColor}>
            {activeTimesheet?.status == TimesheetStatus.UNAPPROVED ? '' : activeTimesheet?.status}
          </IonButton>
          <CloseButton onClick={() => props.onDismiss()} />
        </IonButtons>
      </IonToolbar>

      {/* Timeshett active details */}
      {activeTimesheet && (
        <IonContent>
          {/* Display regetted info */}
          {security.isField &&
            activeTimesheet?.status === TimesheetStatus.REJECTED &&
            activeTimesheet.note && (
              <IonList className="ion-no-padding">
                <IonListHeader>
                  <IonText color="danger">
                    * Note: {activeTimesheet.note}
                  </IonText>
                </IonListHeader>
              </IonList>
            )}

          {/* Project */}
          <ProjectInput
            id={activeTimesheet.projectId}
            canEdit={!disableInput}
            onSelect={(id) =>
              setActiveTimesheet({ ...activeTimesheet, projectId: id })
            }
          />

          {/* Work details */}
          <IonList lines="none">
            <IonListHeader>
              <IonText color="medium">Work Details</IonText>
            </IonListHeader>

            {/* Date input */}
            <DateInput
              value={activeTimesheet.date}
              disabled={disableInput}
              onChange={(value) =>
                setActiveTimesheet({ ...activeTimesheet, date: value })
              }
            />

            {/* Start time */}
            <TimeInput
              label="Start"
              value={activeTimesheet.start}
              disabled={disableInput}
              onChange={(value) =>
                setActiveTimesheet({
                  ...activeTimesheet,
                  start: updateTime(activeTimesheet.start, value),
                })
              }
            />

            {/* End time */}
            <TimeInput
              label="End"
              value={activeTimesheet.end}
              disabled={disableInput}
              onChange={(value) =>
                setActiveTimesheet({
                  ...activeTimesheet,
                  end: updateTime(activeTimesheet.end, value),
                })
              }
            />

            {/* Lunch */}
            <ToggleInput
              label="Lunch"
              value={activeTimesheet.lunch}
              disabled={disableInput}
              onChange={(value) =>
                setActiveTimesheet({
                  ...activeTimesheet!,
                  lunch: value,
                })
              }
            />

            {/* Trade */}
            {(security.isAdmin || security.isOffice) &&
              <SelectInput
                label="Type"
                value={activeTimesheet.trade}
                canEdit={!disableInput}
                options={Object.keys(TradeType)}
                onChange={(value) =>
                  setActiveTimesheet({
                    ...activeTimesheet!,
                    trade: value as TradeType,
                  })
                }
              />
            }

            {/* Description */}
            <TextAreaInput
              lable="Details"
              value={activeTimesheet.details}
              readonly={disableInput}
              onChange={(value) =>
                setActiveTimesheet({
                  ...activeTimesheet!,
                  details: value,
                })
              }
            />

            {/* Images */}
            <ImageInput
              images={activeTimesheetImages}
              addImage={(image) => addActiveTimesheetImage(image)}
              deleteImage={(image) => deleteActiveTimesheetImage(image)}
            />

            {/* Update status */}
            {canEdit && (
              <SelectInput
                label="Status"
                value={activeTimesheet.status}
                canEdit={true}
                // options={Object.keys(TimesheetStatus)}
                options={[TimesheetStatus.APPROVED, TimesheetStatus.UNAPPROVED]}
                onChange={(value) => {
                  setActiveTimesheet({
                    ...activeTimesheet,
                    status: value as TimesheetStatus,
                    note: "",
                  });
                }}
              />
            )}

            {/* Update billing_status */}
            {canEdit && (
              <SelectInput
                label="Billing Status"
                value={activeTimesheet.billing_status}
                canEdit={true}
                // options={Object.keys(TimesheetStatus)}
                options={[TimesheetStatus.BILLED, TimesheetStatus.UNBILLED]}
                onChange={(value) => {
                  setActiveTimesheet({
                    ...activeTimesheet,
                    billing_status: value as TimesheetStatus,
                    note: "",
                  });
                }}
              />
            )}

            {/* Update Reject note */}
            {canEdit && activeTimesheet.status === TimesheetStatus.REJECTED && (
              <StringInput
                label="Note"
                value={activeTimesheet.note}
                canEdit={true}
                onChange={(value) =>
                  setActiveTimesheet({ ...activeTimesheet, note: value })
                }
              />
            )}
          </IonList>

          {isValidated &&
            <IonButton onClick={() => handleSubmit()} expand="block">
              Save
            </IonButton>
          }
        </IonContent>
      )}
    </IonModal>
  );
};
