import { createContext, FC, useEffect, useState } from "react";
import { Auth, DataStore } from "aws-amplify";
import { AuthUser, SecurityGroups } from "../models";

const defaultUserState = {
  security: {
    isAdmin: false,
    isOffice: false,
    isField: false,
  },
  username: "MACTECH",
  email: "",
};

interface UserContextProps {
  username: string;
  email: string;
  security: { isAdmin: boolean; isOffice: boolean; isField: boolean };
  authUser?: AuthUser;
  logout?: () => void;
}

export const UserContext = createContext<UserContextProps>(defaultUserState);

export const UserProvider: FC = ({ children }) => {
  // Security
  const [isAdmin, setIsAdmin] = useState(defaultUserState.security.isAdmin);
  const [isOffice, setIsOffice] = useState(defaultUserState.security.isAdmin);
  const [isField, setIsField] = useState(defaultUserState.security.isAdmin);

  // Cognito user stuff
  const [username, setUsername] = useState(defaultUserState.username);
  const [email, setEmail] = useState(defaultUserState.email);

  // Auth user
  const [authUser, setAuthUser] = useState<AuthUser>();

  async function loadUser() {
    try {
      // Connect to auth
      const cognitoUser = await Auth.currentAuthenticatedUser();

      console.log({cognitoUser});

      let authUser0 = [];
      // Pull user data - everyting is linked to the email
      const authUserSet = await DataStore.query(AuthUser);
      for (let ouser of authUserSet) {
        if (ouser.username?.toLowerCase() === cognitoUser.username.toLowerCase()) {
          authUser0.push(ouser);
          break;
        }
      }

      // Pull user data - everyting is linked to the email
      const authUser = authUser0 !== null ? authUser0 : await DataStore.query(AuthUser, (c) =>
        c.username("eq", cognitoUser.username)
        //c.email("eq", cognitoUser.attributes.email).or((c) => c.username("eq", cognitoUser.username))
      );

      console.log({authUser});
      
      // Logout if error getting person
      if (authUser.length === 0) {
        logout();
      } else {
        // Set state
        setUsername(cognitoUser.username);
        setEmail(cognitoUser.attributes.email);
        setIsAdmin(authUser[0].security === SecurityGroups.ADMIN);
        setIsOffice(authUser[0].security === SecurityGroups.OFFICE);
        setIsField(authUser[0].security === SecurityGroups.FIELD);
        setAuthUser(authUser[0]);
      }
    } catch (error) {
      console.log("error loading user: ", error);
    }
  }

  // logout
  const logout = () => {
    try {
      // await DataStore.clear();
      Auth.signOut({ global: true });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // Load inforamation
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        security: {
          isAdmin,
          isOffice,
          isField,
        },
        username,
        email,
        authUser,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
