import { DataStore, SortDirection, Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { SecurityGroups } from "../models";
import { AuthUser } from "../models";
import AWS from "aws-sdk";

export function useEmployees() {
  // State
  const [officeEmployees, setOfficeEmployees] = useState<AuthUser[]>();
  const [fieldEmployees, setFeedEmployees] = useState<AuthUser[]>();
  const [queriedEmployees, setQueriedEmployees] = useState<AuthUser[]>();
  const [activeEmployeeId, setActiveEmployeeId] = useState<string>();
  const [activeEmployeeIdForPasswordChange, setActiveEmployeeIdForPasswordChange] = useState<string>();
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // Group
  const queryEmployees = async () => {
    if (officeEmployees && fieldEmployees) {
      // Create regex
      let keywordRegex = new RegExp(keyword || "", "i");

      // Create full array
      let employees = officeEmployees?.concat(fieldEmployees!);

      // filter
      let qedEmployees = employees?.filter((employee) => {
        // Username
        if (employee.username?.match(keywordRegex)) return true;

        // Email
        if (employee.email?.match(keywordRegex)) return true;

        // Default
        return false;
      });

      // UPdate state
      setQueriedEmployees(qedEmployees);
    }
  };

  // Load all office employees
  const loadOffice = async () => {
    try {
      // Load users with security tag of office
      let data = await DataStore.query(AuthUser, (c) =>
        c.or((c) =>
          c
            .security("contains", SecurityGroups.OFFICE)
            .security("contains", SecurityGroups.ADMIN)
        ),
        {
          sort: (s) => s.username(SortDirection.ASCENDING)
        }
      );

      setOfficeEmployees(data);
    } catch (error) {
      console.log("Error loading office employees: ", error);
    }
  };

  // Load all field
  const loadField = async () => {
    try {
      // Load users with security tag of office
      let data = await DataStore.query(AuthUser, (c) =>
        c.security("contains", SecurityGroups.FIELD),
        {
          sort: (s) => s.username(SortDirection.ASCENDING)
        }
      );

      setFeedEmployees(data);
    } catch (error) {
      console.log("Error loading field employees: ", error);
    }
  };

  const deleteAuthUser = async (authUserId: string) => {
    try {
      const toDelete = await DataStore.query(AuthUser, authUserId)
      console.log(toDelete);
      if (toDelete) {
        DataStore.delete(toDelete);
      }
    } catch (error) {
      console.log("Error deleting invoice");
    }
  };

  // Change user password
  const changeAuthUserPassword = async (authUserId: string, password: string) => {

    const toChangePassword = await DataStore.query(AuthUser, authUserId);
    const username = toChangePassword?.username;

    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
    const userPoolId = currentAuthenticatedUser.pool.userPoolId;

    let auserPoolId = userPoolId.split('_');
    let awsLocation = auserPoolId[0];

    const credentials = await Auth.currentCredentials();

    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
      region: awsLocation,
      credentials: credentials
    });

    let params = {
      Password: password,
      UserPoolId: userPoolId,
      Username: username !== null && username !== undefined ? username : '',
      Permanent: true
    };

    console.log(params);

    try {
      let o = cognitoidentityserviceprovider.adminSetUserPassword(params, function(err, data) {
        console.log(err);
        console.log(data);
      });
      console.log('Password was changed for user ' + username);
    } catch (e) {
      throw e;
    }

  };

  const loadAll = async () => {
    loadOffice();
    loadField();
  };

  // Query
  useEffect(() => {
    queryEmployees();
  }, [keyword, officeEmployees, fieldEmployees]);

  // Load shit
  useEffect(() => {
    loadOffice();
    loadField();
  }, []);

  return {
    queryEmployees,
    loadAll,
    officeEmployees,
    fieldEmployees,
    activeEmployeeId,
    setActiveEmployeeId,
    activeEmployeeIdForPasswordChange,
    setActiveEmployeeIdForPasswordChange,
    setKeyword,
    queriedEmployees,
    deleteAuthUser,
    changeAuthUserPassword
  };
}
