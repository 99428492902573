import {
  IonCard,
  IonCardContent,
  IonContent,
  IonList,
  IonPage,
} from "@ionic/react";
import {
  Header,
  RevenueItem,
  RevenueListHeader,
  InvoiceModal,
  RevenueCreateCard,
  SearchCard,
} from "../components";
import { useInvoices } from "../hooks";
import { Accounts } from "../models";

interface AccountingProps {}

export const Revenue: React.FC<AccountingProps> = (props) => {
  // Tap into data
  const {
    activeInvoiceId,
    setActiveInvoiceId,
    setContactKeyword,
    queriedInvoices,
    setReloadState,
    loadInvoices,
  } = useInvoices();

  return (
    <IonPage>
      {/* Header */}
      <Header title={"Revenue"} />

      {/* Page content */}
      <IonContent>
        {/* Search Card */}
        <SearchCard onKeywordChange={setContactKeyword} />

        {/* Revenue */}
        <RevenueCreateCard onCreate={() => loadInvoices()} />

        {/* Layour */}
        <IonCard>
          <IonCardContent>
            <IonList>
              {/* Header */}
              <RevenueListHeader />

              {/* Invoices */}
              {queriedInvoices
                ?.filter((invoice) => {
                  return invoice.account === Accounts.INCOME;
                })
                .map((invoice) => {
                  return (
                    <RevenueItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                    />
                  );
                })}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>

      {/* Modal */}
      <InvoiceModal
        invoiceId={activeInvoiceId}
        onDismiss={() => {
          setActiveInvoiceId(undefined);
          setReloadState(true);
        }}
        typeFilter={'revenue'}
      />
    </IonPage>
  );
};
