import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  DateInput,
  DocumentInput,
  StringInput,
  NumberInput,
  TextAreaInput,
  ProjectSplitInput,
  JobInput,
} from "../Inputs";
import { useInvoice, useInvoices  } from "../../hooks";
import { Accounts, Expenses, Project } from "../../models";

interface RevenueCreateCardProps {
  onCreate: () => void;
  project?: Project;
}

export const RevenueCreateCard: React.FC<RevenueCreateCardProps> = (props) => {
  // Hook into accounting
  const { activeInvoice, setActiveInvoice, projectID, addInvoice } =
    useInvoice();

  const { queryRevenueInvoicesByNumber } = useInvoices();

  const [ detectedDuplicateInvoiceNumber, setDetectedDuplicateInvoiceNumber ] = useState<boolean>(false);

  const [ firsttime, setFirsttime ] = useState<boolean>(true);

  // Blank invoice
  const blankInvoice = () => {
    setActiveInvoice({
      amount: 0.0,
      account: Accounts.INCOME,
      category: undefined,
      date: new Date(),
      number: undefined,
      description: undefined,
      business: undefined,
      document: undefined,
      projectIds: activeInvoice ? activeInvoice.projectIds : (props.project ? [props.project.id] : ['']),
      partition: [0],
    });
  };

  // Save invoie
  const handleCreate = async () => {
    await addInvoice().then(() => {
      props.onCreate();
      blankInvoice();
    });
  };

  //Auto load
  useEffect(() => {
    if (firsttime) {
      setFirsttime(false);
      blankInvoice();  
    }
  }, []);

  useEffect(() => {
    console.log(activeInvoice);
    if (activeInvoice?.number) {
      queryRevenueInvoicesByNumber(activeInvoice?.number).then((result) => {
        console.log(result);
        if (result != null && result.length > 0) {
          setDetectedDuplicateInvoiceNumber(true);
        } else {
          setDetectedDuplicateInvoiceNumber(false);
        }
      });
    } else {
      setDetectedDuplicateInvoiceNumber(false);
    }
  }, [activeInvoice]);

  return (
    <IonCard>
      {activeInvoice && (
        <>
          {/* Header */}
          <IonCardHeader>
            <IonToolbar>
              <IonTitle>Add Revenue</IonTitle>
              {(!activeInvoice || !activeInvoice.number || !activeInvoice.date || !activeInvoice.description || !activeInvoice.amount || !activeInvoice.projectIds || !activeInvoice.projectIds.length || !activeInvoice.projectIds[0]) &&
                <IonTitle color="danger">Please fill an Invoice #, Amount, Date, Description and Job PO</IonTitle>
              }
              {detectedDuplicateInvoiceNumber && 
                <IonTitle color="danger">Duplicate invoice number</IonTitle>
              }
              <IonButtons slot="end">
                {/* Save */}
                <IonButton
                  expand="full"
                  disabled={!activeInvoice || !activeInvoice.number || !activeInvoice.date || !activeInvoice.description || !activeInvoice.amount || !activeInvoice.projectIds || !activeInvoice.projectIds.length || !activeInvoice.projectIds[0] || detectedDuplicateInvoiceNumber}
                  onClick={() => handleCreate()}
                >
                  Save
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonCardHeader>

          {/* Form */}

          <IonCardContent>
            <IonGrid>
              <IonRow>
                
                {/* Job PO# */}
                {!props.project &&
                  <IonCol>
                    <JobInput
                      id={projectID}
                      onSelect={(id) =>
                        setActiveInvoice({ ...activeInvoice, projectIds: [id] })
                      }
                    />
                  </IonCol>
                }

                {/* Invoice # */}
                <IonCol>
                  <StringInput
                    label="Invoice #"
                    value={activeInvoice.number}
                    canEdit={true}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, number: value })
                    }
                  />
                </IonCol>

                {/* Description */}
                <IonCol>
                  <TextAreaInput
                    lable="Description"
                    value={activeInvoice.description}
                    readonly={false}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, description: value })
                    }
                  />
                </IonCol>

                {/* Amount */}
                <IonCol>
                  <NumberInput
                    label="Amount"
                    value={activeInvoice?.amount}
                    onChange={(value) => {
                      console.log(value);
                      setActiveInvoice({ ...activeInvoice, amount: value });
                    }}
                  />
                </IonCol>
                
                <IonCol>
                  {/* Date */}
                  <DateInput
                    value={activeInvoice.date}
                    disabled={false}
                    onChange={(value) =>
                      setActiveInvoice({ ...activeInvoice, date: value })
                    }
                  />
                </IonCol>

                {/* File */}
                <IonCol>
                  <DocumentInput
                    label="Add Revenue"
                    hideHeader={true}
                    value={activeInvoice.document !== null ? activeInvoice.document : undefined}
                    onUpload={(key) =>
                      setActiveInvoice({ ...activeInvoice, document: key })
                    }
                  />
                </IonCol>

                
              </IonRow>
              <IonRow>
                <IonCol size="10">
                </IonCol>
                <IonCol size="2">
                    {/* Save */}
                    <IonButton
                      expand="full"
                      disabled={!activeInvoice || !activeInvoice.number || !activeInvoice.date || !activeInvoice.description || !activeInvoice.amount || !activeInvoice.projectIds || !activeInvoice.projectIds.length || !activeInvoice.projectIds[0] || detectedDuplicateInvoiceNumber}
                      onClick={() => handleCreate()}
                    >
                      Save
                    </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>

        </>
      )}
    </IonCard>
  );
};
