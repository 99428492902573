/**************************************************************************
 * React Imports
 **************************************************************************/
import React from "react";

/**************************************************************************
 * Ionic Frameworks
 **************************************************************************/
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonToolbar,
} from "@ionic/react";

/**************************************************************************
 * Breakdown Card
 **************************************************************************/
import { ResponsivePie } from "@nivo/pie";
import { LabourCosts, ProjectRevenue } from "../../hooks";

interface BreakdownCardProps {
  labour: LabourCosts | undefined;
  revenue: ProjectRevenue | undefined;
  showUnapproved?: boolean;
}

export const BreakdownCard: React.FC<BreakdownCardProps> = (props) => {
  let data = [
    {
      id: "Plumbing",
      label: "Plumbing",
      value: props.labour?.plumbing.toFixed(2),
      color: "#c192c1",
    },
    {
      id: "HVAC",
      label: "HVAC",
      value: props.labour?.hvac.toFixed(2),
      color: "#3cbde3",
    }
  ];
  if (props.showUnapproved) {
    data.push({
      id: "Unapproved",
      label: "Unapproved",
      value: props.labour?.unapproved.toFixed(2),
      color: "#ffa600",
    });
  }
  data.push(...[
    {
      id: "Income",
      label: "Income",
      value: props.revenue?.income.toFixed(2),
      color: "#2fdf75",
    },
    {
      id: "Vendor",
      label: "Vendor",
      value: props.revenue?.vendor.toFixed(2),
      color: "#f4f5f8",
    },
    {
      id: "Subcontractor",
      label: "Subcontractor",
      value: props.revenue?.subcontractor.toFixed(2),
      color: "#989aa2",
    },
    {
      id: "Misc",
      label: "Misc.",
      value: props.revenue?.misc.toFixed(2),
      color: "#222428",
    },
  ]);

  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        <IonToolbar>
          <IonCardTitle>Breakdown</IonCardTitle>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent style={{ height: "50vh" }}>
        <ResponsivePie
          data={data}
          colors={{ datum: "data.color" }}
          margin={{ top: 40, bottom: 40 }}
          innerRadius={0.25}
          padAngle={0.7}
          cornerRadius={3}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLabelsTextColor="hsl(225, 22%, 96%)"
          legends={[
            {
              anchor: "left",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </IonCardContent>
    </IonCard>
  );
};
