import {
  IonItem,
  IonLabel,
  IonInput,
  IonPopover,
  IonDatetime,
} from "@ionic/react";
import { formatIonDateTimeString } from "../../_utils/datetime";

interface DateInputProps {
  value?: Date;
  disabled: boolean;
  onChange: (value: Date) => void;
  label?: string;
  tid?: string
}

export const DateInput: React.FC<DateInputProps> = (props) => {
  return (
    <IonItem id={props.tid ? props.tid : "open-date-input"} button disabled={props.disabled}>
      <IonLabel position="stacked">{props.label ? props.label : "Date"}</IonLabel>
      <IonInput
        readonly
        value={props.value?.toLocaleString("en-us", {
          weekday: "long",
          month: "short",
          day: "numeric",
        })}
      />
      <IonPopover
        trigger={props.tid ? props.tid : "open-date-input"}
        showBackdrop={false}
        dismissOnSelect={false}
        onWillDismiss={(e) => {
          // console.log(e.target);
          // console.log(e.srcElement);
          // console.log(123123);
        }}
      >
        <IonDatetime
          presentation="date"
          hourValues={[]}
          minuteValues={[]}
          value={props.value ? formatIonDateTimeString(props.value) : undefined}
          onSelect={(e) => 
            console.log('aaaa')
          }
          onIonChange={(e) => {
            props.onChange(new Date(e.detail.value as string));
          }}
        />
      </IonPopover>
    </IonItem>
  );
};
