import { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  EventColorInput,
  ListHeader,
  StringInput,
  DateTimeInput,
  TimeItem,
  TextAreaInput,
  EmployeeInput,
  ProjectInput,
  CoordinatorInput,
  SupervisorInput,
  CreatorItem,
  ImageInput,
  DeleteButton,
  CloseButton,
} from "../";
import { useEvent, useUser } from "../../hooks";

interface EventModalProps {
  eventId: null | string | undefined;
  deleteEvent: () => void;
  onDismiss: () => void;
}

export const EventModal: React.FC<EventModalProps> = (props) => {
  // User
  const { security } = useUser();
  const [canEdit, setCanEdit] = useState(false);

  // Open state
  const [showModal, setShowModal] = useState(false);

  // Connect to hook
  const {
    setActiveEventId,
    activeEvent,
    setActiveEvent,
    addActiveEventImage,
    deleteActiveEventImage,
    activeEventImages,
    addEmployees,
    removeEmployee,
    saveEvent,
  } = useEvent();

  // Handle save
  const handleSave = async () => {
    saveEvent();
    props.onDismiss();
  };

  // Handle delete
  const handleDelete = async () => {
    props.deleteEvent();
    props.onDismiss();
  };

  // Show when event there
  useEffect(() => {
    setActiveEventId(props.eventId !== null ? props.eventId : undefined);
    props.eventId ? setShowModal(true) : setShowModal(false);
  }, [props.eventId]);

  // Securtiy
  useEffect(() => {
    security.isAdmin || security.isOffice
      ? setCanEdit(true)
      : setCanEdit(false);
  }, [security]);

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        <IonTitle>
          {activeEvent?.start?.toLocaleString("en-us", {
            weekday: "long",
            month: "short",
            day: "numeric",
          }) +
            ": " +
            activeEvent?.title}
        </IonTitle>
        <IonButtons slot="end">
          {/* Archive button */}
          {canEdit && <DeleteButton onDelete={() => handleDelete()} />}
          {/* Close button */}
          <CloseButton onClick={() => props.onDismiss()} />
        </IonButtons>
      </IonToolbar>

      {activeEvent && (
        <IonContent>
          {/* Event deteails for office and admin to change*/}
          <IonList lines="none">
            <ListHeader title="Work Order Details" />

            {/* Project assignement */}
            <ProjectInput
              id={activeEvent.projectId}
              canEdit={canEdit}
              onSelect={(id) => {
                // Update stuff based on it
                setActiveEvent({
                  ...activeEvent!,
                  projectId: id,
                });
              }}
            />

            {canEdit && (
              <>
                {/* Title input */}
                <StringInput
                  label="Titles"
                  canEdit={canEdit}
                  value={activeEvent.title}
                  onChange={(value) =>
                    setActiveEvent({ ...activeEvent, title: value })
                  }
                />
                {/* Color */}
                <EventColorInput
                  value={activeEvent.color}
                  onChange={(value) =>
                    setActiveEvent({ ...activeEvent, color: value })
                  }
                />
                {/* Start Date */}
                <DateTimeInput
                  value={activeEvent.start}
                  disabled={!canEdit}
                  onChange={(value) =>
                    setActiveEvent({ ...activeEvent, start: value })
                  }
                />
              </>
            )}

            {/* Morning/Evening */}
            {!canEdit && <TimeItem value={activeEvent.start} />}

            {/* Description */}
            <TextAreaInput
              lable="Description"
              readonly={!canEdit}
              value={activeEvent.description}
              onChange={(value) =>
                setActiveEvent({
                  ...activeEvent!,
                  description: value,
                })
              }
            />

            {/* Material */}
            {(activeEvent.material || canEdit) && (
              <StringInput
                label="Material"
                value={activeEvent.material}
                canEdit={canEdit}
                onChange={(value) =>
                  setActiveEvent({
                    ...activeEvent!,
                    material: value,
                  })
                }
              />
            )}

            {/* LockBox */}
            {(activeEvent.lockbox || canEdit) && (
              <StringInput
                label="Lockbox"
                value={activeEvent.lockbox}
                canEdit={canEdit}
                onChange={(value) =>
                  setActiveEvent({
                    ...activeEvent!,
                    lockbox: value,
                  })
                }
              />
            )}
          </IonList>

          {/* Assigned employees */}
          {canEdit && (
            <EmployeeInput
              ids={activeEvent?.employeeIds}
              canEdit={canEdit}
              onAdd={(id) => addEmployees(id)}
              onRemove={(id) => removeEmployee(id)}
            />
          )}

          {/* Office coordinator assignment */}
          <CoordinatorInput
            id={activeEvent.coordinatorId}
            canEdit={canEdit}
            onSelect={(id) => {
              setActiveEvent({ ...activeEvent, coordinatorId: id });
            }}
          />

          {/* Supervisor input */}
          <SupervisorInput
            parentId={activeEvent.project?.projectCustomerId}
            id={activeEvent.contactId}
            canEdit={canEdit}
            onSelect={(id) => setActiveEvent({ ...activeEvent, contactId: id })}
          />

          {/* Creator */}
          <CreatorItem user={activeEvent.creator} />

          {/* Images */}
          <ImageInput
            images={activeEventImages}
            addImage={(image) => addActiveEventImage(image)}
            deleteImage={(image) => deleteActiveEventImage(image)}
          />

          {/* Save button */}
          {(security.isAdmin || security.isOffice) && (
            <IonButton expand="full" onClick={() => handleSave()}>
              Save
            </IonButton>
          )}
        </IonContent>
      )}
    </IonModal>
  );
};
