import { IonButton, IonIcon } from "@ionic/react";
import { trashBinOutline } from "ionicons/icons";

interface DeleteButtonProps {
  onDelete: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  return (
    <IonButton onClick={() => props.onDelete()}>
      <IonIcon slot="icon-only" icon={trashBinOutline} />
    </IonButton>
  );
};
