import { useState } from "react";
import { Storage } from "aws-amplify";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";

export interface UserPhoto {
  filepath: string;
  file: Photo;
  webviewPath?: string;
}

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [photoToDelete, setPhotoToDelete] = useState<UserPhoto>();

  // Upload image
  const newImage = async () => {
    // Open photo/file app
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    // Load image data
    const response = await fetch(photo.webPath!);
    // Get data
    const blob = await response.blob();
    const fileName = new Date().getTime().toString() + ".jpeg";

    var file = await new File([blob], fileName, { type: blob.type });

    // Add file to photos folder
    const image = await Storage.put("photos/" + fileName, file);

    return image.key;
  };

  const takePhoto = async () => {
    // Open photo app
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });

    // Rename add add photo
    const filePath = new Date().getTime().toString() + ".jpeg";
    const newPhotos = [
      {
        filepath: filePath,
        file: photo,
        webviewPath: photo.webPath,
      },
      ...photos,
    ];
    setPhotos(newPhotos);
  };

  const uploadPhotos = async () => {
    // String to return
    let images: string[] = [];

    try {
      for (let index = 0; index < photos.length; index++) {
        let photo = photos[index];
        // Load image data
        const response = await fetch(photo.file.webPath!);
        // Get data
        const blob = await response.blob();
        // Create a new file for upload
        var file = await new File([blob], photo.filepath, { type: blob.type });

        // Add file to photos folder
        const image = await Storage.put("photos/" + photo.filepath, file);

        // Push image key
        images.push(image.key);
      }

      // Clear state
      setPhotos([]);
    } catch (error) {
      console.log("Error uploading photo: ", error);
    }

    // Retrun keys
    return images;
  };

  const deletePhoto = async (photo: UserPhoto) => {
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter((p) => p.filepath !== photo.filepath);

    setPhotos(newPhotos);
  };

  return {
    takePhoto,
    newImage,
    deletePhoto,
    uploadPhotos,
    photoToDelete,
    setPhotoToDelete,
    photos,
  };
}
