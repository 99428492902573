import { IonItem, IonLabel, IonToggle } from "@ionic/react";

interface ToggleInputProps {
  label: string;
  value: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

export const ToggleInput: React.FC<ToggleInputProps> = (props) => {
  return (
    <IonItem>
      <IonLabel>{props.label}</IonLabel>
      <IonToggle
        slot="end"
        checked={props.value}
        disabled={props.disabled}
        onIonChange={(e) => props.onChange(e.detail.checked)}
      />
    </IonItem>
  );
};
