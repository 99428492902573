import { useEffect, useState } from "react";
import { useAddress, useUser } from ".";
import {
  Address,
  Project,
  Timesheet,
  TimesheetStatus,
  TradeType,
} from "../models";
import { DataStore, Predicates, SortDirection, Storage } from "aws-amplify";
import { formatAWSDate, formatAWSTime } from "../_utils/datetime";

interface TimesheetForm {
  date: Date;
  start: Date;
  end: Date;
  lunch: boolean;
  trade: TradeType;
  details: string;
  photos: (string | null)[];
  status: TimesheetStatus;
  billing_status: TimesheetStatus;
  note: null | string | undefined;
  projectId: null | string | undefined;
}

export function useTimesheet() {
  // Timesheet
  const [activeTimesheetId, setActiveTimesheetID] = useState<string>();
  const [activeTimesheet, setActiveTimesheet] = useState<TimesheetForm>();

  // Images
  const [activeTimesheetImages, setActiveTimesheetImages] =
    useState<string[]>();

  // Timesheet summary
  const timesheetSummary = () => {
    // Timestamps
    const start = new Date(
      activeTimesheet!.date + "T" + activeTimesheet!.start
    );
    const end = new Date(activeTimesheet!.date + "T" + activeTimesheet!.end);

    // Hours
    const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60;

    return { start, end, hours };
  };

  // Get active timesheet
  const loadTimesheet = async () => {
    try {
      // Timesheet details
      let timesheet = await DataStore.query(Timesheet, activeTimesheetId!);

      // Set form state
      setActiveTimesheet({
        date: new Date(timesheet?.date + "T" + timesheet?.start),
        start: new Date(timesheet?.date + "T" + timesheet?.start),
        end: new Date(timesheet?.date + "T" + timesheet?.end),
        lunch: timesheet?.lunch || false,
        trade: timesheet?.trade! as TradeType,
        details: timesheet?.details || "",
        photos: timesheet?.photos || [],
        status: timesheet?.status! as TimesheetStatus,
        billing_status: timesheet?.billing_status as TimesheetStatus,
        note: timesheet?.note,
        projectId: timesheet?.timesheetProjectId,
      });
    } catch (error) {
      console.log("Error loading timesheet: ", error);
    }
  };

  // Load images
  const loadImages = async () => {
    const photos = activeTimesheet!.photos.map(async (photo) => {
      const photoUrl = await Storage.get(photo!);
      return photoUrl;
    });

    Promise.all(photos).then((results) => setActiveTimesheetImages(results));
  };

  // Save Timesheet
  const saveTimesheet = async () => {
    try {
      // Reload
      const original = await DataStore.query(Timesheet, activeTimesheetId!);

      // console.log(original)
      await DataStore.save(
        Timesheet.copyOf(original!, (updated) => {
          updated.date = formatAWSDate(activeTimesheet!.date);
          updated.start = formatAWSTime(activeTimesheet!.start) + ":00.000Z";
          updated.end = formatAWSTime(activeTimesheet!.end) + ":00.000Z";
          updated.lunch = activeTimesheet!.lunch;
          updated.trade = activeTimesheet!.trade;
          updated.details = activeTimesheet!.details;
          updated.photos = activeTimesheet!.photos;
          updated.project = undefined;
          updated.timesheetProjectId = activeTimesheet?.projectId;
          updated.status = activeTimesheet?.status;
          updated.billing_status = activeTimesheet?.billing_status;
        })
      );
    } catch (error) {
      console.log("Error saving timesheet: ", error);
    }
  };

  // Add image
  const addActiveTimesheetImage = async (image: string) => {
    let images = [image];
    activeTimesheet?.photos.map((photo) => {
      if (photo) images.push(photo);
    });
    setActiveTimesheet({ ...activeTimesheet!, photos: images });
  };

  // Remove image
  const deleteActiveTimesheetImage = async (image: string) => {
    try {
      // Get index
      const indexOfImage = activeTimesheetImages?.indexOf(image);

      if (indexOfImage) {
        // Photos array
        const newImages = [...activeTimesheet?.photos!];

        // Remove from storage
        await Storage.remove(newImages[indexOfImage]!);

        // New photo array
        newImages.splice(indexOfImage!, 1);

        // Save timesheet images
        const original = await DataStore.query(Timesheet, activeTimesheetId!);

        // console.log(original)
        await DataStore.save(
          Timesheet.copyOf(original!, (updated) => {
            updated.photos = newImages;
          })
        );

        // Update timeshett
        setActiveTimesheet({ ...activeTimesheet!, photos: newImages });
      }
    } catch (error) {
      console.log("Error removing image: ", error);
    }
  };

  // Trigger to load timesheet data
  useEffect(() => {
    if (activeTimesheetId) loadTimesheet();
  }, [activeTimesheetId]);

  // Trigger to load images
  useEffect(() => {
    if (activeTimesheet?.photos) loadImages();
  }, [activeTimesheet?.photos]);

  return {
    activeTimesheetId,
    setActiveTimesheetID,
    activeTimesheet,
    setActiveTimesheet,
    activeTimesheetImages,
    deleteActiveTimesheetImage,
    saveTimesheet,
    timesheetSummary,
    addActiveTimesheetImage,
  };
}
