import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItemDivider,
  IonList,
  IonListHeader,
  IonReorderGroup,
  IonRow,
  IonText,
  IonTitle,
  ItemReorderEventDetail,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { CreateButton, DocumentItem, DocumentModal, Header } from "../";
import { useDocuments } from "../../hooks";
import { Project } from "../../models";
import { bool } from "aws-sdk/clients/signer";

interface ProjectDocumentsTabInterface {
  project: Project | undefined;
}

export const ProjectDocumentsTab: React.FC<ProjectDocumentsTabInterface> = (
  props
) => {
  // Hook into data
  const {
    setActiveProjectId,
    addDocument,
    activeDocumentId,
    setActiveDocumentId,
    documents,
    documentsApproved,
    documentsUnapproved,
    setReloadState,
    updateOrder,
    updateRanks,
    loadDocuments,
  } = useDocuments();

  // Refresh
  //const [reloadState, setReloadState] = useState(false);

  // Do reorder
  const doReorderApproved = (event: CustomEvent<ItemReorderEventDetail>) => {
    console.log(event.detail.from, event.detail.to);
    event.detail.complete();
    updateOrder('approved', event.detail.from, event.detail.to);
    // event.detail.complete();
  };

  // Do reorder
  const doReorderDocuments = (event: CustomEvent<ItemReorderEventDetail>) => {
    console.log(event.detail.from, event.detail.to);
    event.detail.complete();
    updateOrder('unapproved', event.detail.from, event.detail.to);
    // event.detail.complete();
  };

  useEffect(() => {
    setActiveProjectId(props.project?.id);
  }, [props.project?.id]);

  // useEffect(() => {
  //   console.log(123123);
  //   console.log(reloadState);
  //   if (reloadState) {
  //     setTimeout(() => {
  //       updateRanks().then(() => {
  //         loadDocuments();
  //         setReloadState(false);
  //       });
  //     }, 1000);
      
  //   }
  // }, [reloadState]);

  return (
    <IonContent>
      {/* HEader */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Documents"
        }
      />

      <IonGrid>
        <IonRow>
          {/* Create button */}
          <IonCol size="1" push="11">
            <IonButtons>
              <CreateButton onCreate={() => addDocument()} />
            </IonButtons>
          </IonCol>
        </IonRow>

        {/* <IonItemDivider color="dark"></IonItemDivider> */}

        {/* Approved docs */}
        <IonRow>
          <IonCol>
            <IonList lines="none">
              <IonListHeader>
                <IonTitle color="primary">Approved</IonTitle>
              </IonListHeader>
              <IonReorderGroup disabled={false} onIonItemReorder={doReorderApproved}>
                {documentsApproved
                  ?.map((doc) => (
                    <DocumentItem
                      key={doc.id}
                      document={doc}
                      onEdit={() => setActiveDocumentId(doc.id)}
                    />
                  ))}
              </IonReorderGroup>
            </IonList>
          </IonCol>
        </IonRow>

        <IonItemDivider color="dark"></IonItemDivider>

        {/* Other */}
        <IonRow>
          <IonCol>
            <IonList lines="none">
              <IonListHeader>
                <IonTitle>Documents</IonTitle>
              </IonListHeader>
              <IonReorderGroup disabled={false} onIonItemReorder={doReorderDocuments}>
              {documentsUnapproved
                ?.map((doc) => (
                  <DocumentItem
                    key={doc.id}
                    document={doc}
                    onEdit={() => setActiveDocumentId(doc.id)}
                  />
                ))}
              </IonReorderGroup>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* Modal */}
      <DocumentModal
        docId={activeDocumentId}
        onDismiss={() => {
          console.log({activeDocumentId});
          setActiveDocumentId(undefined);          
          //setReloadState(true);
        }}
      />
    </IonContent>
  );
};
