import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  stopwatchOutline,
  informationCircleOutline,
  attachOutline,
  receiptOutline,
  clipboardOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ProjectRevenueTab,
  ProjectExpensesTab,
  ProjectAccountingTab,
  ProjectDocumentsTab,
  ProjectLabourTab,
  ProjectNotesTab,
  ProjectSummaryTab,
} from "../components";
import { useProject, useProjectFinances, useProjects } from "../hooks";
import { Project as ProjectModel } from "../models";

interface ProjectProps
  extends RouteComponentProps<{
    id: string;
  }> {}

export const Project: React.FC<ProjectProps> = ({ match }) => {
  // Load project
  const {
    activeProject,
    projectTimesheets,
    projectLabour,
    projectRevenue,
    setActiveProjectId,
    projectInvoices,
    loadInvoices,
  } = useProjectFinances();

  // State
  const [activeJobTab, setActiveJobTab] = useState<string>("summary");

  const [refreshTab, setRefreshTag] = useState<number>(0.0);

  const {getProjectById} = useProjects();

  const aGetProjectById = async (id: string) => {
    return await getProjectById(id);
  };

  const [project, setProject] = useState<ProjectModel>();

  // Load data
  useEffect(() => {
    if (match.params.id) {
      setActiveProjectId(match.params.id);
    } else {
      setActiveProjectId(undefined);
    }
  }, [match.params.id, setActiveProjectId]);

  useEffect(() => {
    console.log('testando');
    console.log(activeProject);
    setProject(activeProject);
  }, [activeProject]);

  useIonViewWillEnter(() => {
    console.log('will enter legal: ');
    console.log(match);
    //console.log(project!);
  });

  // Refresh to summeray
  useIonViewDidLeave(() => {
    setActiveJobTab("summary");
    //setActiveProjectId(undefined);
  });

  return (
    <IonPage>
      {/* Summary tab */}
      {activeJobTab === "summary" && project && (
        <ProjectSummaryTab
          project={project}
          labour={projectLabour}
          revenue={projectRevenue}
        />
      )}

      {activeJobTab === "labour" && project && (
        <ProjectLabourTab
          project={project}
          timesheets={projectTimesheets}
          labour={projectLabour}
          onDelete={() => { console.log('123123'); setRefreshTag(Math.random() * 100); }}
        />
      )}
      {activeJobTab === "revenue" && project && (
        <ProjectRevenueTab
          project={project}
          invoices={projectInvoices}
          onRefresh={() => loadInvoices()}
        />
      )}
      {activeJobTab === "expenses" && project && (
        <ProjectExpensesTab
          project={project}
          invoices={projectInvoices}
          onRefresh={() => loadInvoices()}
        />
      )}
      {activeJobTab === "accounting" && project && (
        <ProjectAccountingTab
          project={project}
          invoices={projectInvoices}
          onRefresh={() => loadInvoices()}
        />
      )}
      {activeJobTab === "documents" && project && (
        <ProjectDocumentsTab project={project} />
      )}
      {activeJobTab === "notes" && <ProjectNotesTab project={project} />}

      <IonTabBar
        slot="bottom"
        onIonTabsDidChange={(e) => setActiveJobTab(e.detail.tab)}
        class="no-print"
      >
        <IonTabButton tab="summary" selected={activeJobTab === "summary"}>
          <IonIcon icon={informationCircleOutline} />
          <IonLabel>Summary</IonLabel>
        </IonTabButton>

        <IonTabButton tab="labour" selected={activeJobTab === "labour"}>
          <IonIcon icon={stopwatchOutline} />
          <IonLabel>Labour</IonLabel>
        </IonTabButton>

        {/* <IonTabButton tab="revenue" selected={activeJobTab === "revenue"}>
          <IonIcon icon={receiptOutline} />
          <IonLabel>Revenue</IonLabel>
        </IonTabButton> */}

        {/* <IonTabButton tab="expenses" selected={activeJobTab === "expenses"}>
          <IonIcon icon={receiptOutline} />
          <IonLabel>Expenses</IonLabel>
        </IonTabButton> */}

        <IonTabButton tab="accounting" selected={activeJobTab === "accounting"}>
          <IonIcon icon={receiptOutline} />
          <IonLabel>Accounting</IonLabel>
        </IonTabButton>

        <IonTabButton tab="documents" selected={activeJobTab === "documents"}>
          <IonIcon icon={attachOutline} />
          <IonLabel>Documents</IonLabel>
        </IonTabButton>

        <IonTabButton tab="notes" selected={activeJobTab === "notes"}>
          <IonIcon icon={clipboardOutline} />
          <IonLabel>Notes</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonPage>
  );
};
