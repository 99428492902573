import { createContext, FC, useState } from "react";

export enum DisplayLayout {
  GRID = "Grid",
  TABLE = "Table",
}

interface ThemeContextProps {
  layout: DisplayLayout;
  toggleLayout: () => void;
}

const defaultThemeState = {
  layout: DisplayLayout.TABLE,
  toggleLayout: () => {},
};

export const ThemeContext = createContext<ThemeContextProps>(defaultThemeState);

export const ThemeProvider: FC = ({ children }) => {
  // Layout state
  const [layout, setLayout] = useState(defaultThemeState.layout);

  // Toggle between grid and table
  const toggleLayout = () => {
    layout === DisplayLayout.GRID
      ? setLayout(DisplayLayout.TABLE)
      : setLayout(DisplayLayout.GRID);
  };

  return (
    <ThemeContext.Provider
      value={{
        layout,
        toggleLayout,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
