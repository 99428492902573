import {
  IonList,
  IonListHeader,
  IonText,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { PhoneButton } from "../";
import { AuthUser } from "../../models";

interface CreatorItemProps {
  user: AuthUser | undefined;
}

export const CreatorItem: React.FC<CreatorItemProps> = (props) => {
  return (
    <>
      {props.user && (
        <IonList>
          <IonListHeader>
            <IonText color="medium">Created By</IonText>
          </IonListHeader>

          <IonItem>
            <IonLabel>{props.user.username}</IonLabel>
            <IonText slot="end">
              <PhoneButton tel={props.user.cell} />
            </IonText>
          </IonItem>
        </IonList>
      )}
    </>
  );
};
