import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonDatetime,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonText,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import { useTimeOff } from "../../hooks";
import { formatIonDateTimeString } from "../../_utils/datetime";

interface TimeOffCardProps {}

export const TimeOffCard: React.FC<TimeOffCardProps> = (props) => {
  // Form data state
  const { timeOffForm, setTimeOffForm, requestMade, saveTimeOff } =
    useTimeOff();

  // Save date
  const handleFormSubmit = () => {
    saveTimeOff();
  };

  return (
    <IonCard style={{ height: "100%" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        {/* Header section */}
        <IonCardHeader>
          <IonToolbar>
            <IonCardSubtitle>PERSONAL DAY</IonCardSubtitle>
            <IonButton slot="end" type="submit" disabled={requestMade}>
              REQUEST
            </IonButton>
          </IonToolbar>
        </IonCardHeader>

        {/* Form */}
        <IonCardContent>
          <IonList>
            {/* Start input */}
            <IonItem id="open-start-input" button>
              <IonLabel>From</IonLabel>
              <IonText slot="end">{timeOffForm?.start.toDateString()}</IonText>
              <IonPopover
                trigger="open-start-input"
                dismiss-on-select="true"
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(timeOffForm?.start!)}
                  onIonChange={(e) => {
                    setTimeOffForm({
                      ...timeOffForm!,
                      start: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            {/* To */}
            <IonItem id="open-end-input" button>
              <IonLabel>To</IonLabel>
              <IonText slot="end">{timeOffForm?.end.toDateString()}</IonText>
              <IonPopover
                trigger="open-end-input"
                dismiss-on-select="true"
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(timeOffForm?.end!)}
                  onIonChange={(e) => {
                    setTimeOffForm({
                      ...timeOffForm!,
                      end: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            {/* Details */}
            <IonItem lines="none">
              <IonTextarea
                placeholder="Details"
                rows={2}
                value={timeOffForm!.details}
                onIonChange={(e) => {
                  setTimeOffForm({
                    ...timeOffForm!,
                    details: e.detail.value as string,
                  });
                }}
              ></IonTextarea>
            </IonItem>
          </IonList>
          {requestMade && (
            <IonText color="primary">*Request has been made</IonText>
          )}
        </IonCardContent>
      </form>
    </IonCard>
  );
};
