import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";

interface StringInputProps {
  label: null | string | undefined;
  value: null | string | undefined;
  canEdit?: boolean;
  onChange: (value: string) => void;
  error?: { error: boolean | undefined; msg: string };
}

export const StringInput: React.FC<StringInputProps> = (props) => {
  return (
    <IonItem>
      {props.label && <IonLabel position="stacked">{props.label}</IonLabel>}
      <IonInput
        type="text"
        value={props.value}
        disabled={props.canEdit ? !props.canEdit : true}
        onIonChange={(e) => props.onChange(e.detail.value as string)}
      />
      {props.error?.error && (
        <IonText color="danger">{props.error.msg}</IonText>
      )}
    </IonItem>
  );
};
