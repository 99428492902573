// Frameworks
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";

// Data
import { useUser } from "../../hooks";

interface UserCardProps {}

export const UserCard: React.FC<UserCardProps> = (props) => {
  const { username, email } = useUser();

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>User Account Information</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Username</IonLabel>
            <IonInput disabled>{username}</IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput disabled>{email}</IonInput>
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

/** 13 */