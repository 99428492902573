import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonList,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { ProjectRevenue } from "../../hooks";

interface CashflowSummaryCardProps {
  revenue: ProjectRevenue | undefined;
}

export const CashflowSummaryCard: React.FC<CashflowSummaryCardProps> = (
  props
) => {
  // Update revenue color
  const [revenuColor, setRevenueColor] = useState("danger");

  useEffect(() => {
    if (props.revenue)
      props.revenue?.total > 0
        ? setRevenueColor("success")
        : props.revenue?.total == 0 ? setRevenueColor('white') : setRevenueColor("danger");
  }, [props.revenue?.total]);
  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        <IonCardTitle>Profit / Loss</IonCardTitle>
      </IonCardHeader>
      {props.revenue && (
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonTitle>$ {props.revenue?.income.toFixed(2)}</IonTitle>
              <IonText slot="end">Income</IonText>
            </IonItem>
            <IonItem>
              <IonTitle color="medium">
                ${" "}
                {(
                  props.revenue?.misc +
                  props.revenue?.subcontractor +
                  props.revenue?.vendor
                ).toFixed(2)}
              </IonTitle>
              <IonText slot="end" color="medium">
                Expenses
              </IonText>
            </IonItem>
            <IonItem>
              <IonTitle color={revenuColor}>
                $ {props.revenue.total.toFixed(2)}
              </IonTitle>
              <IonText slot="end" color={revenuColor}>
                Revenue
              </IonText>
            </IonItem>
          </IonList>
        </IonCardContent>
      )}
    </IonCard>
  );
};
