import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useUser } from ".";
import { NoteStatus, Project } from "../models";
import { Note } from "../models";

export function useNotes() {
  // Get user data
  const { authUser } = useUser();

  // State
  const [activeProjectId, setActiveProjectId] = useState<string>();
  const [projectNoteId, setProjectNoteId] = useState<string>();
  const [activeProjectNotes, setActiveProjectNotes] =
    useState<(Note | undefined)[]>();

  // Refreash
  const [reloadState, setReloadState] = useState(false);

  // Load project notes
  const loadProjectNotes = async () => {
    try {
      if (activeProjectId) {
        // Get list of id
        let project = await DataStore.query(Project, activeProjectId);
        let noteIds = project?.noteIds;
        // Load in data

        if (noteIds) {
          // GEt data
          let data = await DataStore.query(Note, (c) =>
            c.or((c) => noteIds!.reduce((c, id) => c.id("eq", id!), c))
          );

          if (data.length > 0) {
            // Order
            // let orderedNotes = await noteIds.map((noteId) => {
            //   let note = data.find((note) => note.id === noteId);
            //   if (note) return note;
            // }).reverse();
            let orderedNotes = await noteIds.map((noteId) => {
              let note = data.find((note) => note.id === noteId);
              if (note) return note;
            });

            //console.log(noteIds);

            setActiveProjectNotes(orderedNotes);
          } else {
            setActiveProjectNotes(undefined);
          }
        } else {
          setActiveProjectNotes(undefined);
        }
      } else {
        setActiveProjectNotes(undefined);
      }
    } catch (error) {
      console.log("Error loading project notes: ", error);
    }
  };

  // Create note
  const createNote = async () => {
    try {
      // Create new ID
      let newNote = await DataStore.save(
        new Note({ name: authUser?.username, status: NoteStatus.OPEN })
      );

      // Get list of id
      let project = await DataStore.query(Project, activeProjectId!);
      let noteIds = project?.noteIds;

      let ids = noteIds ? [newNote.id].concat(Array.from(noteIds) as string[]) : [];
      //let ids = noteIds ? noteIds.concat([newNote.id]) : [newNote.id];

      // Update project id
      await DataStore.save(
        Project.copyOf(project!, (updated) => {
          updated.noteIds = ids;
        })
      );

      // Open state
      setProjectNoteId(newNote.id);
      loadProjectNotes();
    } catch (error) {
      console.log("Error creating note: ", error);
    }
  };

  // Update order
  const updateOrder = async (from: number, to: number) => {
    try {
      // Load project data
      let original = await DataStore.query(Project, activeProjectId!);
      console.log(original?.noteIds);
      // Update
      if (original?.noteIds) {
        // Copy elements
        let tempIDs = [...original?.noteIds];
        let tempID = tempIDs![from];

        // Remove element from array
        tempIDs?.splice(from, 1);

        // Replace item
        tempIDs?.splice(to, 0, tempID);
        console.log(tempIDs);
        await DataStore.save(
          Project.copyOf(original!, (updated) => {
            updated.noteIds = tempIDs;
          })
        );

        loadProjectNotes();
      }
    } catch (error) {
      console.log("Error fixing note order: ", error);
    }
  };

  const deleteNoteById = async (id: string) => {
    try {
      // Get project
      let original = await DataStore.query(Note, id);

      // remove all
      if (original) {
        await DataStore.delete(original);
      }
    } catch (error) {
      console.log("Error deleting project");
    }
  };

  // Trigger refresh
  useEffect(() => {
    if (reloadState) {
      loadProjectNotes();
      setReloadState(false);
    }
  }, [reloadState]);

  // Trigger loading
  useEffect(() => {
    if (activeProjectId) loadProjectNotes();
  }, [activeProjectId]);

  return {
    setActiveProjectId,
    activeProjectNotes,
    setReloadState,
    createNote,
    projectNoteId,
    setProjectNoteId,
    updateOrder,
    deleteNoteById
  };
}
