import {
  IonList,
  IonListHeader,
  IonText,
  IonItem,
  IonIcon,
  IonPopover,
  IonSearchbar,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonButton,
  IonInput,
} from "@ionic/react";
import {
  addCircleOutline,
  closeCircleOutline,
  createOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { ProjectLabel } from "..";
import { useProjects } from "../../hooks";

interface ProjectSplitInputProps {
  id: null | string | undefined;
  canEdit: boolean;
  partition: number | undefined;
  projectIds: (string | null)[] | null | undefined;
  projectAllocations: (number | null)[] | null | undefined;
  onSelect: (id: string) => void;
  percentage: (amount: number) => void;
  onAdd: () => void;
  onRemove: (index: number) => void;
  updateAllocation: (index: number, amount: number) => void;
}

export const ProjectSplitInput: React.FC<ProjectSplitInputProps> = (props) => {
  const { setProjectKeyword, queriedProjects } = useProjects();
  // Open state, close on
  const [isOpen, setIsOpen] = useState(false);

  const openPopover = () => {
    setIsOpen(true);
  };

  return (
    <IonList>
      {props.canEdit && (
        <IonListHeader>
          <IonText color="medium">Jobs</IonText>

          {props.canEdit && (
            <>
              {/* Open search */}
              <IonButton onClick={openPopover}>
                <IonIcon color="primary" icon={addCircleOutline} />
              </IonButton>

              {/* Search popove */}
              <IonPopover
                showBackdrop={false}
                onDidDismiss={() => setIsOpen(false)}
                isOpen={isOpen}
                reference="event"
              >
                <IonSearchbar
                  onIonChange={(e) =>
                    setProjectKeyword(e.detail.value as string)
                  }
                />
                <IonRadioGroup
                  onIonChange={(e) => {
                    props.onSelect(e.detail.value);
                    setIsOpen(false);
                  }}
                  value={props.id}
                >
                  {queriedProjects?.map((project) => {
                    return (
                      <IonItem key={project.id}>
                        <IonLabel>
                          {"" +
                            project.code?.toUpperCase() +
                            project?.number?.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}
                        </IonLabel>
                        <IonRadio slot="start" value={project.id} />
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
              </IonPopover>
            </>
          )}
        </IonListHeader>
      )}

      {props.id &&
        queriedProjects?.map((project) => {
          if (project.id === props.id)
            return (
              <IonItem key={project.id}>
                <IonLabel>
                  {"" +
                    project?.code?.toUpperCase() +
                    project?.number?.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    }) +
                    ": $"}
                </IonLabel>
                <IonInput
                  type="number"
                  value={props.partition}
                  onIonChange={(e) => props.percentage(Number(e.detail.value))}
                />
                <IonButton
                  disabled={!props.id || !props.partition}
                  onClick={() => props.onAdd()}
                >
                  <IonIcon
                    icon={addCircleOutline}
                    onClick={() => props.onAdd()}
                  />
                </IonButton>
              </IonItem>
            );
        })}

      {/* Display a list of jobs and button to remove allocation */}
      {props.projectIds &&
        props.projectIds.map((id, index) => {
          let project = queriedProjects?.find((project) => project.id === id);
          if (project) {
            return (
              <IonItem key={project.id}>
                {/* Simple label of PO */}
                <ProjectLabel project={project} /> {": $"}
                {/* Change of allocation */}
                <IonInput
                  type="number"
                  value={props.projectAllocations![index]}
                  onIonChange={(e) =>
                    props.updateAllocation(index, Number(e.detail.value))
                  }
                />
                {/* Remove project  */}
                <IonButton color="danger" onClick={() => props.onRemove(index)}>
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonItem>
            );
          }
        })}
    </IonList>
  );
};
