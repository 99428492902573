import {
  IonButton,
  IonIcon,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { eyeOutline, refreshCircle } from "ionicons/icons";
import { AuthUser } from "../../models";
import { DeleteButton } from "../Buttons";
import { useUser } from "../../hooks";

interface UserItemProps {
  employee: AuthUser;
  onView: () => void;
  onDelete?: () => void;
  onChangePasswordClick? : () => void;
}

export const UserItem: React.FC<UserItemProps> = (props) => {
  const onDelete = props.onDelete === null || props.onDelete === undefined ? null : props.onDelete;
  const onChangePasswordClick = props.onChangePasswordClick === null || props.onChangePasswordClick === undefined ? null : props.onChangePasswordClick;
  const { security } = useUser();
  return (
    <IonItem key={props.employee.id}>
      <IonGrid>
        <IonRow>
          <IonCol>{props.employee.username}</IonCol>
          <IonCol>
            <a href={"tel:" + props.employee.cell}>{props.employee.cell}</a>
          </IonCol>
          <IonCol>
            <a href={"mailto:" + props.employee.email}>
              {props.employee.email}
            </a>
          </IonCol>
          <IonCol className="ion-text-right">
            {security.isAdmin && onChangePasswordClick !== null &&
              <IonButton onClick={() => onChangePasswordClick()}>
                <IonIcon slot="icon-only" icon={refreshCircle} />
              </IonButton>
            }
            <IonButton onClick={() => props.onView()}>
              <IonIcon slot="icon-only" icon={eyeOutline} />
            </IonButton>
            {security.isAdmin && onDelete !== null &&
              <DeleteButton onDelete={() => onDelete()} />
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
