import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import { useEmployees } from "../../hooks";
import { PhoneButton } from "../Buttons";

interface CoordinatorInputProps {
  id: null | string | undefined;
  canEdit: boolean;
  onSelect: (id: string) => void;
}

export const CoordinatorInput: React.FC<CoordinatorInputProps> = (props) => {
  const { officeEmployees } = useEmployees();

  return (
    <IonList>
      <IonListHeader>
        <IonText color="medium">CMAC Coordinator</IonText>

        <IonItem id="open-coordinator-input" button disabled={!props.canEdit}>
          {props.canEdit && <IonIcon color="primary" icon={createOutline} />}
          <IonPopover
            trigger="open-coordinator-input"
            dismiss-on-select="true"
            showBackdrop={false}
          >
            <IonRadioGroup
              onIonChange={(e) => props.onSelect(e.detail.value)}
              value={props.id}
            >
              {officeEmployees?.map((employee) => {
                return (
                  <IonItem key={employee.id}>
                    <IonLabel> {employee.username}</IonLabel>
                    <IonRadio slot="start" value={employee.id} />
                  </IonItem>
                );
              })}
            </IonRadioGroup>
          </IonPopover>
        </IonItem>
      </IonListHeader>

      {props.id &&
        officeEmployees?.map((employee, index) => {
          if (employee.id === props.id) {
            return (
              <IonItem key={index}>
                <IonLabel>{employee.username}</IonLabel>
                <IonText slot="end">
                  <PhoneButton tel={employee.cell} />
                </IonText>
              </IonItem>
            );
          }
        })}
    </IonList>
  );
};
