import {
  IonItem,
  IonLabel,
  IonInput,
  IonPopover,
  IonDatetime,
} from "@ionic/react";
import { formatIonDateTimeString } from "../../_utils/datetime";

interface DateInputProps {
  value: Date;
  disabled: boolean;
  onChange: (value: Date) => void;
}

export const DateTimeInput: React.FC<DateInputProps> = (props) => {
  return (
    <IonItem id="open-date-input" button disabled={props.disabled}>
      <IonLabel position="stacked">Date</IonLabel>
      <IonInput
        readonly
        value={props.value.toLocaleString("en-us", {
          weekday: "long",
          month: "short",
          day: "numeric",
        })}
      />
      <IonPopover trigger="open-date-input" showBackdrop={false}>
        <IonDatetime
          presentation="time-date"
          hourValues={[]}
          minuteValues={[]}
          value={formatIonDateTimeString(props.value)}
          onIonChange={(e) => {
            console.log(e);
            props.onChange(new Date(e.detail.value as string));
          }}
        />
      </IonPopover>
    </IonItem>
  );
};
