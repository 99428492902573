import { IonItem, IonLabel, IonTextarea } from "@ionic/react";

interface TextAreaInputProps {
  lable?: string;
  value: null | string | undefined;
  readonly: boolean;
  onChange: (value: string) => void;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = (props) => {
  return (
    <IonItem lines="none">
      {props.lable && <IonLabel position="stacked">{props.lable}</IonLabel>}
      <IonTextarea
        placeholder="Description"
        disabled={props.readonly}
        rows={4}
        value={props.value}
        onIonChange={(e) => props.onChange(e.detail.value as string)}
      ></IonTextarea>
    </IonItem>
  );
};
