import { IonApp, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ThemeProvider, UserProvider } from "./context";

import { Menu, Routes } from "./components";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* AWS authentication */
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

Amplify.configure(awsExports);

const App = () => {
  return (
    <UserProvider>
      <ThemeProvider>
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Menu />
              <Routes />
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </ThemeProvider>
    </UserProvider>
  );
};

export default withAuthenticator(App);

/** 1 */