import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useUser } from ".";
import { Event } from "../models";
import { formatAWSDateTime } from "../_utils/datetime";

export function useEvents() {
  // Logged in user
  const { security, authUser } = useUser();

  // state
  const [reloadState, setReloadState] = useState(false);

  // Event loaded
  const [events, setEvents] = useState<Event[]>();
  const [activeEventId, setActiveEventId] = useState<string>();

  // Get a list of events
  const loadEvents = async () => {
    try {
      // Blank array
      let data: Event[] = [];

      if (security.isField) {
        // Load emplyee events
        data = await DataStore.query(Event);
        data = data.filter((event) =>
          event.employeeIds?.includes(authUser!.id)
        );
        // Todo - add range by start and end month
      } else if (security.isAdmin || security.isOffice) {
        // Load office peoples
        data = await DataStore.query(Event, (c) => c.deleted("ne", true));
      }

      // Reload
      setEvents(data);
    } catch (error) {
      console.log("Error loading events: ", error);
    }
  };

  // Create new event
  const createEvent = async () => {
    try {
      // Create start date
      let startDate = new Date();
      startDate.setHours(14); // Set to am that day
      startDate.setMinutes(0);

      let start = formatAWSDateTime(startDate);

      // Create new event
      let newEvent = await DataStore.save(
        new Event({
          start: start,
          title: "New Work Order",
          color: "#69bb7b",
          createdBy: authUser,
          employeeIds: [],
        })
      );

      // Reload shit
      loadEvents();
      setActiveEventId(newEvent.id);
    } catch (error) {
      console.log("Error creating event: ", error);
    }
  };

  // Delete event
  const deleteEvent = async () => {
    try {
      if (activeEventId) {
        const eventToDelete = await DataStore.query(Event, activeEventId);
        await DataStore.save(
          Event.copyOf(eventToDelete!, (updated) => {
            updated.deleted = true;
          })
        );
      }

      loadEvents();
    } catch (error) {
      console.log("Error deleting event: ", error);
    }
  };

  // Load event
  useEffect(() => {
    loadEvents();
  }, [activeEventId]);

  // Reload state
  useEffect(() => {
    if (reloadState) {
      loadEvents();
      setReloadState(false);
    }
  }, [reloadState]);

  // Load user's event
  useEffect(() => {
    if (authUser) loadEvents();
  }, [authUser]);

  return {
    activeEventId,
    createEvent,
    events,
    setActiveEventId,
    deleteEvent,
    setReloadState,
  };
}
