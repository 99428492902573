import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, pencilOutline, personOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useEmployees, useOnCall, useTimeOff } from "../../hooks";
import { formatIonDateTimeString } from "../../_utils/datetime";
import { TimeOffStatus } from "../../models";

interface TimeOffModalInterface {
  timeOffId: null | string | undefined;
  onDismiss: () => void;
}

export const TimeOffModal: React.FC<TimeOffModalInterface> = (props) => {
  // State
  const [showModal, setShowModal] = useState(false);

  // Hook together that shit
  const {
    timeOffForm,
    setActiveTimeoffId,
    setTimeOffForm,
    saveTimeOff,
    deleteTimeoff,
  } = useTimeOff();

  const { fieldEmployees } = useEmployees();

  // Handle save
  const handleSave = async () => {
    await saveTimeOff();
    setShowModal(false);
    props.onDismiss();
  };

  // Handle deletion
  const handleDelete = async () => {
    await deleteTimeoff();
    setShowModal(false);
    props.onDismiss();
  };

  // Auto open
  useEffect(() => {
    if (props.timeOffId) {
      setActiveTimeoffId(props.timeOffId);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.timeOffId]);

  // Modal
  return (
    <IonModal isOpen={showModal} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar */}
      <IonToolbar>
        {/* Modal title */}
        <IonTitle>
          TimeOff: {timeOffForm?.employee?.username?.toLocaleUpperCase()}
        </IonTitle>

        {/* Close modal */}
        <IonButtons slot="end">
          <IonButton onClick={() => props.onDismiss()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>

      {/* Oncall details */}
      <IonContent>
        {timeOffForm && (
          <IonList lines="none">
            {/* Header section */}
            <IonListHeader>
              <IonText color="medium">Details</IonText>
            </IonListHeader>

            {/* Assignement */}
            <IonItem id="open-employee-edit" button>
              <IonLabel>Employee</IonLabel>
              <IonText>
                {timeOffForm.employee?.username?.toLocaleUpperCase()}
              </IonText>

              {/* List out employees*/}
              <IonPopover
                trigger="open-employee-edit"
                showBackdrop={false}
                dismiss-on-select="true"
              >
                {fieldEmployees
                  ?.filter(
                    (employee) =>
                      !timeOffForm?.employee?.id.includes(employee.id)
                  )
                  .map((employee) => {
                    return (
                      <IonItem
                        key={employee.id}
                        button
                        onClick={() =>
                          setTimeOffForm({
                            ...timeOffForm!,
                            employee: employee,
                          })
                        }
                      >
                        <IonLabel>
                          {employee.username?.toLocaleUpperCase()}
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonPopover>
            </IonItem>

            {/* Start input */}
            <IonItem id="open-start-input" button>
              <IonLabel>From</IonLabel>
              <IonText slot="end">
                {timeOffForm?.start.toDateString()}
              </IonText>
              <IonPopover
                trigger="open-start-input"
                dismiss-on-select={false}
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(timeOffForm?.start!)}
                  onIonChange={(e) => {
                    setTimeOffForm({
                      ...timeOffForm!,
                      start: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            {/* To */}
            <IonItem id="open-end-input" button>
              <IonLabel>To</IonLabel>
              <IonText slot="end">
                {timeOffForm?.end.toDateString()}
              </IonText>
              <IonPopover
                trigger="open-end-input"
                dismiss-on-select={false}
                showBackdrop={false}
              >
                <IonDatetime
                  presentation="date"
                  value={formatIonDateTimeString(timeOffForm?.end!)}
                  onIonChange={(e) => {
                    setTimeOffForm({
                      ...timeOffForm!,
                      end: new Date(e.detail.value!),
                    });
                  }}
                ></IonDatetime>
              </IonPopover>
            </IonItem>

            <IonItem id="open-status-input" button>
              <IonLabel>
                Status
              </IonLabel>
              <IonSelect value={timeOffForm.status} onIonChange={(e) => {
                setTimeOffForm({
                  ...timeOffForm!,
                  status: e.detail.value
                });
              }}>
                <IonSelectOption value={TimeOffStatus.REQUESTED}>{TimeOffStatus.REQUESTED}</IonSelectOption>
                <IonSelectOption value={TimeOffStatus.APPROVED}>{TimeOffStatus.APPROVED}</IonSelectOption>
                <IonSelectOption value={TimeOffStatus.DENIED}>{TimeOffStatus.DENIED}</IonSelectOption>
              </IonSelect>
            </IonItem>

            {/* Details */}
            <IonItem lines="none">
              <IonLabel position="stacked" color="medium">
                Details
              </IonLabel>
              <IonTextarea
                placeholder="Enter details here..."
                rows={8}
                value={timeOffForm!.details}
                onIonChange={(e) => {
                  setTimeOffForm({
                    ...timeOffForm!,
                    details: e.detail.value as string,
                  });
                }}
              ></IonTextarea>
            </IonItem>
          </IonList>
        )}

        {/* Handle save */}
        <IonButton expand="block" onClick={() => handleSave()}>
          Save
        </IonButton>
        <IonButton expand="block" color="danger" onClick={() => handleDelete()}>
          Delete
        </IonButton>
      </IonContent>
    </IonModal>
  );
};
