import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, trashBinOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAddress, useProject, useUser } from "../../hooks";
import {
  BuilderInput,
  StringInput,
  NumberInput,
  AddressInput,
  SelectInput,
  SupervisorInput,
} from "..";
import { ProjectStatus } from "../../models";

interface ProjectModalProps {
  projectId: null | string | undefined;
  newProject: boolean;
  onDelete: () => void;
  onDismiss: () => void;
  onSave?: () => void;
}

export const ProjectModal: React.FC<ProjectModalProps> = (props) => {
  // User
  const { security } = useUser();
  const [canEdit, setCanEdit] = useState(false);

  const [activeAddress, setActiveAddress] = useState<any>({address1: '', address2: ''});

  // Modal state
  const [isOpen, setIsOpen] = useState(false);

  // Saveing state
  const [updatingAddress, setUpdatingAddress] = useState(false);

  // Project
  const {
    activeProject,
    checkPO,
    setActiveProjectId,
    setActiveProject,
    updateBuilder,
    saveProject,
    errors,
    setPO,
  } = useProject();

  // Hand close
  const handleClose = async () => {
    props.newProject ? props.onDelete() : props.onDismiss();
  };
  // Handle update
  const handleSaveClick = async () => {
    activeProject?.addressId === "new"
      ? setUpdatingAddress(true)
      : onProjectSave();
  };

  // Save
  const onProjectSave = async () => {
    await saveProject();
    setUpdatingAddress(false);
    props.onDismiss();
    console.log(activeProject);
    if (props.onSave) { props.onSave(); }
  };

  // Wait for address to update
  useEffect(() => {
    if (updatingAddress && activeProject?.addressId !== "new") onProjectSave();
  }, [updatingAddress, activeProject?.addressId]);

  // Open if active id
  useEffect(() => {
    setActiveProjectId(props.projectId !== null ? props.projectId : undefined);
    if (props.projectId) {
      setIsOpen(true);
    } else {
      setActiveProject(undefined);
      setIsOpen(false);
    }
  }, [props.projectId]);

  // Securtiy
  useEffect(() => {
    security.isAdmin || security.isOffice
      ? setCanEdit(true)
      : setCanEdit(false);
  }, [security]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => handleClose()}>
      {/* Toolbar */}
      <IonToolbar>
        <IonTitle>Job Details</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={() => props.onDelete()}>
            <IonIcon slot="icon-only" icon={trashBinOutline} />
          </IonButton>
          <IonButton onClick={() => handleClose()}>
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>

      {activeProject && (
        <IonContent>
          {/* Buidler input */}
          <BuilderInput
            id={activeProject.customerId}
            onChange={(idvalue) => updateBuilder(idvalue)}
          />

          {/* Code */}
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <StringInput
                  label="PO *"
                  value={activeProject.code}
                  canEdit={canEdit}
                  onChange={(value) => {
                    setActiveProject({ ...activeProject!, code: value });
                    setPO();
                  }}
                  // error={{
                  //   error: errors?.code,
                  //   msg: "PO is required",
                  // }}
                />
              </IonCol>
              <IonCol>
                <NumberInput
                  label=""
                  value={activeProject.number}
                  onChange={(value) => {
                    if (value !== activeProject.number) checkPO(value);
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* Description */}
          <StringInput
            label="Note"
            value={activeProject.description}
            canEdit={canEdit}
            onChange={(value) =>
              setActiveProject({ ...activeProject, description: value })
            }
          />

          {/* Supervisor input */}
          <SupervisorInput
            parentId={activeProject.customerId}
            id={activeProject.supervisorId}
            canEdit={canEdit}
            onSelect={(id) =>
              setActiveProject({ ...activeProject, supervisorId: id })
            }
          />

          {/* Status */}
          <SelectInput
            label="Status"
            value={activeProject.status}
            options={["OPEN", "ARCHIVED"]}
            canEdit={canEdit}
            onChange={(value) =>
              setActiveProject({ ...activeProject, status: value })
            }
          />

          {/* Address */}
          <AddressInput
            id={activeProject.addressId !== null ? activeProject.addressId : undefined}
            saveAddress={updatingAddress}
            canEdit={canEdit}
            onChange={(id) =>
              setActiveProject({ ...activeProject, addressId: id })
            }
            onChangeActiveAddress={(addr1, addr2) => {
              setActiveAddress({'address1': addr1, 'address2': addr2});
            }}
          />
          {errors?.data?.length !== undefined && (activeProject.addressId === 'new' && (['', null, undefined].includes(activeAddress?.address1) || ['', null, undefined].includes(activeAddress?.address2))) && errors?.data?.length > 0 && !errors?.data?.includes('ADDRESS') &&
            <IonText color="danger">{errors?.message}, ADDRESS</IonText>
          }
          {errors?.data?.length !== undefined && errors?.data?.length > 0 && (activeProject.addressId === 'new' && (['', null, undefined].includes(activeAddress?.address1) || ['', null, undefined].includes(activeAddress?.address2))) && errors?.data?.includes('ADDRESS') &&
            <IonText color="danger">{errors?.message}</IonText>
          }
          {errors?.data?.length === undefined && (activeProject.addressId === 'new' && (['', null, undefined].includes(activeAddress?.address1) || ['', null, undefined].includes(activeAddress?.address2))) &&
            <IonText color="danger">"Missing values for: ADDRESS"</IonText>
          }
          {/* Save */}
          <IonButton
            disabled={errors?.code || (activeProject.addressId === 'new' && (['', null, undefined].includes(activeAddress?.address1) ))}
            onClick={() => handleSaveClick()}
            expand="block"
          >
            Save
          </IonButton>
        </IonContent>
      )}
    </IonModal>
  );
};
