import { IonItem, IonIcon, IonLabel, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";

interface FinancialLineItemProps {
  label: string;
  color: string;
  value: number;
}
export const FinancialLineItem: React.FC<FinancialLineItemProps> = (props) => {
  return (
    <IonItem>
      <IonIcon slot="start" color={props.color} icon={ellipse}></IonIcon>
      <IonLabel>{props.label}</IonLabel>
      <IonText slot="end">{props.value.toFixed(2)}</IonText>
    </IonItem>
  );
};
