// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Expenses = {
  "VENDOR": "VENDOR",
  "SUBCONTRACTOR": "SUBCONTRACTOR",
  "MISCELLANEOUS": "MISCELLANEOUS"
};

const Accounts = {
  "INCOME": "INCOME",
  "EXPENSES": "EXPENSES"
};

const TimeOffStatus = {
  "REQUESTED": "REQUESTED",
  "APPROVED": "APPROVED",
  "DENIED": "DENIED"
};

const TimesheetStatus = {
  "UNAPPROVED": "UNAPPROVED",
  "REJECTED": "REJECTED",
  "APPROVED": "APPROVED",
  "BILLED": "BILLED",
  "UNBILLED": "UNBILLED"
};

const SecurityGroups = {
  "ADMIN": "ADMIN",
  "OFFICE": "OFFICE",
  "FIELD": "FIELD"
};

const TradeType = {
  "HVAC": "HVAC",
  "PLUMBING": "PLUMBING"
};

const ProjectStatus = {
  "OPEN": "OPEN",
  "CLOSED": "CLOSED",
  "ARCHIVED": "ARCHIVED",
  "QUOTED": "QUOTED"
};

const NoteStatus = {
  "OPEN": "OPEN",
  "CLOSED": "CLOSED"
};

const ContactType = {
  "SUPERVISOR": "SUPERVISOR",
  "BUILDER": "BUILDER"
};

const { Document, Invoice, AuthUser, Event, TimeOff, OnCall, Contact, Timesheet, Address, Project, Note } = initSchema(schema);

export {
  Document,
  Invoice,
  AuthUser,
  Event,
  TimeOff,
  OnCall,
  Contact,
  Timesheet,
  Address,
  Project,
  Note,
  Expenses,
  Accounts,
  TimeOffStatus,
  TimesheetStatus,
  SecurityGroups,
  TradeType,
  ProjectStatus,
  NoteStatus,
  ContactType
};