import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Contact, ContactType } from "../models";

export function useBuilders() {
  // State
  const [builders, setBuilders] = useState<Contact[]>();
  const [quedBuilders, setQuedBuilders] = useState<Contact[]>();

  // Query
  const [keyword, setKeyword] = useState<null | string | undefined>();

  // load contacnts of type builders
  const loadBuilders = async () => {
    try {
      // Get data
      let data = await DataStore.query(Contact, (c) =>
        c.type("eq", ContactType.BUILDER)
      );

      // Store data
      setBuilders(data);
    } catch (error) {
      console.log("Error loading builders: ", error);
    }
  };

  // If keyword changed
  const queryBuilder = async () => {
    try {
      let keywordRegex = new RegExp(keyword || "", "i");
      let qBuilders = builders?.filter((builder) => {
        // Filter based on input of keyword
        if (builder.name?.match(keywordRegex)) return true;
        if (builder.email?.match(keywordRegex)) return true;
        if (builder.code?.match(keywordRegex)) return true;

        // Return false if doesn't exist since trying to search
        return false;
      });
      // Update state
      setQuedBuilders(qBuilders);
    } catch (error) {
      console.log("Error querying builders: ", error);
    }
  };

  // Trigger query on load
  useEffect(() => {
    queryBuilder();
  }, [builders, keyword]);

  // Load builders
  useEffect(() => {
    loadBuilders();
  }, []);

  // Return stuff
  return { builders, quedBuilders, setKeyword };
}
