import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemOption,
  IonList,
  IonListHeader,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from "@ionic/react";
import {
  CreateButton,
  Header,
  ExpenseItem,
  InvoiceModal,
  RevenueItem,
  RevenueListHeader,
  ExpensesListHeader,
  RevenueCreateCard,
  ExpenseCreateCard,
} from "..";
import { useInvoices, useProjectFinances } from "../../hooks";
import { Accounts, Expenses, Invoice, Project, Timesheet } from "../../models";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";

interface ProjectAccountingTabInterface {
  project: Project | undefined;
  invoices: Invoice[] | undefined;
  onRefresh: () => void;
}

export const ProjectAccountingTab: React.FC<ProjectAccountingTabInterface> = (
  props
) => {
  const { setActiveInvoiceId, activeInvoiceId, createInvoice } = useInvoices();
  const [ typeFilter, setTypeFilter ] = useState('');

  //const { loadInvoices, projectInvoices } = useProjectFinances();

  const [projectInvoices, setProjectInvoices] = useState<Array<Invoice>>();

  // Load data
  useEffect(() => {
    console.log('asdfasdfasf AAAAAAAAAAAAAAAA');
    loadProjectInvoices();
  }, []);

  const loadProjectInvoices = async () => {
    console.log('loadProjectInvoices START');
    let data = await DataStore.query(Invoice);
    let projectInvoicesData = data.filter((invoice) => {
      return invoice.projectIds?.includes(props.project!.id);
    });
    console.log(projectInvoicesData);
    setProjectInvoices(projectInvoicesData);
  };

  return (
    <IonContent>
      {/* Page header */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Invoices"
        }
      />

      {/* List out stuff */}
      <IonCard>
        {/* Add button header */}
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonSelect value={typeFilter ? typeFilter : 'all'} style={{'marginRight': '20px'}} onIonChange={(e) => { console.log(e.detail.value); setTypeFilter(e.detail.value) } }>
                <IonSelectOption value="all">Display All</IonSelectOption>
                <IonSelectOption value="revenue">Display revenue</IonSelectOption>
                <IonSelectOption value="expenses">Display expenses</IonSelectOption>
              </IonSelect>
              <CreateButton onCreate={() => createInvoice(props.project?.id)} />
            </IonButtons>
          </IonToolbar>
        </IonCardHeader>

        {/* List out invoices */}
        <IonCardContent>

          {['all', 'revenue'].includes(typeFilter) &&
            <RevenueCreateCard project={props.project!} onCreate={() => {loadProjectInvoices()}}></RevenueCreateCard>
          }
          {['expenses'].includes(typeFilter) &&
            <ExpenseCreateCard project={props.project!} category={Expenses.MISCELLANEOUS} onCreate={() => {loadProjectInvoices()}}></ExpenseCreateCard>
          }

          {/* List out revenue invoice */}
          {['', 'all', 'revenue'].includes(typeFilter) &&
            <IonList>
              <IonListHeader>Revenue</IonListHeader>
              <RevenueListHeader />
              {projectInvoices
                ?.filter((invoice) => {
                  return (invoice.account === Accounts.INCOME) && (invoice.amount !== 0);
                })
                .map((invoice) => {
                  return (
                    <RevenueItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                    />
                  );
                })}

              <IonItem>
                <IonGrid>
                  <IonRow>
                  <IonCol size="12" sizeSm="2">
                  </IonCol>
                  {/* Invoice number */}
                  <IonCol size="12" sizeSm="2">
                  </IonCol>
                  {/* Description */}
                  <IonCol size="12" sizeSm="3" style={{"font-weight": "bold"}}>
                    TOTAL:
                  </IonCol>
                  {/* Amount */}
                  <IonCol size="12" sizeSm="2" style={{"font-weight": "bold"}}>
                  ${projectInvoices
                        ?.reduce((res, oinvoice) => {
                          return ((oinvoice.account === Accounts.INCOME) && (oinvoice.amount !== 0) ? res + oinvoice!.amount! : res) ;
                        }, 0.0)
                      }
                  </IonCol>
                  {/* Date */}
                  <IonCol size="12" sizeSm="2">
                  </IonCol>
                  <IonCol className="ion-text-right" sizeSm="1" size="12">
                  </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>              
            </IonList>
          }

          {/* VEndor Expenses */}
          {['', 'all', 'expenses'].includes(typeFilter) &&
            <IonList>
              <IonListHeader>Vendor Invoices</IonListHeader>
              <ExpensesListHeader category="Vendor" />
              {projectInvoices
                ?.filter((invoice) => {
                  return (
                    invoice.account === Accounts.EXPENSES &&
                    invoice.category === Expenses.VENDOR &&
                    invoice.amount !== 0
                  );
                })
                .map((invoice) => {
                  return (
                    <ExpenseItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                    />
                  );
                })}
                <IonItem>
                  <IonGrid>
                    <IonRow>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Invoice number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Description */}
                    <IonCol size="12" sizeSm="2" style={{"font-weight": "bold"}}>
                      TOTAL:
                    </IonCol>
                    {/* Amount */}
                    <IonCol size="12" sizeSm="1" className="ion-text-left" style={{"font-weight": "bold"}}>
                      ${projectInvoices
                        ?.reduce((res, oinvoice) => {
                          return (oinvoice.account === Accounts.EXPENSES && oinvoice.category === Expenses.VENDOR && oinvoice.amount !== 0 ? res + oinvoice!.amount! : res);
                        }, 0.0)
                      }
                    </IonCol>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    <IonCol className="ion-text-right" sizeSm="1" size="12">
                    </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>              
            </IonList>
          }

          {/* Sub Expenses */}
          {['', 'all', 'expenses'].includes(typeFilter) &&
            <IonList>
              <IonListHeader>Subcontrator Invoices</IonListHeader>
              <ExpensesListHeader category="Subcontrator" />

              {projectInvoices
                ?.filter((invoice) => {
                  return (
                    invoice.account === Accounts.EXPENSES &&
                    invoice.category === Expenses.SUBCONTRACTOR &&
                    invoice.amount !== 0
                  );
                })
                .map((invoice) => {
                  return (
                    <ExpenseItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                    />
                  );
                })}

                <IonItem>
                  <IonGrid>
                    <IonRow>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Invoice number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Description */}
                    <IonCol size="12" sizeSm="2" style={{"font-weight": "bold"}}>
                      TOTAL:
                    </IonCol>
                    {/* Amount */}
                    <IonCol size="12" sizeSm="1" className="ion-text-left" style={{"font-weight": "bold"}}>
                      ${projectInvoices
                        ?.reduce((res, oinvoice) => {
                          return (oinvoice.account === Accounts.EXPENSES && oinvoice.category === Expenses.SUBCONTRACTOR && oinvoice.amount !== 0 ? res + oinvoice!.amount! : res);
                        }, 0.0)
                      }
                    </IonCol>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    <IonCol className="ion-text-right" sizeSm="1" size="12">
                    </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>    
            </IonList>
          }

          {/* Misc Expenses */}
          {['', 'all', 'expenses'].includes(typeFilter) &&
            <IonList>
              <IonListHeader>Misc Invoices</IonListHeader>
              <ExpensesListHeader category="Misc" />

              {projectInvoices
                ?.filter((invoice) => {
                  return (
                    invoice.account === Accounts.EXPENSES &&
                    invoice.category === Expenses.MISCELLANEOUS &&
                    invoice.amount !== 0
                  );
                })
                .map((invoice) => {
                  return (
                    <ExpenseItem
                      key={invoice.id}
                      invoice={invoice}
                      onEdit={() => setActiveInvoiceId(invoice.id)}
                    />
                  );
                })}

                <IonItem>
                  <IonGrid>
                    <IonRow>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Invoice number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Number */}
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    {/* Description */}
                    <IonCol size="12" sizeSm="2" style={{"font-weight": "bold"}}>
                      TOTAL:
                    </IonCol>
                    {/* Amount */}
                    <IonCol size="12" sizeSm="1" className="ion-text-left" style={{"font-weight": "bold"}}>
                      ${projectInvoices
                        ?.reduce((res, oinvoice) => {
                          return (oinvoice.account === Accounts.EXPENSES && oinvoice.category === Expenses.MISCELLANEOUS && oinvoice.amount !== 0 ? res + oinvoice!.amount! : res);
                        }, 0.0)
                      }
                    </IonCol>
                    <IonCol size="12" sizeSm="2">
                    </IonCol>
                    <IonCol className="ion-text-right" sizeSm="1" size="12">
                    </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>    
            </IonList>
          }
        </IonCardContent>
      </IonCard>

      {/* Modal */}
      <InvoiceModal
        invoiceId={activeInvoiceId}
        onDismiss={() => {
          console.log(activeInvoiceId);
          setActiveInvoiceId(undefined);
          loadProjectInvoices();
          props.onRefresh();
        }}
        typeFilter={typeFilter}
        project={props.project}
      />
    </IonContent>
  );
};
