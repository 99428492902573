import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { eyeOutline } from "ionicons/icons";
import { OnCall } from "../../models";

interface OnCallItemInterface {
  onCall: OnCall;
  onEdit: () => void;
}

export const OnCallItem: React.FC<OnCallItemInterface> = (props) => {
  console.log(props.onCall);
  return (
    <IonItem key={props.onCall.id}>
      <IonGrid>
        <IonRow>
          <IonCol>
            {props.onCall.employee?.username?.toLocaleUpperCase()}
          </IonCol>
          <IonCol>
            {new Date(props.onCall.start + "T08:00").toDateString() +
              " to " +
              new Date(props.onCall.end + "T08:00").toDateString()}
          </IonCol>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => props.onEdit()}>
              <IonIcon slot="icon-only" icon={eyeOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
