import { IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";

export const ProjectListHeader: React.FC = () => {
  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          {/* Code  */}
          <IonCol size="12" sizeSm="1">
            <IonText color="medium">PO</IonText>
          </IonCol>
          {/* Builder  */}
          <IonCol size="12" sizeSm="3">
            <IonText color="medium">Builder</IonText>
          </IonCol>
          {/* Builder  */}
          <IonCol size="12" sizeSm="3">
            <IonText color="medium">Address</IonText>
          </IonCol>
          {/* Note  */}
          <IonCol size="12" sizeSm="3">
            <IonText color="medium">Note</IonText>
          </IonCol>
          {/* Actions  */}
          <IonCol size="12" sizeSm="2" className="ion-text-center">
            <IonText color="medium">View</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
