import {
  IonDatetime,
  IonItem,
  IonLabel,
  IonPopover,
  IonText,
} from "@ionic/react";
import {
  formatTimeString,
  formatTimeH12String,
  formatIonDateTimeString,
} from "../../_utils/datetime";

interface TimeInputProps {
  label: string;
  value: Date;
  disabled: boolean;
  onChange: (value: Date) => void;
  hour24?: boolean;
}

export const TimeInput: React.FC<TimeInputProps> = (props) => {
  return (
    <IonItem
      id={"open-time-input-" + props.label}
      button
      disabled={props.disabled}
    >
      <IonLabel>{props.label}</IonLabel>
      <IonText slot="end">{props.hour24 ? formatTimeString(props.value) : formatTimeH12String(props.value)}</IonText>
      <IonPopover
        trigger={"open-time-input-" + props.label}
        showBackdrop={false}
      >
        <IonDatetime
          hourCycle="h12"
          presentation="time"
          value={formatIonDateTimeString(props.value)}
          onIonChange={(e) => {
            props.onChange(new Date(e.detail.value as string));
          }}
        ></IonDatetime>
      </IonPopover>
    </IonItem>
  );
};
