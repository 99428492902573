import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useNote } from "../../hooks";
import {
  CloseButton,
  SelectInput,
  StatusColorInput,
  StringInput,
  TextAreaInput,
} from "../";
import { NoteStatus } from "../../models";
import { closeOutline } from "ionicons/icons";

interface NoteModalInterface {
  noteId: null | string | undefined;
  onDismiss: () => void;
  onDelete?: () => void;
}

export const NoteModal: React.FC<NoteModalInterface> = (props) => {
  // Open state
  const [isOpen, setIsOpen] = useState(false);

  // Hook into data
  const { setActiveNoteId, activeNote, setActiveNote, saveNote } = useNote();

  // Hand close
  const handleClose = async () => {
    if (activeNote && activeNote.description && activeNote.description.trim() !== '') {
      props.onDismiss();
    } else if (props.onDelete) {
      props.onDelete();
    }
  };

  // Save
  const handleSave = async () => {
    await saveNote();
    props.onDismiss();
  };
  // Set open
  useEffect(() => {
    setActiveNoteId(props.noteId !== null ? props.noteId : undefined);
    props.noteId ? setIsOpen(true) : setIsOpen(false);
  }, [props.noteId]);

  // Hook in
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => handleClose()}>
      {activeNote && (
        <IonToolbar>
          <IonTitle>Note</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleClose()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      )}

      {activeNote && (
        <IonContent>
          {/* Color */}
          {/* <StatusColorInput
            value={activeNote.color !== null ? activeNote.color : undefined}
            onChange={(value) => setActiveNote({ ...activeNote, color: value })}
          /> */}
          {/* Name input */}
          <StringInput
            label="For"
            value={activeNote.name}
            canEdit={true}
            onChange={(value) => setActiveNote({ ...activeNote, name: value })}
          />

          {/* Descriptoin */}
          <TextAreaInput
            lable="Description"
            value={activeNote.description}
            readonly={false}
            onChange={(value) =>
              setActiveNote({ ...activeNote, description: value })
            }
          />

          {/* status */}
          <SelectInput
            label="Status"
            value={activeNote.status}
            options={Object.keys(NoteStatus)}
            canEdit={true}
            onChange={(value) =>
              setActiveNote({ ...activeNote, status: value as NoteStatus })
            }
          />

          {/* Save */}
          <IonButton expand="full" onClick={() => handleSave()}>
            Save
          </IonButton>
        </IonContent>
      )}
    </IonModal>
  );
};
