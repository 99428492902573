import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonList,
  IonText,
  IonTitle,
} from "@ionic/react";
import { LabourCosts } from "../../hooks";

interface TimesheetSummaryCardInterface {
  labour: LabourCosts | undefined;
  showUnapproved?: boolean
}

export const TimesheetSummaryCard: React.FC<TimesheetSummaryCardInterface> = (
  props
) => {
  return (
    <IonCard style={{ height: "100%" }}>
      <IonCardHeader>
        {/* Title */}
        <IonCardTitle>
          Labour Total: $
          {props.labour &&
            (
              props.labour?.hvac +
              props.labour?.plumbing +
              (props.showUnapproved ? props.labour?.unapproved : 0)
            ).toFixed(2)}
        </IonCardTitle>
      </IonCardHeader>

      {/* Display totals */}
      {props.labour && (
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonTitle color="plumbing">
                $ {props.labour.plumbing.toFixed(2)}
              </IonTitle>
              <IonText slot="end" color="plumbing">
                Plumbing
              </IonText>
            </IonItem>
            <IonItem>
              <IonTitle color="hvac">$ {props.labour.hvac.toFixed(2)}</IonTitle>
              <IonText slot="end" color="hvac">
                HVAC
              </IonText>
            </IonItem>
            {props.showUnapproved &&
              <IonItem>
              <IonTitle color="medium">
                $ {props.labour.unapproved.toFixed(2)}
              </IonTitle>
              <IonText slot="end" color="medium">
                Unapproved
              </IonText>
            </IonItem>
            }
            
          </IonList>
        </IonCardContent>
      )}
    </IonCard>
  );
};
