import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import {
  DateInput,
  Header,
  TimesheetItem,
  TimesheetModal,
  TimesheetSummaryCard,
} from "..";
import { LabourCosts, useTimesheets, useUser } from "../../hooks";
import { Project, Timesheet, TimesheetStatus } from "../../models";
import { checkmarkDoneCircle, filter } from "ionicons/icons";
import { DataStore, SortDirection } from "aws-amplify";
import { formatAWSDate } from "../../_utils/datetime";

interface ProjectLabourTabInterface {
  project: Project | undefined;
  timesheets: Timesheet[] | undefined;
  labour: LabourCosts | undefined;
  onDelete?: () => void;
}

export const ProjectLabourTab: React.FC<ProjectLabourTabInterface> = (
  props
) => {
  // Get timesheets
  const [activeTimesheetID, setActiveTimesheetID] = useState<string>();

  const [totalHoursString, setTotalHoursString] = useState<string>("");

  let stoday = new Date();
  let senddate = stoday;
  let sstartdate = new Date();
  sstartdate.setDate(sstartdate.getDate() - 70);

  const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
  const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);
  const [filterBillingStatus, setBillingStatus] = useState<string>("all");

  const [timesheets, setTimesheets] = useState<Array<Timesheet>>([]);

  const {deleteTimesheet} = useTimesheets();

  const getBillingStatusMapping = (billing_status: string) => {
    switch (billing_status) {
      case "billed":
        return TimesheetStatus.BILLED;
      case "unbilled":
        return TimesheetStatus.UNBILLED;
    }
  };

  const loadProjectTimesheets = async () => {
    console.log('loadProjectTimesheets start');
    let sFilterStartDate = filterStartDate ? formatAWSDate(filterStartDate) : undefined;
    let sFilterEndDate = filterEndDate ? formatAWSDate(filterEndDate) : undefined;
    console.log('loadProjectTimesheets START');
    let timesheetsData = await DataStore.query(Timesheet, (c) => {
        let d = c
          .timesheetProjectId("eq", props.project?.id!)
          .deleted("ne", true);
        if (sFilterStartDate) {
          d = d
            .date("ge", sFilterStartDate);
        }
        if (sFilterEndDate) {
          d = d
            .date("le", sFilterEndDate);
        }
        return d;
      }
      ,
      {
        sort: (s) => s.createdAt(SortDirection.DESCENDING)
      }
    );
    setTotalHoursString(getFormattedTimeFromFullMinutes(timesheetsData?.filter((timesheet) => { 
      return timesheet.status && [TimesheetStatus.APPROVED].includes(timesheet.status as TimesheetStatus) && (!filterBillingStatus || filterBillingStatus === 'all' || (["billed", "unbilled"].includes(filterBillingStatus!) && getBillingStatusMapping(filterBillingStatus) == timesheet.billing_status)) && (timesheet.date ? ((!filterStartDate || timesheet.date >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date <= formatAWSDate(filterEndDate))) : false);
    }).reduce((accumulator, currentValue, currentIndex, array) => {
      const start = new Date(currentValue.date + "T" + currentValue.start);
      const end = new Date(currentValue.date + "T" + currentValue.end);
      let addoneday = false;
      if (end.getTime() < start.getTime()) {
        addoneday = true;
      }
      const hduration = (end.getTime() + (addoneday ? 1*24*60*60*1000 : 0) - start.getTime()) / 1000;
      const fullminutes = Math.floor(hduration / 60) - (currentValue.lunch ? 30 : 0);
      return accumulator + fullminutes;
    }, 0)!));
    console.log(timesheetsData);
    setTimesheets(timesheetsData);
    console.log('loadProjectTimesheets end');
  };

  const handleDelete = async () => {
    console.log('handleDelete START');
    loadProjectTimesheets().then(() => {
      if (props.onDelete) {
        props.onDelete();
      }
    });
  };

  const getFormattedTimeFromFullMinutes = (fullminutes: number) => {
    const hours = Math.floor(fullminutes / 60);
    const minutes = (fullminutes % 60);
    const shours = hours + 'h' + (minutes % 60) + 'min';
    return shours;
  };

  useEffect(() => {
    loadProjectTimesheets();
  }, [props.project?.id, filterStartDate]);

  const { security } = useUser();

  const getSelected = () => {

    let a = document.getElementsByName("checkedTimesheets");
    let b: Array<any> = [];
    for (let i = 0; i < a.length; i++) {
      let o = a[i];
      console.log(o.tagName);
      if (o.tagName.toLowerCase() === "ion-checkbox" && o.classList.contains("checkbox-checked")) {
        b.push(o.getAttribute("value"));
      }
    }

    let selectedTimesheets = timesheets.filter((t) => {
      return b.includes(t.id);
    });
    
    return selectedTimesheets;

  };

  const greyOutSelected = async () => {
    let selected = getSelected();
    selected.map(async (timesheet) => {
      await DataStore.save(Timesheet.copyOf(timesheet, (updated) => {
        updated.status = TimesheetStatus.BILLED;
      }));
    });
    console.log("COMPLETED GREY OUT");
    loadProjectTimesheets();
  };

  const ungreyOutSelected = async () => {
    let selected = getSelected();
    selected.map(async (timesheet) => {
      await DataStore.save(Timesheet.copyOf(timesheet, (updated) => {
        updated.status = TimesheetStatus.UNAPPROVED;
      }));
    });
    console.log("COMPLETED GREY OUT");
    loadProjectTimesheets();
  };

  useEffect(() => {
    loadProjectTimesheets();
  }, [filterBillingStatus]);

  return (
    
    <IonContent class="printable-content">
      {/* Page header */}
      <Header
        title={
          props.project?.code?.toUpperCase() +
          "" +
          props.project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ": Timesheets"
        }
      />

      {props.labour && (
        <IonGrid>

          <IonRow>
            <IonCol>
              <IonToolbar>
                <IonTitle></IonTitle>
                {/* Conditional save only when the document is htere */}
                <IonButtons slot="end">
                  {/* Save */}
                  <IonButton
                    expand="full"
                    /* disabled={activeInvoice !== null && activeInvoice !== undefined}  && !activeInvoice.document */
                    // disabled={activeInvoice === null || activeInvoice === undefined || activeInvoice.number === null || activeInvoice.number === undefined || activeInvoice.number === ''}
                    onClick={() => {greyOutSelected()}}
                  >
                    GREY OUT SELECTED
                  </IonButton>
                  |
                  <IonButton
                    expand="full"
                    /* disabled={activeInvoice !== null && activeInvoice !== undefined}  && !activeInvoice.document */
                    // disabled={activeInvoice === null || activeInvoice === undefined || activeInvoice.number === null || activeInvoice.number === undefined || activeInvoice.number === ''}
                    onClick={() => {ungreyOutSelected()}}
                  >
                    UNGREY OUT SELECTED
                  </IonButton>
                  |
                  <IonButton
                   expand="full"
                   onClick={() => {
                    //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
                    //let win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=0,left=0");
                    //if (win) {
                    //  win.document.body.innerHTML = "HTML";
                    //}
                      // if (window && window.document) {
                      //   let head = window?.document?.head.innerHTML;
                      //   let content = window?.document?.getElementsByClassName('ion-page')[0].innerHTML;
                      //   let childHtml = '<html><head>' + head + '</head><body>' + content + '</body></html>';
                      //   //var table = window.document.getElementById('printArea').innerHTML;
                      //   // let table = window.document.getElementById('').innerHTML;
                      //   let myWindow = window.open('', '', 'width=800, height=600');
                      //   if (myWindow) {
                      //     myWindow.document.write(childHtml);
                      //     myWindow.print();
                      //   }
                      // }

                      if (window) {
                        window.print();
                      }
                      
            
                   }}
                  >
                    PRINT
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>

          <IonCard>
            <IonCardContent>
              <IonRow>
                <IonCol>
                  {/* Date */}
                  <DateInput
                    tid="aaaa"
                    label="Start Date"
                    value={filterStartDate}
                    disabled={false}
                    onChange={(value) => {
                        setFilterStartDate(value);
                      }
                    }
                    
                  />
                </IonCol>
                <IonCol>
                  {/* Date */}
                  <DateInput
                    tid="bhbbb"
                    label="End Date"
                    value={filterEndDate}
                    disabled={false}
                    onChange={(value) => {
                        setFilterEndDate(value);
                      }
                    }
                  />
                </IonCol>
              </IonRow>
              <IonRow>
              <IonCol>
                <IonLabel>Billing Status:</IonLabel>
                <IonSelect style={{color: "#065BAA"}} value={filterBillingStatus} placeholder="All" onIonChange={(e) => setBillingStatus(e.detail.value)} >
                  <IonSelectOption value="all">-- All --</IonSelectOption>
                  <IonSelectOption value="billed">Billed</IonSelectOption>
                  <IonSelectOption value="unbilled">Non Billed</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>
            </IonCardContent>
          </IonCard>

          {/* All */}
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardContent>
                  <IonList lines="inset">
                    <IonListHeader>
                      <IonText>All Timesheets</IonText>
                    </IonListHeader>
                    <IonItem>
                        <IonGrid>
                          <IonRow>
                            {/* Job details */}
                            <IonCol sizeSm="1">
                            </IonCol>

                          {!security.isField && 
                            <IonCol sizeSm="3">
                              <IonItem>
                                <IonText>
                                  Employee
                                </IonText>
                              </IonItem>
                            </IonCol>
                          }
                          
                          <IonCol sizeSm="1">
                            <IonItem>
                              <IonText>
                                Project
                              </IonText>
                            </IonItem>
                          </IonCol>

                          <IonCol sizeSm="1">
                            <IonItem>
                              <IonText>
                                Hours
                              </IonText>
                            </IonItem>
                          </IonCol>

                          <IonCol sizeSm="1">
                            <IonItem>
                              <IonText>
                                Date
                              </IonText>
                            </IonItem>
                          </IonCol>

                          <IonCol sizeSm="1">
                            <IonItem>
                              <IonText>
                                Action
                              </IonText>
                              <IonLabel></IonLabel>
                            </IonItem>
                          </IonCol>

                          {/* Hours details */}
                          <IonCol sizeSm="2">
                            <IonItem lines="none">
                              <IonText>Details</IonText>
                            </IonItem>
                          </IonCol>

                          
                          {/* <IonCol sizeSm="2">
                            <IonButton fill="clear" color={statusColor}>
                              {props.timesheet?.status}
                            </IonButton>
                          </IonCol> */}

                          {/* Edit button */}
                          <IonCol sizeSm="2" className="ion-text-right">
                            
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    {timesheets
                      ?.filter((timesheet) => {
                        return (!filterBillingStatus || filterBillingStatus === 'all' || (["billed", "unbilled"].includes(filterBillingStatus!) && getBillingStatusMapping(filterBillingStatus) == timesheet.billing_status)) && timesheet.status && [TimesheetStatus.UNAPPROVED, TimesheetStatus.APPROVED, TimesheetStatus.BILLED].includes(timesheet.status as TimesheetStatus) && (timesheet.date ? ((!filterStartDate || timesheet.date >= formatAWSDate(filterStartDate)) && (!filterEndDate || timesheet.date <= formatAWSDate(filterEndDate))) : false);
                      })
                      // .sort((a: Timesheet, b: Timesheet) => {
                      //   if (a.date !== null && a.date !== undefined && b.date !== null && b.date !== undefined) {
                      //     if (a.date > b.date) {
                      //       return 1;
                      //     } else if (a.date < b.date) {
                      //       return -1;
                      //     } else {
                      //       return 0;
                      //     }
                      //   } else if ((a.date === null || a.date === undefined) && (b.date === null || b.date === undefined)) {
                      //     return 0;
                      //   } else if (a.date !== null && a.date !== undefined) {
                      //     return -1;
                      //   } else {
                      //     return 1;
                      //   }              
                      // })
                      .map((timesheet) => {
                        return (
                          <TimesheetItem
                            key={timesheet.id}
                            timesheet={timesheet}
                            onEdit={() => setActiveTimesheetID(timesheet.id)}
                            onDelete={() => {
                              handleDelete();
                            }}
                            customStyle={["BILLED"].includes(timesheet.status!) ? {"backgroundColor": "#CCCCCC"} : {}}
                            withCheckboxes={true}
                            onUpdate={() => loadProjectTimesheets()}
                          />
                        );
                      })}
                    <IonItem>
                      <IonLabel style={{fontWeight: "bold"}}>
                        TOTAL APPROVED HOURS: {totalHoursString}
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          {/* Unapproved */}
          { false && 
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonList lines="inset">
                      <IonListHeader>
                        <IonText>Unapproved</IonText>
                      </IonListHeader>
                      {props.timesheets
                        ?.filter((timesheet) => {
                          return timesheet.status === TimesheetStatus.UNAPPROVED;
                        })
                        .map((timesheet) => {
                          return (
                            <TimesheetItem
                              key={timesheet.id}
                              timesheet={timesheet}
                              onEdit={() => setActiveTimesheetID(timesheet.id)}
                              withCheckboxes={true}
                            />
                          );
                        })}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          }

          {/* Rejected */}
          { false && 
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonList lines="inset">
                      <IonListHeader>
                        <IonText>Rejected</IonText>
                      </IonListHeader>
                      {props.timesheets
                        ?.filter((timesheet) => {
                          return timesheet.status === TimesheetStatus.REJECTED;
                        })
                        .map((timesheet) => {
                          return (
                            <TimesheetItem
                              key={timesheet.id}
                              timesheet={timesheet}
                              onEdit={() => setActiveTimesheetID(timesheet.id)}
                              withCheckboxes={true}
                            />
                          );
                        })}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          }

          {/* Approved */}
          { false && 
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <IonList lines="inset">
                      <IonListHeader>
                        <IonText>Approved</IonText>
                      </IonListHeader>
                      {props.timesheets
                        ?.filter((timesheet) => {
                          return timesheet.status === TimesheetStatus.APPROVED;
                        })
                        .map((timesheet) => {
                          return (
                            <TimesheetItem
                              key={timesheet.id}
                              timesheet={timesheet}
                              onEdit={() => setActiveTimesheetID(timesheet.id)}
                              withCheckboxes={true}
                            />
                          );
                        })}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          }
          
        </IonGrid>
      )}

      {/* Data control */}
      <TimesheetModal
        timesheetId={activeTimesheetID}
        onDismiss={() => {
          if (activeTimesheetID) {
            console.log(activeTimesheetID);
          }
          setActiveTimesheetID(undefined)
        }}
        onEditSave={() => loadProjectTimesheets()}
      />
    </IonContent>
  );
};
