import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { AuthUser, OnCall } from "../models";
import { formatAWSDate } from "../_utils/datetime";

export interface OnCallForm {
  start: Date;
  end: Date;
  note: null | string | undefined;
  employee: AuthUser | undefined;
}
export function useOnCall() {
  // State
  const [activeOnCallId, setActiveOnCallId] = useState<string>();

  // Data
  const [activeOnCallForm, setActiveOnCallForm] = useState<OnCallForm>();

  // Load data
  const loadOnCall = async () => {
    try {
      let data = await DataStore.query(OnCall, activeOnCallId!);

      setActiveOnCallForm({
        start: new Date(data?.start!),
        end: new Date(data?.end!),
        note: data?.note || "",
        employee: data?.employee !== null ? data?.employee : undefined,
      });
    } catch (error) {
      console.log("Error loading on call data");
    }
  };

  // Edit employee
  const saveOnCall = async () => {
    try {
      // Make copy to save
      const original = await DataStore.query(OnCall, activeOnCallId!);

      // Update data
      await DataStore.save(
        OnCall.copyOf(original!, (updated) => {
          updated.employee = activeOnCallForm?.employee;
          updated.start = formatAWSDate(activeOnCallForm!.start);
          updated.end = formatAWSDate(activeOnCallForm!.end);
          updated.note = activeOnCallForm?.note;
        })
      );
    } catch (error) {
      console.log("Error editting employee");
    }
  };

  // Delete
  const deleteOnCall = async () => {
    try {
      // Make copy to save
      const original = await DataStore.query(OnCall, activeOnCallId!);

      // Update data
      await DataStore.save(
        OnCall.copyOf(original!, (updated) => {
          updated.deleted = true;
        })
      );
    } catch (error) {
      console.log("Error editting employee");
    }
  };

  // Effect
  useEffect(() => {
    //   If Id set
    if (activeOnCallId) loadOnCall();
  }, [activeOnCallId]);

  return {
    activeOnCallForm,
    setActiveOnCallId,
    setActiveOnCallForm,
    saveOnCall,
    deleteOnCall,
  };
}
