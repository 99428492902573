import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { Accounts, Expenses, Invoice, Project } from "../models";
import { formatAWSDate } from "../_utils/datetime";

export function useInvoices() {
  // state
  const [reloadState, setReloadState] = useState(false);

  // Data
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [activeInvoiceId, setActiveInvoiceId] = useState<string>();

  // Query
  const [keyword, setContactKeyword] = useState<null | string | undefined>();
  const [queriedInvoices, setQueriedInvoices] = useState<Invoice[]>();

  // Load data
  const loadInvoices = async () => {
    try {
      let data = await DataStore.query(Invoice);
      setInvoices(data);
    } catch (error) {
      console.log("Error loading invoices: ", error);
    }
  };

  // Query revenue invoices by number
  const queryRevenueInvoicesByNumber = async (number: string) => {
    try {
      let data = await DataStore.query(Invoice, (i) => i.number("eq", number).account("eq", Accounts.INCOME));
      return data;
    } catch (error) {
      console.log("Error loading invoices: ", error);
      return null;
    }
  };

  // Query invoices
  const queryInvoices = async () => {
    // Create regex
    let keywordRegex = new RegExp(keyword || "", "i");
    let quedInvoices = invoices?.filter((invoice) => {
      if (keyword) {
        if (invoice.business?.match(keywordRegex)) return true;
        return false;
      }
      return true;
    });

    setQueriedInvoices(quedInvoices);
  };

  const getPOs = async (projectIds: Array<string>) => {

    let projects = [];
    let pos = [];
    for (let id of projectIds) {
      let lprojects = await DataStore.query(Project, (c) =>
        c.id("eq", id)
      );
      if (lprojects.length > 0) {
        let oproject = lprojects[0];
        let spo = 
          oproject.code && oproject.number ?
            oproject.code?.toUpperCase() +
            oproject.number?.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
          :
          '';
        if (spo) {
          projects.push(oproject);
          pos.push(spo);
        }
        
      }
      
    }

    return pos;

  };

  // Create invoice
  const createInvoice = async (projectId?: null | string | undefined) => {
    try {
      // Project add
      let projectIds = projectId ? [projectId] : [];
      let partitions = projectId ? [0.0] : [];

      //Create the invoice
      let newInvoice = await DataStore.save(
        new Invoice({
          amount: 0.0,
          account: Accounts.EXPENSES,
          category: Expenses.MISCELLANEOUS,
          date: formatAWSDate(new Date()),
          projectIds: projectIds,
          partition: partitions,
        })
      );
      setActiveInvoiceId(newInvoice.id);
      loadInvoices();
    } catch (error) {
      console.log("Error loading invoice");
    }
  };

  const deleteInvoice = async (invoiceId: string) => {

    try {
      const toDelete = await DataStore.query(Invoice, invoiceId)
      if (toDelete) {
        DataStore.delete(toDelete);
      }
    } catch (error) {
      console.log("Error deleting invoice");
    }

  };

  // Query
  useEffect(() => {
    queryInvoices();
  }, [invoices, keyword]);

  // Relaod
  useEffect(() => {
    if (reloadState) {
      loadInvoices();
      setReloadState(false);
    }
  }, []);
  // Load shit
  useEffect(() => {
    loadInvoices();
  }, []);

  // Return
  return {
    createInvoice,
    deleteInvoice,
    activeInvoiceId,
    setActiveInvoiceId,
    setContactKeyword,
    queriedInvoices,
    setReloadState,
    loadInvoices,
    queryRevenueInvoicesByNumber,
    getPOs
  };
}
