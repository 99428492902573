import { IonRouterOutlet } from "@ionic/react";
import { Route, Redirect } from "react-router";
import {
  Projects,
  Hours,
  Account,
  Schedule,
  Contacts,
  Expenses,
  Project,
  Timesheets,
  Users,
  OnCall,
  Revenue,
} from "../../pages";
import { AccountCreate } from "../../pages/AcountCreate";

export const Routes: React.FC = () => {
  return (
    <IonRouterOutlet id="main">
      <Route exact={true} path="/mactech/jobs" component={Projects} />

      <Route exact={true} path="/mactech/jobs/:id" component={Project} />

      <Route exact={true} path="/mactech/revenue" component={Revenue} />

      <Route
        exact={true}
        path="/mactech/expenses/:category"
        component={Expenses}
      />

      <Route
        exact={true}
        path="/mactech/expenses/:category"
        component={Expenses}
      />

      <Route exact={true} path="/mactech/schedule" component={Schedule} />

      <Route exact={true} path="/mactech/timesheets" component={Timesheets} />

      <Route exact={true} path="/mactech/users" component={Users} />

      <Route exact={true} path="/mactech/contacts" component={Contacts} />

      <Route exact={true} path="/mactech/oncall" component={OnCall} />

      <Route exact={true} path="/mactech/hours" component={Hours} />

      <Route exact={true} path="/mactech/account" component={Account} />

      <Route exact={true} path="/mactech/account/create" component={AccountCreate} />

      <Redirect exact={true} path="/" to="/mactech/jobs" />
    </IonRouterOutlet>
  );
};
