import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonText,
  IonButton,
  IonIcon,
  IonToolbar,
  IonButtons,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { useEventsOncall } from "../../hooks";

interface OnCallSummaryCardProps {
  addEvent: () => void;
}

export const OnCallSummaryCard: React.FC<OnCallSummaryCardProps> = (props) => {
  const { onCallEmployees } = useEventsOncall();

  return (
    // Card showing who's on call
    <IonCard style={{ height: "100%" }}>
      {/* Heading section */}
      <IonCardHeader>
        <IonToolbar color="translucent">
          <IonCardTitle>On Call Employees</IonCardTitle>

          {/* Create buttons */}
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={() => props.addEvent()}>
              + Work Order
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardHeader>
      <IonCardContent>
        {/* List of on call employees to quickly make stuff */}
        <IonList>
          {onCallEmployees?.map((onCallEmployee) => {
            return (
              <IonItem key={onCallEmployee.id}>
                <IonText>
                  {onCallEmployee.employee?.username?.toLocaleUpperCase()}
                  {" (" +
                    new Date(onCallEmployee.start).toDateString() +
                    " - " +
                    new Date(onCallEmployee.end).toDateString() +
                    ")"}
                </IonText>
              </IonItem>
            );
          })}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};
